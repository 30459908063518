<template>
  <div class="container h-100" :class="{ 'mobile-container': !isLargeScreen }">
    <div class="fixed-full row" v-if="screenWidth >= 900">
      <div class="col-4 contact-list">
        <div
          class="contactitem row p-3"
          v-for="(contact, index) in contacts"
          :key="index"
          :class="{ 'active-contact': activeChat.id === contact.id }"
          @click="
            fetchChatMessages(contact.id);
            golastt();
            handleContactClick(contact.id);
          "
          style="display: flex; align-items: center"
        >
          <div class="col-4 p-0">
            <img
              :src="
                'https://backend.chesnok.kz/storage/' +
                contact.messages[0].receiver_avatar
              "
              style="width: 100%"
            />
          </div>
          <div class="col-8">
            <p class="mb-0">
              {{ contact.messages[0].sender_name }} >
              {{ contact.messages[0].receiver_name }}
              <span
                v-if="contact.unread_messages_count > 0"
                class="badge bg-danger"
              >
                {{ contact.unread_messages_count }}
              </span>
            </p>
            <small class="text-muted">{{ contact.last.content }} </small>
          </div>
        </div>
      </div>
      <div class="col-8 message-history">
        <div v-if="activeChat.id > 0" class="topbar">
          <b>{{ activeChat.messages[0].sender_name }}</b> написал
          <b>{{ activeChat.messages[0].receiver_name }}</b>
        </div>
        <div id="message-container" class="messages">
          <div
            v-for="(message, index) in selectedContactMessages"
            :key="index"
            class="message"
            :class="getMessageClass(message)"
            :id="index === selectedContactMessages.length - 1 ? 'last' : ''"
          >
            {{ message.content }}
            <br />
            <small class="text-muted">{{
              formatMessageDate(message.updated_at)
            }}</small>
          </div>
        </div>
        <div class="row" v-if="activeChat.id > 0">
          <div class="col-8">
            <input
              class="form-control mb-2"
              v-model="newMessage"
              v-on:keyup.enter="sendMessage"
            />
          </div>
          <div class="col-4">
            <button
              class="btn btn-primary"
              @click="sendMessage"
              v-if="activeChat.confirmed == 1 || activeChat.length > 0"
            >
              Отправить
            </button>

            <button
              class="btn btn-primary"
              @click="resetChatConfirmation()"
              v-if="activeChat.confirmed == 0"
            >
              Разрешить отправку сообщений
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- ЭТО ДЛЯ МОБИЛЫ -->
    <div class="fixed-full row" v-else>
      <div
        class="col-12 p-1"
        style="padding-top: 10px"
        v-if="activeChat.id > 0"
      >
        <div class="row">
          <div class="col-6"></div>
          <button
            class="btn btn-primary"
            style="width: 100%"
            @click="activeChat = []"
          >
            К списку контактов
          </button>
          <p>Чат с {{ activeChat.messages[0].sender_name }}:</p>
        </div>
      </div>

      <div
        class="col-3 contact-list overflow-auto"
        v-if="activeChat.length == 0"
      >
        <div
          class="contactitem row p-3"
          v-for="(contact, index) in contacts"
          :key="index"
          @click="
            golastt();
            fetchChatMessages(contact.id);
          "
          style="display: flex; align-items: center"
        >
          <div class="col-3 p-0">
            <img
              :src="
                'https://backend.chesnok.kz/storage/' +
                contact.messages[0].receiver_avatar
              "
              style="width: 15vw"
            />
          </div>
          <div class="col-9">
            <p class="mb-0" style="font-size: 4vw">
              {{ contact.messages[0].sender_name }} >
              {{ contact.messages[0].receiver_name }}
            </p>
            <span
              v-if="contact.unread_messages_count > 0"
              class="badge bg-danger"
            >
              {{ contact.unread_messages_count }}
            </span>
            <small class="text-muted" style="font-size: 3vw">{{
              contact.messages[0].content
            }}</small>
          </div>
        </div>
      </div>

      <div class="col-12" v-else>
        <div class="col-12">
          <div
            id="message-container"
            class="messages"
            style="height: 45vh !important; padding-bottom: 2vh"
          >
            <div
              v-for="(message, index) in selectedContactMessages"
              :key="index"
              class="message"
              :class="getMessageClass(message)"
              :id="index === selectedContactMessages.length - 1 ? 'last' : ''"
            >
              {{ message.content }}
              <br />
              <small class="text-muted" style="font-size: 2vw">{{
                formatMessageDate(message.updated_at)
              }}</small>
            </div>
          </div>
          <div class="row" v-if="activeChat.id > 0">
            <div class="col-8 col-md-10">
              <input
                class="form-control mb-2"
                v-model="newMessage"
                v-on:keyup.enter="sendMessage"
              />
            </div>
            <div class="col-4 col-md-2">
              <button
                class="btn btn-primary"
                @click="sendMessage"
                v-if="activeChat.confirmed == 1 || activeChat.length > 0"
              >
                Отправить
              </button>

              <button
                class="btn btn-primary"
                @click="resetChatConfirmation()"
                v-if="activeChat.confirmed == 0"
              >
                Разрешить отправку сообщений
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { nextTick } from "vue";
import Cabartistbar from "@/components/Cabartistbar.vue";

export default {
  data() {
    return {
      screenWidth: window.innerWidth,
      contacts: [],
      intervalId: null,
      intervalIdMess: null,
      selectedContactMessages: [],
      activeChat: [],
      newMessage: "",
      sender: 0,
      golast: false,
      isactivechat: true,
    };
  },
  created() {
    if (!localStorage.getItem("isLogged")) {
      document.location.href = "/";
      exit;
    }
  },
  mounted() {
    console.log("грибы");
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
    this.loadMessagesInitial();
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.intervalIdMess) {
      clearInterval(this.intervalIdMess);
    }
  },
  methods: {
    formatMessageDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      const date = new Date(dateString);
      return date.toLocaleDateString("ru-RU", options);
    },
    handleContactClick(contactId) {
      // Останавливаем текущие интервалы (если они запущены)
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      if (this.intervalIdMess) {
        clearInterval(this.intervalIdMess);
      }

      // Запускаем необходимые функции
      this.fetchChatMessages(contactId);
      this.golastt();
    },
    markMessagesAsRead() {
      let token = localStorage.getItem("token");

      axios
        .post(
          "https://backend.chesnok.kz/api/markAsReadManager",
          {
            messages: this.selectedContactMessages,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log(response.data);
          this.loadMessagesInitial();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getMessageClass(message) {
      return this.sender !== message.sender_id
        ? "user-message"
        : "contact-message";
    },
    updateWidth() {
      this.screenWidth = window.innerWidth;
    },
    startAutoUpdateOnlyMessages(chat_id) {
      if (this.intervalIdMess) {
        clearInterval(this.intervalIdMess);
      }
      this.intervalIdMess = setInterval(() => {
        this.fetchChatMessages(chat_id);
      }, 10000); // Обновление каждые 10 секунд (10000 миллисекунд)
    },

    golastt() {
      console.log("golast запущена");
      this.golast = false;
      this.isactivechat = true;
    },

    startAutoUpdate(chat_id) {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        this.loadMessagesInitial();
      }, 10000); // Обновление каждые 10 секунд (10000 миллисекунд)
    },
    resetChatConfirmation() {
      let token = localStorage.getItem("token");
      let id = this.activeChat.id;

      axios
        .post(
          "https://backend.chesnok.kz/api/resetChatConfirmation",
          {
            id: id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.loadMessagesInitial(id);

          // this.fetchChatMessages(id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendMessage() {
      let token = localStorage.getItem("token");

      if (this.activeChat.confirmed == 0) {
      } else {
        axios
          .post(
            "https://backend.chesnok.kz/api/sendMessage",
            {
              chat_id: this.activeChat.id,
              sender_id: this.activeChat.messages[0].receiver_id,
              receiver_id: this.activeChat.messages[0].sender_id,
              content: this.newMessage,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            this.fetchChatMessages(this.activeChat.id);
            this.newMessage = ""; // Очистка поля ввода сообщения после отправки
            this.golast = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    fetchChatMessages(chat_id) {
      const currentMessageCount = this.selectedContactMessages.length;

      this.startAutoUpdateOnlyMessages(chat_id);
      console.log("chat_id - " + chat_id);
      this.contacts.forEach((element) => {
        if (element.id == chat_id) {
          if (this.isactivechat == true) {
            this.activeChat = element;
            this.isactivechat = false;
          }
          this.sender = element.messages[0].sender_id;
        }
      });

      try {
        let token = localStorage.getItem("token");
        axios
          .post(
            "https://backend.chesnok.kz/api/getChatMessages",
            {
              id: chat_id,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            this.selectedContactMessages = response.data;
            this.markMessagesAsRead();

            const newMessageCount = response.data.length;
            if (newMessageCount > currentMessageCount) {
              this.scrollToBottom();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error("Error fetching chat messages:", error);
      }
    },
    scrollToBottom() {
      nextTick(() => {
        const messageContainer = document.getElementById("message-container");
        if (messageContainer) {
          messageContainer.scrollTop = messageContainer.scrollHeight;
        }
      });
    },
    logout() {
      localStorage.removeItem("isLogged");
      this.loggedIn = null;
      window.location.href = "#/login";
      // Здесь добавьте логику выхода из системы
    },
    loadMessagesInitial(id) {
      let token = localStorage.getItem("token");

      axios
        .post(
          "https://backend.chesnok.kz/api/getArtistChats",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.contacts = response.data;

          let a = localStorage.getItem("activeChatId");

          this.contacts.forEach((element) => {
            if (element.id == id) {
              console.log("Присваеватся новый актив чат");
              console.log(this.activeChat);
              this.activeChat = element;
              this.fetchChatMessages(element.id);
            }
          });
          localStorage.removeItem("activeChatId");

          // this.markMessagesAsRead();

          this.startAutoUpdate();
          if (id) {
            this.fetchChatMessages(id);
          }
        })
        .catch((error) => {
          this.logout();
          console.log(error);
        });
    },
  },
  components: { Cabartistbar },
};
</script>

<style scoped>
.container {
  height: 62vh !important;
}

.mobile-container {
  height: 71vh !important;
}

.active-contact {
  background-color: #7e7e7e9a;
  color: white;
}

.contactitem {
  cursor: pointer !important;
}

.contactitem:hover {
  background-color: #33afe0;
}

.topbar {
  background-color: gainsboro !important;
  padding: 3%;
}

.chat-page {
  padding: 16px;
}

.fixed-full {
  background-color: rgba(228, 228, 228, 0.295);
  margin: 3%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.contact-list {
  height: 70vh !important;
  overflow-y: hidden;
}

.message-history {
  max-height: 600px;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  height: 55vh !important;
}

.user-message {
  background-color: #2196f3;
  color: white;
  display: inline-block;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  float: right;
  clear: both;
}

.contact-message {
  background-color: #f1f1f1;
  color: #333;
  display: inline-block;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  float: left;
  clear: both;
}

.input-area {
  padding: 8px;
  border-top: 1px solid #e0e0e0;
}

.contact-list {
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid #e0e0e0;
}

.q-item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-area {
  display: flex;
  align-items: center;
  padding: 8px;
}

.input-area .q-input {
  flex: 1;
  margin-right: 8px;
}

.message {
  max-width: 60%;
  word-wrap: break-word;
}

.messages {
  padding-bottom: 8px;
}

.messages::-webkit-scrollbar {
  width: 6px;
}

.messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.contact-list::-webkit-scrollbar {
  width: 6px;
}

.contact-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

@media only screen and (max-width: 600px) {
  .contact-list {
    width: 100%;
  }

  .message-history {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: none;
  }
}
</style>
