<template>
    <div style="background-color: #223971">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6 col-sm-6 " style="padding-left: 0%; ">
                    <CitySelect />
                </div>
                <div class="col-6 col-sm-6 d-flex align-items-center justify-content-end" style="padding-right: 3%;">
                    <a href="#">
                        <img class="m-2 iconssocial" style=" margin-left:3.4% !important" src="../assets/icons.png" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CitySelect from './CitySelect.vue';

export default {
    name: 'SocialMediaLinks',
    components: {
        CitySelect,
    }
    // Add any necessary data, props, methods here
}
</script>
