<template>
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card">
            <div class="card-header bg-secondary text-white">Контакты</div>
            <div class="card-body" >
              <p>По вопросам контента и редактирования данных: <a href="mailto:info@chesnok.kz">info@chesnok.kz</a></p>
              <p>По вопросам рекламы на сайте: <a href="mailto:support@chesnok.kz">support@chesnok.kz</a></p>
              <p>Если у вас вопрос по редактированию данных профиля (например, изменение названия и добавление видов деятельности возможно только по запросу), у вас есть вопрос, или вы хотите сообщить об ошибке - пишите на почту <a href="mailto:support@chesnok.kz">support@chesnok.kz</a> с подробным описанием проблемы. Это позволит нам быстрее решить ваш вопрос. Если вам не ответили в течении суток - смело звоните! :)</p>
              <p>+7 701 554 1380</p>
              <p>Звоните, пожалуйста, с 11:00 до 18:00 по рабочим дням.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: 'ContactsPage',
  }
  </script>

  <style >
  .card-body .dark-theme{
  }
  body.dark-theme {
  background-color: #121212; /* Example dark background */
  color: #ffffff !important; /* Example light text color */
}

body.dark-theme .card-body {
  background: black !important;

}
/* You can add specific styles for other elements as well */
body.dark-theme a, body.dark-theme p {
  color: #ffffff !important; /* Light text for dark background */
}
body.dark-theme span {
  color: white !important
}
  </style>
