<template>
  <div>
    <div class="custom-row">
      <ul class="custom-col" v-for="i in Math.ceil(categories.length / 2)" :key="i">
        <li v-for="category in categories.slice((i - 1) * Math.ceil(categories.length / 2), i * Math.ceil(categories.length / 2))" :key="category.id">
          <a :href="'/categories/' + category.id">{{ category.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const categories = ref([]);

    const getCategories = async () => {
      try {
        const response = await axios.get('https://backend.chesnok.kz/api/categories');
        categories.value = response.data;
        console.log(response.data);
      } catch (error) {
        console.error('Ошибка при получении списка категорий:', error);
      }
    };

    onMounted(getCategories);

    return {
      categories
    };
  }
}
</script>

<style>
body {
  font-family: Montserrat, sans-serif !important;
}

.custom-row {
  display: flex;
  flex-wrap: wrap; /* Ensures responsiveness */
}

.custom-col {
  list-style-type: none;
  padding: 0;
  flex: 0 0 50%; /* Mimics col-6 behavior */
  max-width: 50%; /* Ensures the column does not exceed 50% width */
  font-size: 3vw;
}

.custom-col a {
  color: #D3D3D3;
  text-decoration: none;
}
</style>
