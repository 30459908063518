<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-8 mx-auto">
        <div class="card">
          <div class="card-header bg-secondary text-white">{{ pageTitle }}</div>
          <div class="card-body" v-html="pageDescription" >

          </div>
        </div>
      </div>
    </div>
  </div>
</template>



  <script>
  import axios from 'axios';

  export default {
    data() {
      return {
        pageTitle: '',
        pageDescription: '',
      };
    },
    created() {
      this.fetchPageData();
    },
    methods: {
      fetchPageData() {
        const pageId = this.$route.params.id;
        axios
          .get(`https://backend.chesnok.kz/api/pages/${pageId}`)
          .then(response => {
            this.pageDescription = response.data.page.description;
            this.pageTitle = response.data.page.name;
          })
          .catch(error => {
            console.error('There was an error fetching the page data:', error);
          });
      },
    },
  };
  </script>

  <style >
  .card-body .dark-theme{
    color: white !important;
  }
  body.dark-theme div div div div{
  color: #ffffff !important; /* Example light text color */
}

body.dark-theme .card-body {
  background: black !important;

}

/* You can add specific styles for other elements as well */
body.dark-theme a, body.dark-theme p {
  color: #ffffff !important; /* Light text for dark background */
}
body.dark-theme span {
  color: white !important
}
  </style>
