<template>
  <div id="app" class="m-0 p-0">
    <TopBar @click="shownav = false" class="" />
    <!-- <SocialMediaLinks @click="shownav = false" class="SocialMediaLinks" /> -->
    <!-- <nav class="navbar navbar-expand-lg navbar-dark m-0 p-0">
      <div class="container-fluid m-0 p-0 ">
        <div class="mobile-search-icon" @click="$router.push('/advancedsearch'); showMobileMenu = false">
          <img src="./assets/search.png" class="mt-3" alt="Search">
          <div class="pt-3">
            <CitySelect />
          </div>
        </div>

        <a class="navbar-brand" @click="$router.push('/'); showMobileMenu = false" style="align-items: center;">
          <img src="./assets/logo.png" class="logoimg" alt="" />
          <ChangeLanguage @languageChanged="onLanguageChanged" class="selectlang"></ChangeLanguage>
        </a>

        <div style="display: inline !important; ">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDr1opdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
            @click="toggleMobileMenu">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="loginmobile">
            <a class="nav-link navtextsizemobile" href="/mlogin" v-if="!loggedIn"
              style="font-size: clamp(8px, 2.2vw, 23px); color: #223971 !important">
              <span>Войти</span>
            </a>

            <div v-if="loggedIn">
              <a class="margin-right: 30% !important" style=" background-color: white; color: black" type="button"
                data-bs-toggle="dropdown" aria-expanded="false" @click=" showMobileMenu = false">
                User
              </a>
              <ul class="dropdown-menu" v-if="isArtist == 'false'" style="position: absolute; right: 10px; left: 30%">
                <li>
                  <a class="dropdown-item" href="/cabinet/myrequest">Сообщения</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/cabinet/bookmarks">Избранное</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/cabinet/editaccountuser">Редактировать профиль</a>
                </li>
                <li><a class="dropdown-item" href="#" @click="logout">Выйти</a></li>
              </ul>
              <ul class="dropdown-menu" style="position: absolute; right: 10px; left: 30%" v-else>
                <li>
                  <a class="dropdown-item" href="/cabinet/profileslist">{{ $t('questionary') }}</a>
                </li>
                <li><a class="dropdown-item" href="/cabinet/views">{{ $t('views') }}</a></li>
                <li><a class="dropdown-item" href="/cabinet/requests">{{ $t('requests') }}</a></li>
                <li>
                  <a class="dropdown-item" href="/cabinet/editaccountprofile">{{ $t('profile') }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/cabinet/marketing">{{ $t('marketing') }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/cabinet/offers">{{ $t('special_requests') }}</a>
                </li>
                <li><a class="dropdown-item" href="#" @click="logout">{{ $t('logout') }}</a></li>
              </ul>
            </div>
          </div>
        </div>



        <div class="collapse navbar-collapse justify-content-end mt-4" id="navbarNavDropdown">
          <ul class="navbar-nav spaced-nav" style="
              border-top: 4px solid #87cff4;
              border-bottom: 4px solid #87cff4;
              padding: 0.6%;
              width: 80%;
              display: flex;
              align-items: center;
              justify-content: space-between; /* или другое значение по вашему выбору */

            ">
            <li class="nav-item m-1">
              <a class="nav-link navtextsize" :class="{ activecategory: shownav, active: shownav }" aria-current="page"
                @click="shownav = !shownav" style="font-size: clamp(8px, 2.2vw, 23px); color: #223971 !important">{{
                  $t('CATALOG') }}
              </a>
            </li>
            <Guide />
            <li class="nav-item m-1">
              <a class="nav-link active navtextsize" aria-current="page" href="/news"
                style="font-size: clamp(8px, 2.2vw, 23px); color: #223971 !important">{{ $t('NEWS') }}</a>
            </li>
            <li class="nav-item m-1">
              <a class="nav-link navtextsize" href="/advancedsearch"
                style="font-size: clamp(8px, 2.2vw, 23px); color: #223971 !important">{{ $t('ADVANCED_SEARCH') }}</a>
            </li>
            <li class="nav-item m-1" v-if="loggedIn">
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="notificationDropdown"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="badge rounded-pill bg-danger" v-if="notifications.length > 0" style="margin-right: 5px">{{
                    notifications.length }}</span>

                  <i class="bi bi-bell"></i>
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notificationDropdown">
                  <li class="p-3" v-if="notifications.length > 0">
                    <q-btn color="primary" @click="clearNotifies()" style="cursor: pointer">{{ $t('MARK_AS_READ')
                    }}</q-btn>
                  </li>
                  <hr />
                  <li v-for="notification in notifications" :key="notification.id">
                    <a class="dropdown-item" href="#" @click="noti(notification)">{{
                      notification.text
                    }}</a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="nav-item m-1" v-if="!loggedIn">
              <a class="nav-link navtextsize" href="/login"
                style="font-size: clamp(8px, 2.2vw, 23px); color: #223971 !important">
                <span>ВОЙТИ </span>
                <span> / </span>
                <span> РЕГИСТРАЦИЯ</span>
                <img src="./assets/user.png" style="width: 2.1vw; cursor: pointer; margin-left: 1vw" />
              </a>
            </li>

           
            <li class="nav-item m-1" v-if="loggedIn">
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" style="background-color: white; color: black"
                  type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img :src="'https://backend.chesnok.kz/storage/' + avatar" alt=""
                    style="border-radius: 47%; height: 2.5vh" />
                  {{ username }}
                </button>
                <ul class="dropdown-menu" v-if="isArtist == 'false'">
                  <li>
                    <a class="dropdown-item" href="/cabinet/myrequest">Сообщения</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/cabinet/bookmarks">Избранное</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/cabinet/editaccountuser">Редактировать профиль</a>
                  </li>
                  <li><a class="dropdown-item" href="#" @click="logout">Выйти</a></li>
                </ul>
                <ul class="dropdown-menu" v-else>
                  <li>
                    <a class="dropdown-item" href="/cabinet/profileslist">{{ $t('questionary') }}</a>
                  </li>
                  <li><a class="dropdown-item" href="/cabinet/views">{{ $t('views') }}</a></li>
                  <li><a class="dropdown-item" href="/cabinet/requests">{{ $t('requests') }}</a></li>
                  <li>
                    <a class="dropdown-item" href="/cabinet/editaccountprofile">{{ $t('profile') }}</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/cabinet/marketing">{{ $t('marketing') }}</a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/cabinet/offers">{{ $t('special_requests') }}</a>
                  </li>
                  <li><a class="dropdown-item" href="#" @click="logout">{{ $t('logout') }}</a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </nav> -->

    <div :class="{ 'mobile-menu-white': activeTab === 3, 'mobile-menu': activeTab != 3 }" v-show="showMobileMenu">
      <div class="mobile-menu-tabs">
        <div @click="activateTab(1)"
          :class="{ active: activeTab === 1, 'tab-white': activeTab === 3, 'tab': activeTab != 3 }">Каталог</div>
        <div @click="activateTab(2)"
          :class="{ active: activeTab === 2, 'tab-white': activeTab === 3, 'tab': activeTab != 3 }">Гид</div>
        <div @click="activateTab(3)"
          :class="{ 'active-white': activeTab === 3, 'tab-white': activeTab === 3, 'tab': activeTab != 3 }">Новости
        </div>
      </div>
      <div class="tab-content" v-show="activeTab === 1">
        <Catalogmobile />
      </div>
      <div class="tab-content" v-show="activeTab === 2">
        <Guidemobile />
      </div>
      <div class="tab-content" v-show="activeTab === 3">
        <!-- <Newsmobile /> -->
      </div>
    </div>
    <main>
      <transition name="slide-fade">
        <div class="drpdownmenu " v-show="shownav">
          <div class="row">
            <div class="col-4">
              <li v-for="category in firstColumn" :key="category.id">
                <a class="dropdown-item" :href="'/categories/' + category.id">{{
                  category.name
                }}</a>
              </li>
            </div>
            <div class="col-4">
              <li v-for="category in secondColumn" :key="category.id">
                <a class="dropdown-item" :href="'/categories/' + category.id">{{
                  category.name
                }}</a>
              </li>
            </div>
            <div class="col-4">
              <li v-for="category in thirdColumn" :key="category.id">
                <a class="dropdown-item" :href="'/categories/' + category.id">{{
                  category.name
                }}</a>
              </li>
            </div>
          </div>
        </div>
      </transition>

      <router-view></router-view>
    </main>
    <Footer style="margin-top: 80px;" />

    <!-- <footer class="footerr text-light py-3">
      <div class="container-fluid p-4" style="color: gray; font-size: 12.75pt; padding-left: 3% !important">
        <span class="m-3" v-for="page in footerPages" @click="goFooterPage(page.id)" style="cursor: pointer;">{{ page.name }} </span>

        <span class="p-3"    @click="this.$router.push('/contacts')" style="cursor: pointer;">Контакты </span>
        <br />
        <span class="p-3" style="cursor: pointer;"> Ⓒ2023 CHESNOK, “Все по чесноку”, “По чесноку говоря” Все права защищены
        </span>
      </div>
    </footer> -->
    <!-- <footer class="footerrformobile bg-dark text-light py-3"
      style="background-color: #d9d9d9 !important; min-height: 25vh">
      <div class="container p-4">
        <div class="row">
          <div class="col-6">
            <ul style="list-style-type: none; color: black; font-size: 20.4px">
              <li style="font-size: 8.5pt" v-for="page in footerPages" @click="goFooterPage(page.id)">{{ page.name }}</li>
            </ul>
          </div>
          <div class="col-6">
            <ul style="list-style-type: none; color: black; font-size: 24px">
              <li style="font-size: 10pt">Карта сайта</li>
              <li style="font-size: 10pt">Написать в службу заботы</li>
              <li style="font-size: 10pt">Скачать приложение</li>
              <li style="font-size: 10pt"  @click="this.$router.push('/contacts')">Контакты</li>

            </ul>
          </div>
          <div class="col-12 mt-5">
            <p style="color: black; font-size: 17px; text-align: center">
              Ⓒ2023 Все права защищены. <br />
              “CHESNOK”
            </p>
          </div>
        </div>
      </div>
    </footer> -->
  </div>
</template>

<script>
import axios from "axios";

import TopBar from "./components/TopBar.vue";

import SocialMediaLinks from "./components/SocialMediaLinks.vue";
import Catalog from "./components/Catalog.vue";
import Catalogmobile from "./components/Catalogmobile.vue";
import Guide from "./components/Guide.vue";
import Guidemobile from "./components/Guidemobile.vue";
import ChangeLanguage from "./components/Changelanguage.vue";
import Newsmobile from "./components/Newsmobile.vue";
import SearchBar from "./components/SearchBar.vue";
import CitySelect from './components/CitySelect.vue';
import Footer from "./components/Footer.vue";
import Modal from "./components/Modal.vue";
import '@fortawesome/fontawesome-free/css/all.css';


export default {
  
  name: "App",
  data() {
    return {
      isDarkTheme: false,

      activeTab: 1, // Изначально активен первый таб

      showMobileMenu: false,

      showModal: false,
      shownav: false,
      loggedIn: false,
      username: "",
      notifications: [], // Add notifications array
      categories: [],
      footerPages: [],
    };
  },
  components: {
    Catalog,
    Guide,
    SearchBar,
    SocialMediaLinks,
    CitySelect,
    Catalogmobile,
    Guidemobile,
    Newsmobile,
    ChangeLanguage,
    TopBar,
    Footer
  },
  computed: {
    firstColumn() {
      const third = Math.ceil(this.categories.length / 3);
      return this.categories.slice(0, third);
    },
    secondColumn() {
      const third = Math.ceil(this.categories.length / 3);
      return this.categories.slice(third, 2 * third);
    },
    thirdColumn() {
      const third = Math.ceil(this.categories.length / 3);
      const start = 2 * Math.floor(this.categories.length / 3); // Изменено здесь
      return this.categories.slice(start);
    },
  },

  created() {
    // this.checkToken();
    this.fetchFooterPage();
    // this.getCategoriesInfo();
    document.title = "Chesnok.kz - все по чесноку";

    if (localStorage.getItem('darkTheme') === 'true') {
      this.isDarkTheme = true;
      document.body.classList.add('dark-theme');
    }



    if (!localStorage.getItem("selectedCity")) {
      const wholeKazakhstan = {
        id: 0,
        city_name: "Весь Казахстан",
      };
      localStorage.setItem("selectedCity", JSON.stringify(wholeKazakhstan));
    }

    this.isArtist = localStorage.getItem("isArtist");
    this.username = localStorage.getItem("username");
    this.avatar = localStorage.getItem("avatar");

    this.loggedIn = localStorage.getItem("isLogged");

    let token = localStorage.getItem("token");


    let theme = localStorage.getItem("darkTheme")
    console.log('смотрим че в земе');
    console.log(theme)
    // document.body.classList.toggle('dark-theme', theme);

  },
  methods: {
    goFooterPage(id) {
      document.location.href = '/page/' + id;
    },
    fetchFooterPage() {
      axios.get('https://backend.chesnok.kz/api/pages').then(response => {
        this.footerPages = response.data.pages;
      })
    },
    toggleDarkTheme() {
      this.isDarkTheme = !this.isDarkTheme;
      // localStorage.setItem('darkTheme', this.isDarkTheme);
      document.body.classList.toggle('dark-theme', this.isDarkTheme);
    },
    activateTab(tabNumber) {
      if (tabNumber == 3) {
        document.location.href = '/news';
      }
      this.activeTab = tabNumber;
    },
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    clearNotifies() {
      let token = localStorage.getItem("token");

      console.log("clear");
      axios
        .post(
          "https://backend.chesnok.kz/api/clearNotifies",
          {
            id: this.user.id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          alert("Прочитано!");
          document.location.reload();
        })
        .catch((error) => { });
    },
    logout() {
      localStorage.removeItem("isLogged");
      this.loggedIn = null;
      localStorage.removeItem('isArtist');
      localStorage.removeItem('isLogged');
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('avatar');
      window.location.href = "/";
    },
    noti(noti) {
      console.log(noti);
      if (noti.type == "message") {
        localStorage.setItem("activeChatId", noti.chat_id);
        console.log(this.$router.currentRoute.value.path);
        console.log('-');
        if (this.$router.currentRoute.value.path == "/cabinet/requests") {
          console.log('hi');
          window.location.reload();
        } else {
          console.log('--');
          console.log(this.isArray);
          if (this.isArtist == 'false') {
            this.$router.push("/cabinet/myrequest");

          } else {
            this.$router.push("/cabinet/requests");

          }
        }
      }
      let a = localStorage.getItem("activeChatId");
      console.log(a);
    },
    async checkToken() {
      // Get the token from local storage
      const token = localStorage.getItem('token');

      try {
        // Set up the Authorization header with the token
        const headers = {
          'Authorization': `Bearer ${token}`
        };

        // Send a POST request to the /api/me endpoint with the token in the header
        const response = await axios.post('https://backend.chesnok.kz/api/me', {}, { headers });

        // If the request is successful, the token is active
        console.log('Token is active:', response.data);
        // Handle the active token scenario here
      } catch (error) {
        // If the request fails, the token might not be active
        console.log('Token is not active:', error.response);

        // Clear the local storage
        localStorage.removeItem('isArtist');
        localStorage.removeItem('isLogged');
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('avatar');

        // Redirect to login page or do any other necessary cleanup
        // Replace '/login' with your actual login route
        // this.$router.push('/login');
      }
    },
    getCategoriesInfo() {
      axios
        .get("https://backend.chesnok.kz/api/categories")
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => { });
    },
    getUserInfo() {
      console.log("Вошли");
      console.log(this.loggedIn);
      if (this.loggedIn == "true") {
        console.log("Истина");
        let token = localStorage.getItem("token");

        axios
          .post(
            "https://backend.chesnok.kz/api/me",
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            this.user = response;
            axios
              .post(
                "https://backend.chesnok.kz/api/notifies",
                {
                  id: this.user.id,
                },
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then((response) => {
                this.notifications = response.data.data;
                console.log(this.notifies);
              })
              .catch((error) => { });
          })
          .catch((error) => { });
      } else {
        console.log("вышли");
      }
    },
  },
};
</script>

<style>
#app {
  touch-action: pan-x pan-y !important;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  touch-action: pan-x pan-y !important;
  font-family: Montserrat, sans-serif !important;
  overflow-x: hidden;
  margin: 0 auto !important;
  /* Автоматические отступы по бокам */
  max-width: 1900px;
  /* Максимальная ширина body */
}

body.dark-theme {
  background-color: #2c2c2c !important;
  /* Example dark background */
  color: #ffffff !important;
  /* Example light text color */
}

/* You can add specific styles for other elements as well */
body.dark-theme a,
body.dark-theme p {
  color: #ffffff !important;
  /* Light text for dark background */
}

body.dark-theme span {
  color: white !important
}

.selectlang {
  display: none;
}

.mobile-menu {
  display: none;
}

.loginmobile {
  display: none;
}

.arrow-icon {
  display: none;
}

.navtextsizemobile {
  display: none;
}

.gpsbtn {
  margin: 3% 1px;
}

.rightrow {
  display: none;
}

.container_events {
  margin-bottom: 222px solid black !important;

}

.btn-primary {
  background: #223971 !important;
  border: 1px solid #223971 !important;
}

.btn-info {
  background: #223971 !important;
  border: 1px solid #223971 !important;
  color: whitesmoke !important;
}

.spaced-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media screen and (min-resolution: 20dpi) {
  .city_name {
    font-size: 0.5vw !important
  }

  .selectcityicon {
    width: 1vw !important;
    margin-right: 1vw !important;
  }

  .h2meropr {
    font-size: 1vw;
  }

  .pmeropr {
    font-size: 1vw;
  }
}

@media screen and (min-resolution: 40dpi) {
  .city_name {
    font-size: 1.14vw !important
  }

  .navbar-brand img {
    margin-top: 1vh;
  }

  .selectcityicon {
    width: 1.6vw !important;
    margin-right: 1vw !important;
  }

  .h2meropr {
    font-size: 1vw;
  }

  .pmeropr {
    font-size: 1vw;
  }
}

@media screen and (min-resolution: 60dpi) {
  .city_name {
    font-size: 1.24vw !important
  }

  .selectcityicon {
    width: 1.6vw !important;
    margin-right: 1vw !important;
  }

  .h2meropr {
    font-size: 1.5vw;
  }

  .pmeropr {
    font-size: 1vw;
  }
}

@media screen and (min-resolution: 90dpi) {
  .city_name {
    font-size: 1.34vw !important
  }

  .h2meropr {
    font-size: 1.7vw;
  }

  .pmeropr {
    font-size: 1.36vw;
  }

  .navbar-brand img {
    margin-top: 5vh;
  }

  .selectcityicon {
    width: 1.3vw !important;
    margin-right: 1vw !important;
    margin-bottom: 0.5vh !important;
  }

  .iconssocial {
    width: 16vw !important;
  }

  .navtextsize {
    font-size: 1.3vw !important;
    color: #223971 !important;
    cursor: pointer !important;
  }

  .logoimg {
    width: 24vw;
  }
}

@media screen and (min-resolution: 100dpi) {
  .pmeropr {
    font-size: 1.36vw;
  }

  .navbar-brand img {
    margin-top: 5vh;
  }

  .selectcityicon {
    width: 1.6vw !important;
    margin-right: 1vw !important;
    margin-bottom: 0.5vh !important;
  }

  .iconssocial {
    width: 15vw !important;
  }

  .navtextsize {
    font-size: 1.2vw !important;

    /* font-size: clamp(6px, 1.9vw, 20px) !important; */
    color: #223971 !important;
    cursor: pointer !important;
  }

  .logoimg {
    width: 22vw !important;
  }
}

@media screen and (min-resolution: 120dpi) {
  .selectcityicon {
    width: 1.6vw !important;
    margin-right: 1vw !important;
  }

  .iconssocial {
    width: 14vw !important;
  }

  .navtextsize {
    font-size: 1.1vw !important;

    /* font-size: clamp(6px, 1.9vw, 14px) !important; */
    color: #223971 !important;
    cursor: pointer !important;
  }

  .logoimg {
    width: 20vw !important;
  }
}

@media screen and (min-resolution: 140dpi) {
  .selectcityicon {
    width: 1.6vw !important;
    margin-right: 1vw !important;
  }

  .iconssocial {
    width: 13vw !important;
  }

  .navtextsize {
    font-size: 1vw !important;
    color: #223971 !important;
    cursor: pointer !important;
  }
}

.collapsing-enter-active,
.collapsing-leave-active {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  overflow: hidden;
}

.collapsing-enter,
.collapsing-leave-to

/* .collapsing-leave-active в <2.1.8 */
  {
  max-height: 0;
  opacity: 0;
}

.drpdownmenu {
  padding-left: 42.3vw;
  padding-bottom: 3vh;
  list-style-type: none;
}

.mobile-search-icon {
  display: none;
}

.activecategory {
  text-shadow: 2px 2px 5px #888888;
}

@font-face {
  font-family: Montserrat, sans-serif !important;
  src: url("Proxima.TTF") format("TTF");
  font-weight: normal;
  font-style: normal;
}

@media (min-width: 901px) {
  .footerrformobile {
    display: none;
  }

  .footerrformobile ul li {
    font-size: 3px;
  }


}

@media (max-width: 980px) {

  .selectlang {
    display: block;
  }

  .special {
    font-size: 10pt !important;
  }

  .mobile-menu {
    display: block;
    background-color: #303030;
    /* Изменили цвет фона */
    padding-left: 2vw;
    width: 100%;
    height: 100%;
    z-index: 9999;
    justify-content: left;
    align-items: center;
  }

  .mobile-menu-white {
    display: block;
    background-color: #ffffff;
    /* Изменили цвет фона */
    padding-left: 2vw;
    width: 100%;
    height: 100%;
    z-index: 9999;
    justify-content: left;
    align-items: center;
  }

  .mobile-menu-tabs {
    display: flex;
    flex-direction: row;
    margin-top: 3vh;
    margin-bottom: 20px;
  }

  .tab {
    padding: 10px 0px 0px 0px;
    margin-right: 40px !important;
    background-color: transparent;
    /* Изменили цвет фона таба */
    color: white;
    cursor: pointer;
    font-size: 14pt;
    margin-right: 10px;
    font-weight: bold;
    /* Сделали текст таба жирным */
    border-bottom: 2px solid transparent;
    /* Добавили нижнее подчеркивание */
    transition: border-bottom-color 0.2s;
    /* Добавили анимацию подчеркивания */
  }

  .tab-white {
    padding: 10px 0px 0px 0px;
    margin-right: 40px !important;
    background-color: transparent;
    /* Изменили цвет фона таба */
    color: black;
    cursor: pointer;
    font-size: 14pt;
    margin-right: 10px;
    font-weight: bold;
    /* Сделали текст таба жирным */
    border-bottom: 2px solid transparent;
    /* Добавили нижнее подчеркивание */
    transition: border-bottom-color 0.2s;
    /* Добавили анимацию подчеркивания */
  }

  .tab.active {
    background-color: transparent;
    color: #7ecae4;

    border-bottom: 8px solid white;
  }

  .tab-white.active-white {
    background-color: transparent;
    color: #7ecae4 !important;

    border-bottom: 8px solid white;
  }

  .tab-content {
    /* display: none; */
    margin-top: 3vh;
    padding-top: 3vh;

    border-top: 3px solid rgb(177, 173, 173);
    color: black;
  }

  .tab-content.active {
    display: block;
  }



  .SocialMediaLinks {
    display: none;
  }

  .footerr {
    display: none;
  }

  .carouseltop {
    /* display: none !important; */
  }

  .carousel-indicator-top-carousel {
    display: none !important;
  }

  .city_name {
    font-size: 10pt !important;
  }

  .navbar-toggler {
    margin-bottom: 1.1vh !important;
    margin-top: 0.9vh !important;
  }

  .loginmobile {
    display: block;
    text-align: center;
    /* display: inline !important; */
  }

  .dropdown {
    margin: 0px !important;
    padding: 0px !important;
  }

  .navtextsizemobile {
    display: inline;
    font-size: 10pt !important;
    font-weight: bold !important;
  }

  .gpsbtn {
    color: black !important;
    padding: 0px;
  }

  .gpsbtn span {
    font-size: 10pt !important;
  }

  .rightrow {
    display: inline;
  }


  .selectcityicon {
    display: none;
  }



  .nameartist {
    font-size: 3.7vw !important;
  }

  .fee {
    font-size: 2.5vw !important;
  }

  .mobile-search-icon {
    display: inline;
    padding-left: 9px;
  }

  .mobile-search-icon img {
    height: 3.7vh;
  }

  .navbar-brand img {
    margin-top: 2vh;
    width: 20vh !important;
  }



  .crslcontainer {
    padding: 3vw;
  }

  .soon-h4 {
    font-size: 3.2vw !important;
    color: rgb(59, 59, 59) !important;
    font-weight: bold;
  }

  .horizontal-line {
    border: 2px solid #7fccf4 !important;
  }

  .container_events {
    background-color: #223971;
    border-top: 4px solid #7fccf4;
    margin-bottom: 222px solid black !important;
  }

  .hr_events {
    display: none;
  }

  .h2meropr {
    padding: 2vh 14vw !important;
    font-size: 3vw;
    color: white !important;
  }

  .pmeropr {
    padding: 0px !important;
    font-size: 1vw;
    display: none;
  }
}
























.login {
  border: 1px solid #569193 !important;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.reg {
  background-color: #569193 !important;
  border: none !important;
}

main {
  flex: 1;
}

.navbar-nav {
  display: flex;
}

.navbar-nav>* {
  order: 0;
}

.navbar-nav .order-last {
  order: 1;
}

@media (min-width: 992px) {
  .navbar-nav {
    flex-direction: row;
  }

  .navbar-nav .order-last {
    order: 0;
  }
}

.navbar-toggler-icon {
  filter: invert(100%);
}

.nav-link {
  color: black !important;
}

.btn-outline-danger {
  border-color: rgb(61, 210, 204) !important;
  color: rgb(61, 210, 204) !important;
}

.btn-outline-danger:hover {
  background-color: rgb(245, 241, 241) !important;
}

.btn-outline-custom:hover {
  background-color: rgb(61, 210, 204);
  color: white;
}

/* Add any custom styling here */</style>
