<template>
    <div v-if="reviews.length">
        <h3 class="mb-3">  {{ $t('COMMENTS') }}:</h3>
        <div v-for="comment in reviews" :key="comment.id" class="mb-4">
            <div class="card">
                <div class="row">
                    <div class="col-11">
                        <div class="card-body">
                    <h5 class="card-title">{{ comment.comment }} </h5>
                    <div>
                        <span v-for="star in 5" :key="star"
                            :class="star <= Number(comment.rate) ? 'text-warning' : 'text-secondary'">
                            ★
                        </span>
                    </div>
                    <p class="card-text">{{ comment.text }}</p>

                </div>
                    </div>
                    <div class="col-1">
                        <button class="btn btn-danger float-right" @click="deleteReview(comment.id)">X</button>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        profileId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            reviews: [],
        };
    },
    methods: {
        fetchReviews() {
            let token = localStorage.getItem('token');

            axios.post('https://backend.chesnok.kz/api/reviewsForProfile', {
                id: this.profileId,
            },
                { headers: { 'Authorization': `Bearer ${token}` } }
            ).then(response => {
                this.reviews = response.data;
            })
        },
        deleteReview(reviewId) {
            let token = localStorage.getItem('token');

            axios.post('https://backend.chesnok.kz/api/destroyReview', {
                profileId: this.profileId,
                reviewId: reviewId
            },
                { headers: { 'Authorization': `Bearer ${token}` } }
            ).then(response => {
                // После успешного удаления обновить список комментариев
                this.fetchReviews();
            }).catch(error => {
                // Обработать ошибку
                console.error(error);
            });
        }
    },
    computed: {
        computedValue() {
            return this.counter * 2;
        }
    },
    watch: {
        counter(newVal, oldVal) {
            console.log(`Счетчик обновлен: ${oldVal} -> ${newVal}`);
        }
    },
    created() {
        this.fetchReviews();
        console.log('Хук created');
    },
    beforeMount() {
        console.log('Хук beforeMount');
    },
    mounted() {
        console.log('Хук mounted');
    },
    beforeUpdate() {
        console.log('Хук beforeUpdate');
    },
    updated() {
        console.log('Хук updated');
    },
    beforeDestroy() {
        console.log('Хук beforeDestroy');
    },
    destroyed() {
        console.log('Хук destroyed');
    },
}
</script>

<style scoped>
h1 {
    font-size: 2em;
}

p {
    font-size: 1em;
}

button {
    margin: 1em 0;
}
</style>
