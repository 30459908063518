<template>
    <li class="nav-item dropdown" >
      <a class="nav-link  m-1 navtextsize" style="color:#223971 !important; font-size: clamp(8px, 2.2vw, 23px);;" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        {{ $t('GUIDE') }}
      </a>
      <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
        <li><a class="dropdown-item" href="/customer">Вы заказчик?</a></li>
        <li><a class="dropdown-item" href="/managers">Вы профи?</a></li>
        <li><a class="dropdown-item" href="/about">О нас</a></li>
      </ul>
    </li>
  </template>

  <script>
  export default {
    name: 'Guide'
  }
  </script>

  <style >
  body {
    font-family: Montserrat, sans-serif !important;
}
  /* Добавьте здесь свои стили */
  </style>