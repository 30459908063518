<template>
  <div class="container">
    <div class="p-3">
      <!-- <h4 class="mb-3" style="color: gray; font-weight: 600;">{{ categoryName }}:</h4> -->
    </div>
    <div
      v-for="item in paginatedItems"
      :key="item.id"
      class="row p-3 mb-4 pagitem desktopview"
      @click="go(item)"
      style=""
    >
      <div class="col-3 d-flex justify-content-center align-items-center">
        <img
          v-if="item.avatar"
          :src="'https://backend.chesnok.kz/storage/' + item.avatar"
          class="product-image"
          alt="Product image"
        />
      </div>
      <div class="col-9">
        <div class="row">
          <div class="col-12">
            <h3
              style="
                text-transform: uppercase;
                font: 600 40px Montserrat, sans-serif;
              "
            >
              {{ item.name }}
            </h3>
          </div>
          <div class="col-6">
            <div
              v-if="!item.hideprice"
              style="
                color: rgba(0, 0, 0, 0.8);
                font-family: Montserrat, sans-serif !important;
                font-weight: 400;
              "
            >
              <div v-if="item.from == null && item.before == null">
                <p>
                  <li class="titlerow">
                    <span style="" class="titlerow">Стоимость:</span> Гонорар по
                    запросу
                  </li>
                </p>
              </div>
              <div v-if="item.from != null && item.before == null">
                <p>
                  <li class="titlerow">
                    <span style="" class="titlerow">Стоимость:</span>
                    <b>Гонорар от {{ item.from }}</b>
                  </li>
                </p>
              </div>
              <div v-if="item.from == null && item.before != null">
                <p>
                  <li class="titlerow">
                    <span style="" class="titlerow"> Стоимость:</span>
                    <b>Гонорар до {{ item.before }}</b>
                  </li>
                </p>
              </div>
              <div v-if="item.from != null && item.before != null">
                <p>
                  <li class="titlerow">
                    <span style="" class="titlerow">Стоимость:</span>
                    <b>Гонорар ({{ item.from }} - {{ item.before }})</b>
                  </li>
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <li
              class="titlerow"
              style="
                font-family: Montserrat, sans-serif !important;
                font-weight: 400;
              "
            >
              <span style="color: gray"><i class="titlerow">Рейтинг:</i></span>
              <span
                v-for="(star, index) in 5"
                style="font-size: 14pt"
                :key="index"
                class="star"
                :class="index < item.starrate ? 'gold' : 'grey'"
              >
                ★
              </span>
            </li>
          </div>
          <div class="col-6 mt-2">
            <li
              class="titlerow"
              style="
                font-family: Montserrat, sans-serif !important;
                font-weight: 400;
              "
            >
              <span class="titlerow">Отзывы: {{ item.reviews.length }}</span>
            </li>
          </div>
          <div class="col-12 mt-5">
            <div class="row">
              <div class="col-3 p-3" @click="go(item)" style="cursor: pointer">
                <span
                  class="writetoartist"
                  style="
                    font-family: Montserrat, sans-serif;
                    font-size: 16pt;
                    font-weight: 600;
                  "
                  >Написать</span
                >
              </div>
              <div class="col-4 p-3" @click="go(item)" style="cursor: pointer">
                <span
                  style="
                    color: black;
                    font-family: Montserrat, sans-serif;
                    font-size: 16pt;
                    font-weight: 600;
                  "
                  >Смотреть фото
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cb65ed88738e8cbca137bd201e3b929af0df66ce0b973cba6e517782edcdd0c?"
                    class="img"
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-for="item in paginatedItems"
      :key="item.id"
      class="row p-3 m-1 mb-4 pagitem mobileview p-0"
      @click="go(item)"
      style=""
    >
      <div class="col-12 p-0">
        <div class="row">
          <div class="col-12">
            <h3
              style="
                text-transform: uppercase;
                font: 600 20px Montserrat, sans-serif;
              "
            >
              {{ item.name }}
            </h3>
          </div>

          <div class="col-12 d-flex justify-content-center align-items-center">
            <img
              v-if="item.avatar"
              :src="'https://backend.chesnok.kz/storage/' + item.avatar"
              class="product-image"
              alt="Product image"
            />
          </div>

          <div class="col-12 pt-3">
            <div
              v-if="!item.hideprice"
              style="
                color: rgba(0, 0, 0, 0.8);
                font-family: Montserrat, sans-serif !important;
                font-weight: 400;
              "
            >
              <div v-if="item.from == null && item.before == null">
                  <li class="titlerow" style="color: gray">
                    <span style="" class="titlerow">Стоимость:</span> <span style="color: black">Гонорар по
                    запросу</span>
                  </li>
              </div>
              <div v-if="item.from != null && item.before == null">
                <p>
                  <li class="titlerow" style="color: gray">
                    <span style="" class="titlerow" >Стоимость:</span>
                    <b style="color: black !important">Гонорар от {{ item.from }}</b>
                  </li>
                </p>
              </div>
              <div v-if="item.from == null && item.before != null">
                <p>
                  <li class="titlerow">
                    <span style="" class="titlerow"> Стоимость:</span>
                    <b>Гонорар до {{ item.before }}</b>
                  </li>
                </p>
              </div>
              <div v-if="item.from != null && item.before != null">
                <p>
                  <li class="titlerow">
                    <span style="" class="titlerow">Стоимость:</span>
                    <b>Гонорар ({{ item.from }} - {{ item.before }})</b>
                  </li>
                </p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <li
              class="titlerow"
              style="
                font-family: Montserrat, sans-serif !important;
                font-weight: 400;
                color: gray;
              "
            >
              <span style="color: gray">Рейтинг:</span>
              <span
                v-for="(star, index) in 5"
                style="font-size: 14pt"
                :key="index"
                class="star"
                :class="index < item.starrate ? 'gold' : 'grey'"
              >
                ★
              </span>
            </li>
          </div>
          <div class="col-6">
            <li
              class="titlerow"
              style="
                font-family: Montserrat, sans-serif !important;
                font-weight: 400;
                color: gray;
              "
            >
              <span class="titlerow">Отзывы: </span><span style="color: black">{{ item.reviews.length }}</span>
            </li>
          </div>
          <div class="col-12 mt-2">
            <div class="row">
              <div class="col-12 p-3" @click="go(item)" style="cursor: pointer">
                <span
                  class="writetoartist"
                  style="
                    font-family: Montserrat, sans-serif;
                    font-size: 16pt;
                    font-weight: 400;
                  "
                  >Написать артисту</span
                >
              </div>
              <div class="col-12 p-3 pt-1" @click="go(item)" style="cursor: pointer">
                <span
                  style="
                    color: black;
                    font-family: Montserrat, sans-serif;
                    font-size: 16pt;
                    font-weight: 400;
                  "
                  >Смотреть фото
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cb65ed88738e8cbca137bd201e3b929af0df66ce0b973cba6e517782edcdd0c?"
                    class="img"
                /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nav v-if="totalPages > 1" aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a
            class="page-link"
            href="#"
            aria-label="Previous"
            @click="changePage(currentPage - 1)"
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          class="page-item"
          v-for="page in totalPages"
          :key="page"
          :class="{ active: currentPage === page }"
        >
          <a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a
            class="page-link"
            href="#"
            aria-label="Next"
            @click="changePage(currentPage + 1)"
          >
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template><script>
import axios from "axios";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bookmarkedProfiles: [],
      items: [],
      isLoading: true,
      loggedIn: null,
      city: "",
      currentPage: 1,
      itemsPerPage: 6, // Change this value according to your needs
      categoryName: "",
    };
  },
  methods: {
    formatPrice(price) {
      return Number(price).toLocaleString("ru-RU");
    },
    getPriceText(item) {
      if (!item.hideprice) {
        if (item.from == null && item.before == null) {
          return this.$t("FEE_ON_REQUEST");
        } else if (item.from != null && item.before == null) {
          return `Гонорар от ${this.formatPrice(item.from)} ₸`;
        } else if (item.from == null && item.before != null) {
          return `Гонорар до ${this.formatPrice(item.before)} ₸`;
        } else if (item.from != null && item.before != null) {
          return (
            this.$t("FEE") +
            ` (${this.formatPrice(item.from)} ₸ - ${this.formatPrice(
              item.before
            )} ₸)`
          );
        }
      } else {
        return this.$t("FEE_ON_REQUEST");
      }
    },
    go(profile) {
      if (profile.type != "1") {
        this.$router.push("/show/" + profile.id);
      } else {
        this.$router.push("/show/food/" + profile.id);
      }
    },
    async fetchBookmarkedProfiles() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://backend.chesnok.kz/api/bookmarked-profiles",
          { headers: { Authorization: `Bearer ${token}` } }
        );
        this.bookmarkedProfiles = response.data.map((profile) => profile.id);
      } catch (error) {
        console.error(error);
      }
    },
    async bookmarkProfile(profileId) {
      if (this.loggedIn) {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.post(
            `https://backend.chesnok.kz/api/profiles/${profileId}/bookmark`,
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          );
          console.log(response.data.message);
          this.fetchBookmarkedProfiles();
          // Здесь вы можете обновить состояние иконки закладки на основе ответа сервера.
        } catch (error) {
          console.error(error);
        }
      } else {
        this.$router.push("/login");
      }
    },
    getCategoryInfo() {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.chesnok.kz/api/getCategoryInfo",
          {
            id: this.$route.params.id,
            city_id: this.city.id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.isLoading = false;
          this.items = response.data.profiles;
          this.categoryName = response.data.category.name;
          this.items.forEach((item) => {
            if (item.reviews && Array.isArray(item.reviews)) {
              let totalRate = 0;
              let totalReviews = item.reviews.length;
              item.reviews.forEach((review) => {
                totalRate += review.rate;
              });
              item.starrate =
                totalReviews > 0 ? Math.ceil(totalRate / totalReviews) : 0;
            } else {
              item.starrate = 0;
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
  computed: {
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.items.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  created() {
    this.loggedIn = localStorage.getItem("isLogged");
    this.city = JSON.parse(localStorage.getItem("selectedCity"));
    this.getCategoryInfo();
    this.fetchBookmarkedProfiles();
  },
};
</script>
<style scoped>
.mobileview{
  display: none;
}
body {
  font-family: Montserrat, sans-serif !important;
}
.writetoartist {
  color: #f55 !important;
}
.pagitem {
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}
body.dark-theme .pagitem {
  padding: 30px !important;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  background: #3e3e3e;
}
body.dark-theme .titlerow {
  color: rgba(255, 255, 255, 0.8) !important;
}
.star {
  font-size: 1.5em;
  color: grey;
}

.star.gold {
  color: gold;
}

.product {
  margin-bottom: 2em;
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.product:hover {
  box-shadow: 0 2px 5px rgba(32, 117, 214, 0.3);
}

.product-image {
  width: 100%;
  border-radius: 25px;
  /* Изменено на 100% */
  height: auto;
  /* Изменено с height: 100% на auto для сохранения пропорций */
  aspect-ratio: 1 / 1;
  /* Добавлено для обеспечения квадратного аспекта */
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 1px 1px 1px #00000040;
}

.page-link {
  color: rgb(106, 185, 242) !important;
}

.page-item.active .page-link {
  background-color: rgb(106, 185, 242);
  border-color: rgb(106, 185, 242);
  color: white !important;
}

@media (max-width: 900px) {
  .desktopview {
    display: none;
  }
  .mobileview{
    display: block
  }
}
</style>