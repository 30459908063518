<template>
  <div
    class="d-flex justify-content-center desktop-view"
    style="width: 30%; margin-left: 35%"
  >
    <div class="row">
      <div class="col-5 p-2 mb-2" style="text-align: left">
        <span
          class="mb-4 mt-0 registerlink"
          style="font: 200 20px Montserrat, sans-serif"
          @click="this.$router.push('/register')"
          >Регистрация</span
        >
      </div>
      <div class="col-7 p-2 mb-2" style="text-align: left">
        <span class="mb-4 mt-0" style="font: 300 20px Montserrat, sans-serif"
          ><b>Войти</b></span
        >
      </div>

      <form @submit.prevent="login">
        <div class="mb-3 mt-2">
          <input
            id="emailOrPhone"
            v-model="credentials.email"
            style=""
            type="text"
            class="form-control"
            placeholder="Email / телефон"
          />
        </div>

        <div class="mb-3">
          <input
            id="password"
            v-model="credentials.password"
            style=""
            type="password"
            class="form-control"
            placeholder="Пароль"
          />
        </div>

        <div
          class="mt-4"
          style="display: flex; align-items: center; justify-content: center"
        >
          <div class="form-check" style="margin-right: 10px">
            <input
              class="form-check-input"
              type="checkbox"
              style="
                border: 2px solid gray;
                width: 22px;
                height: 22px;
                border-color: #cd0b0b;
              "
              value=""
              id="remember"
            />
            <p
              class="mt-1"
              style="
                margin-left: 10px;
                margin-right: 20px;
                color: rgb(74, 74, 74);
              "
            >
              Запомнить
            </p>
          </div>
          <p class="mt-1" style="color: black"><a href="/restore" style="text-decoration: none; color: black">Забыли пароль?</a></p>
        </div>

        <div class="mb-4 mt-2">
          <button type="submit" class="btn rdbtn w-100"><b>Войти</b></button>
        </div>

        <div
          class="mb-3"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 10pt;
          "
        >
          <p>
            Нажимая кнопку входа, вы принимаете условия
            <span style="text-decoration: underline"
              >Пользовательского соглашения</span
            >
            и
            <span style="text-decoration: underline"
              >Политики конфиденциальности</span
            >
          </p>
        </div>
      </form>
    </div>
  </div>

  <div class="d-flex justify-content-center mobile-view p-4" style="width: 100%">
    <div class="row">
      <div class="col-5 p-2 mb-2" style="text-align: left">
        <span
          class="mb-4 mt-0 registerlink"
          style="font-size: 1rem;"
          @click="this.$router.push('/register')"
          >Регистрация</span
        >
      </div>
      <div class="col-7 p-2 mb-2" style="text-align: left">
        <span class="mb-4 mt-0" style="font: 500 18px Montserrat, sans-serif; font-weight: 400;"
          ><b>Войти</b></span
        >
      </div>

      <form @submit.prevent="login">
        <div class="mb-3 mt-2">
          <input
            id="emailOrPhone"
            v-model="credentials.email"
            style=""
            type="text"
            class="form-control"
            placeholder="Email / телефон"
          />
        </div>

        <div class="mb-3">
          <input
            id="password"
            v-model="credentials.password"
            style=""
            type="password"
            class="form-control"
            placeholder="Пароль"
          />
        </div>

        <div
          class="mt-4"
          style="display: flex; align-items: center; justify-content: space-between"
        >
          <div class="form-check" style="margin-right: 10px">
            <input
              class="form-check-input"
              type="checkbox"
              style="
                border: 2px solid gray;
                width: 22px;
                height: 22px;
                border-color: #cd0b0b;
              "
              value=""
              id="remember"
            />
            <p
              class="mt-1"
              style="
                margin-left: 10px;
                margin-right: 20px;
                color: rgb(74, 74, 74);
              "
            >
              Запомнить
            </p>
          </div>
          <p class="mt-1" style="color: black">Забыли пароль?</p>
        </div>

        <div class="mb-4 mt-2">
          <button type="submit" class="btn rdbtn w-100" style="padding: 15px !important"><b>Войти</b></button>
        </div>

        <div
          class="mb-3"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 10pt;
          "
        >
          <p>
            Нажимая кнопку входа, вы принимаете условия
            <span style="text-decoration: underline"
              >Пользовательского соглашения</span
            >
            и
            <span style="text-decoration: underline"
              >Политики конфиденциальности</span
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import { parsePhoneNumber } from "libphonenumber-js";

export default {
  setup() {
    const credentials = ref({
      email: "",
      password: "",
    });
    const loginErrors = ref({
      email: "",
      password: "",
    });

    const registrationErrors = ref({
      name: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    });
    const user = ref({
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      isArtist: false,
    });
    const showTerms = ref(false);

    const register = async () => {
      if (user.value.password != user.value.password_confirmation) {
        alert("Пароли должны совпадать");
        return;
      }

      axios
        .post("https://backend.chesnok.kz/api/register", {
          name: user.value.name,
          email: user.value.email,
          password: user.value.password,
          isartist: user.value.isArtist,
        })
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          // this.$router.push('/')
          alert("Регистрация прошла успешна, вы можете войти на сайт");
          window.location.href = "/login";
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const login = async () => {
      const isEmail = validateEmail(credentials.value.email);
      if (isEmail) {
        await loginWithEmail();
      } else {
        await loginWithPhoneNumber();
      }
    };

    const validateEmail = (email) => {
      email = email.trim();
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const loginWithEmail = async () => {
      try {
        const response = await axios.post(
          "https://backend.chesnok.kz/api/login",
          {
            email: credentials.value.email.trim(),
            password: credentials.value.password.trim(),
          }
        );
        handleLoginResponse(response);
      } catch (error) {
        console.error(error);
        alert("Данные не найдены");
      }
    };

    const loginWithPhoneNumber = async () => {
      try {
        const phoneNumber = parsePhoneNumber(credentials.value.email, "KZ");
        if (!phoneNumber.isValid()) {
          console.error("Invalid phone number");
          return;
        }
        const formattedPhoneNumber = phoneNumber.number;
        const response = await axios.post(
          "https://backend.chesnok.kz/api/login",
          {
            email: formattedPhoneNumber.trim(),
            password: credentials.value.password.trim(),
          }
        );
        handleLoginResponse(response);
      } catch (error) {
        console.error(
          "Invalid input. Please enter a valid phone number or email address"
        );
      }
    };

    const handleLoginResponse = (response) => {
      localStorage.setItem("isArtist", response.data.user.is_artist);
      localStorage.setItem("isLogged", true);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("username", response.data.user.name);
      localStorage.setItem("avatar", response.data.user.avatar);

      if (response.data.user.is_artist) {
        window.location.href = "/cabinet/profileslist";
      } else {
        window.location.href = "/";
      }
    };

    return { credentials, showTerms, login, register, user };
  },
};
</script>


<style scoped>
#emailOrPhone {
  border-radius: 11.722px;
  border-width: 1px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  padding: 14px 10px;
  font: 400 16px/122% Montserrat, sans-serif;
  border-color: rgba(0, 0, 0, 1);
}
#password {
  border-radius: 11.722px;
  border-width: 1px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  padding: 14px 10px;
  font: 400 16px/122% Montserrat, sans-serif;
  border-color: rgba(0, 0, 0, 1);
}

.mobile-view {
  display: none !important;
  /* Показать на мобильных устройствах */
}
.desktop-view {
  display: block !important;
  /* Скрыть на мобильных */
}
@media (max-width: 768px) {
  .mobile-view {
    display: block !important;
    /* Показать на мобильных устройствах */
  }
  .desktop-view {
    display: none !important;
    /* Скрыть на мобильных */
  }
}

.registerlink {
  color: #cd0b0b;
  font: 500 20px Montserrat, sans-serif;
}
body.dark-theme .registerlink {
  color: #cd0b0b !important;
  font: 500 20px Montserrat, sans-serif;
}

body.dark-theme #emailOrPhone {
  background: #3e3e3e !important;
  color: rgba(255, 255, 255, 0.499) !important;
  border: 0.5px rgba(255, 255, 255, 0.355) solid;
}

body.dark-theme #password {
  background: #3e3e3e !important;
  color: rgba(255, 255, 255, 0.499) !important;
  border: 0.5px rgba(255, 255, 255, 0.355) solid;
}

body.dark-theme input[type="text"]::placeholder {
  color: #ebe5e5;
}

body.dark-theme input[type="password"]::placeholder {
  color: #999;
}

.rdbtn {
  background-color: #cd0b0b !important;
  margin-top: 30px;
  width: 100%;
  color: #fff;
  white-space: nowrap;
  padding: 15px 60px;
  font: 200 18px/89% Montserrat, sans-serif !important;
}
</style>
