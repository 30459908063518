<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
        <!--
        <div class="container pt-1 pb-1">
          <Navcomponent></Navcomponent>
        </div> -->

        <div class="container-fluid p-0 carouseltop m-0">
          <Carousel></Carousel>
        </div>
        <div class="container">
          <div class="div" style="padding-top: 80px">
            <div class="div-2" style="padding-left: 1% !important">
              {{ $t("ANY_EVENTS") }}
            </div>
            <div class="div-3"></div>
            <div class="div-33"></div>
            <div class="div-4">
              {{ $t("HUGE_MARKET") }}
            </div>
          </div>
        </div>

        <div class="container-fluid" style="padding-top: 80px">
          <CarouselContainer></CarouselContainer>
        </div>
        <div class="container-fluid" style="padding-top: 80px">
          <adversefirst></adversefirst>
        </div>
        <div class="" style="padding-top: 80px">
          <div class="container-fluid p-0 m-0">
            <Recomended class="mt-3"></Recomended>
          </div>
        </div>
        <div class="container-fluid" style="padding-top: 80px">
          <adversesecond></adversesecond>
        </div>
        <div class="" style="padding-top: 80px">
          <div class="container-fluid p-0 m-0">
            <Firstbloc class="mt-3"></Firstbloc>
          </div>
        </div>
        <div style="padding-top: 80px;">
          <div class="container-fluid p-0 m-0">
            <adversethird></adversethird>
          </div>
        </div>
        <div class="" style="padding-top: 80px;">
          <div class="container-fluid p-0 m-0">
            <Chesnokreview></Chesnokreview>
          </div>
        </div>
        <!-- <div class="pt-3 mt-3 ">
          <div class="container-fluid p-0 m-0">
            <Mobileapps></Mobileapps>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import Carousel from "@/components/Carousel.vue";
import Firstbloc from "@/components/Firstbloc.vue";
import Toptoaster from "@/components/Toptoaster.vue";
import Topdj from "@/components/Topdj.vue";
import Recomended from "@/components/Recomended.vue";
import adversefirst from "@/components/Adversefirst.vue";
import adversesecond from "@/components/Adversesecond.vue";
import adversethird from "@/components/Adversethird.vue";
import SocialMediaLinks from "../components/SocialMediaLinks.vue";

import Navcomponent from "@/components/Navcomponent.vue";
import CarouselContainer from "@/components/CarouselContainer.vue";
import Mobileapps from "@/components/Mobileapps.vue";
import Chesnokreview from "@/components/Chesnokreview.vue";

export default {
  name: "HomeView",
  components: {
    SocialMediaLinks,
    adversesecond,
    adversefirst,
    adversethird,
    HelloWorld,
    Carousel,
    Firstbloc,
    Recomended,
    Navcomponent,
    Topdj,
    Toptoaster,
    CarouselContainer,
    Mobileapps,
    Chesnokreview,
  },
};
</script>

<style>
body {
  font-family: "Proxima Nova", sans-serif !important;
}
body.dark-theme .div-4 {
  color: gray !important;
}
h2 {
  font-family: "Proxima Nova", sans-serif !important;
}

@media (max-width: 900px) {
  .ads {
    display: none;
  }
}

.div {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #000;
  padding: 0 20px;
}

@media (max-width: 991px) {
  .div {
    flex-wrap: wrap;
  }
}

.div-2 {
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 700 26px/39px Montserrat, sans-serif;
}

@media (max-width: 991px) {
  .div-2 {
    max-width: 100%;
    font-size: 14px;
    font-weight: 700 !important;
  }
  .div-3 {
    display: none;
  }
  .div-33 {
    border-bottom: 2px solid #cd0b0b;
    min-height: 1px;
    width: 80%;
  }
  .div-4 {
    font-weight: 100 !important;
    font-size: 10pt !important;
  }
}

.div-3 {
  background-color: #cd0b0b;
  align-self: stretch;
  width: 3px;
  height: 79px;
}

.div-4 {
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  margin: 0.6% 0;
  font: 400 18px Montserrat, sans-serif;
}
</style>
