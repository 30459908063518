<template>
  <div class="reviechesnok container">
    <div>
      <div class="div p-0 mb-3">
        <div class="div-2">
          <span>{{ $t('FRANKLY_SPEAKING') }}</span>
        </div>
        <img loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/80231082d21146716fbffcf1dc7cc9f3b8c185e0011604b8696a52f72a7fb7c2?"
          class="img" />
      </div>

      <div class="mt-3">
        <iframe width="32%" height="300" style="margin-left: 3%; border-radius: 30px;"
          src="https://www.youtube.com/embed/6qsz1XdT8vc?si=5Q3aK2lYREcI9W0S" title="YouTube video player" frameborder="0"
          class="m-1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>

        <iframe width="32%" height="300" style="margin-left: 3%; border-radius: 30px;" class="m-1"
          src="https://www.youtube.com/embed/HeLMxFBk-s4?si=xCK87UctDB9HdLO6" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>

        <iframe width="32%" height="300" style="margin-left: 3%; border-radius: 30px;" class="m-1"
          src="https://www.youtube.com/embed/HeLMxFBk-s4?si=xCK87UctDB9HdLO6" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>
  </div>
  <div class="container reviechesnokmobile" style="
      box-shadow: 0px 4px 4px 7px #00000040;
      padding-left: 4%;
      padding-right: 4%;
      padding-top: 3%;
      padding-bottom: 3%;
    ">
    <div style="text-align: left">
      <h3>По чесноку говоря:</h3>
      <div class="video-container">
        <iframe width="70%" height="400" :src="'https://www.youtube.com/embed/ac_kS49M95w'" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<style>
body {
  font-family: Montserrat, sans-serif !important;
}

.lineleft {
  flex-grow: 1;
  height: 8px;
  background-color: #7fccf4;
  margin: 0px 30px 0px 0px;
}

.lineright {
  flex-grow: 1;
  height: 8px;
  background-color: #7fccf4;
  margin: 0px 0px 0px 30px;
}

.video-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 15px;
  width: 70%;
}

@media (min-width: 901px) {
  .reviechesnokmobile {
    display: none;
  }
}

@media (max-width: 900px) {
  .reviechesnok {
    display: none;
  }

  .video-container iframe {
    width: 100%;
    height: auto;
  }
}
</style>
