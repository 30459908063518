<template>
    <div class="container" >
      <ul >
        <li v-for="(newsItem, index) in news" :key="index" class="list-group-item news-item">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-3">
                  <img :src="newsItem.image" :alt="newsItem.title" class="demo-image" />
                </div>
                <div class="col-8">
                  <p>{{ newsItem.title }}</p>
                  <p>{{ newsItem.date }}</p>
                </div>
              </div>
              <p class="card-text">{{ newsItem.content }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        news: []
      };
    },
  
    created() {
      this.getNews();
    },
  
    methods: {
      async getNews() {
        try {
          // Здесь будет запрос к API для получения новостей
          // const response = await axios.get('<your API endpoint>')
          // this.news = response.data
  
          // Замените данными с вашего API
          this.news = [
            {
              title: 'Выгодные условия для профессионалов!',
              date: '26.04.23',
              content: '',
              image: 'https://img.freepik.com/premium-photo/digital-design-businessman-show-growth-graph-of-earning-with-digital-marketing-strategy_35761-549.jpg'
            }
          ];
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  </script>
  
  <style>
  .demo-image {
    width: 90%;
    max-width: 300px;
    height: 100%;
  }
  
  .news-item {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
  }
  </style>