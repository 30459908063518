<template>
  <div class="container">
    <div class="row mb-4 align-items-left">
      <div class="col-12 col-md-auto text-uppercase font-weight-bold h1 d-flex align-items-center footer-title" style="font-size: 58px;">Чеснок</div>
      <div class="col-12 col-md-auto d-flex align-items-center icon-container">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c6101e77bcd0380b478f87d70caf6867d777ac0e516fcb66be6697a854c79082?" class="img-fluid" />
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8b698b3ffb59741fb6a32f4b2b2ef8529a474e2daf7e3f8b90ffc761fbfaa5c?" class="img-fluid" />
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d329d0e040c9890efbe9246ec7f3fdae54a3f8b2b8e857f7718d765eeee3590?" class="img-fluid" />
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9cebe7f9799430e52bb3e01524f18b9c89fbbbfcf85e585746a4e5d502a78273?" class="img-fluid" />
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c0b7107a7800f2c46c0e692613f497bb146c9ed616ca57b71d53a678af8477fa?" class="img-fluid" />
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ce745d52912ca1cc9a75de694772f29c222d07212b5e117c04536fd9269c927?" class="img-fluid" />
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-auto" v-for="page in footerPages" :key="page.id">
        <span class="item-footer"  @click="goFooterPage(page.id)" style="font-size: 18px; font-weight: 400; cursor: pointer;">{{ page.name }}</span>
      </div>
      <div class="col-12 col-md-auto item-footer">
        <p @click="this.$router.push('/contacts')" style="cursor: pointer;font-size: 18px; font-weight: 400 !important; ">Контакты</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-muted">
        <p style="cursor: pointer;">Ⓒ2023 CHESNOK, “Все по чесноку”, “По чесноку говоря” Все права защищены</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      footerPages: []
    };
  },
  mounted() {
    this.fetchFooterPage();
  },
  methods: {
    fetchFooterPage() {
      axios.get('https://backend.chesnok.kz/api/pages')
        .then(response => {
          this.footerPages = response.data.pages;
        });
    },
    goFooterPage(id) {
      document.location.href=`/page/${id}`;
      // this.$router.push(`/page/${id}`);
    }
  }
};
</script>

<style scoped>
html{
  font-family: Montserrat, sans-serif !important;

}
body{
  font-family: Montserrat, sans-serif !important;
}
.footer-title{
  font-size: 50.93px !important; font-weight: 400; 
}
.text-uppercase{
  font-family: Montserrat, sans-serif !important;

}
.item-footer {
  margin-right: 40px;
}
.font-weight-bold {
  font-weight: 800 !important;
}
.h1 {
  font-size: 3rem !important;
  line-height: 1.17 !important;
}
.img-fluid {
  width: 35px;
  height: auto;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.text-muted {
  color: rgba(0, 0, 0, 0.5) !important;
}
.icon-container img {
  margin-right: 10px;
}
.icon-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

@media (max-width: 768px) {
.footer-title{
  font-size: 2rem !important;
  margin-bottom: 4%;
}
}
</style>
