<template>
    <div class="scrollmenu-wrapper">
      <button class="scroll-btn left-btn p-1" @click="scrollLeft"><i style="color:rgb(61, 210, 204);"  class="m-1 bi bi-chevron-left" /></button>
      <div class="scrollmenu p-2">
        <div v-for="category in categories" :key="category.id" class="menu-item" style="cursor: pointer;" @click="$router.push('/categories/'+category.id)">
          {{ category.name }}
        </div>
      </div>
      <button class="scroll-btn right-btn p-3" @click="scrollRight"><i style="color:rgb(61, 210, 204);"  class="m-2 bi bi-chevron-right" /></button>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import axios from 'axios'
  
  export default {
    setup() {
      const categories = ref([])
  
      onMounted(async () => {
        try {
          const { data } = await axios.get('https://backend.chesnok.kz/api/categories')
          categories.value = data
        } catch (error) {
          console.error(error)
        }
      })
  
      const scrollLeft = () => {
        const scrollMenu = document.querySelector('.scrollmenu');
        scrollMenu.scrollLeft -= 100;
      };
  
      const scrollRight = () => {
        const scrollMenu = document.querySelector('.scrollmenu');
        scrollMenu.scrollLeft += 100;
      };
  
      return {
        categories,
        scrollLeft,
        scrollRight
      }
    }
  }
  </script>
  
  <style scoped>
  .scrollmenu-wrapper {
    position: relative;
    padding: 0 30px; /* для отступа от кнопок управления */
  }
  
  .scrollmenu {
    scroll-behavior: smooth;
    transition: scroll-left 0.5s ease-in-out; /* Добавляем плавный переход */
    overflow-x: hidden; /* скрываем горизонтальный скролл */
    white-space: nowrap;
    display: flex;
  }
  
  .menu-item {
    transition: margin 0.5s ease-in-out; /* Добавляем плавный переход */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    border: 1px solid #969292;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    min-width: 150px;
    text-align: center;
  }
  
  .scroll-btn {
    transition: transform 0.5s ease-in-out; /* Добавляем плавный переход */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border: none;
    font-size: 2em;
    cursor: pointer;
  }
  
  .left-btn {
    left: 0;
  }
  
  .right-btn {
    right: 0;
  }
  </style>