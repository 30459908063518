<template>
  <div
    class="container-fluid mb-5 cabartistbloc"
    style="background-color: aliceblue"
  >
    <div class="container">
      <div class="row">
        <Cabartistbar></Cabartistbar>
      </div>
    </div>
  </div>
  <div
    class="container-fluid editusermobilemanager"
    style="width: 83%; margin-left: 8.5%"
  >
    <div class="row">
      <div class="col-12 col-md-2 pt-3">
        <img :src="imageUrl" class="profile-image" /> <br />
        <div class="file-input mt-2" style="width: 100%; margin: auto">
          <!-- Установите ширину равной ширине изображения и выровняйте по центру -->
          <label
            for="myFile"
            class="button"
            data-bs-toggle="modal"
            data-bs-target="#cropImageModal"
            style="width: 100%"
          >
            <p class="btn btn-info changeavatarp" style="">
              {{ $t("CHANGE_AVATAR") }}
            </p>
            <!-- Установите ширину кнопки на 100% -->
          </label>
        </div>
      </div>

      <div class="col-12 col-md-5 py-2">
        <div class="mb-3">
          <h5><span class="circle"></span>{{ username }}</h5>
        </div>

        <div class="mb-3">
          <label class="form-label">{{ $t("USER_NAME") }}:</label>
          <input
            type="text"
            class="form-control"
            style="
              border-radius: 0%;
              border: 1px solid rgb(107, 107, 107) !important;
              border: #979797 1px solid;
            "
            v-model="username"
          />
        </div>

        <div class="mb-3">
          <label class="form-label">{{ $t("USER_EMAIL") }}:</label>
          <input
            type="text"
            class="form-control"
            style="
              border-radius: 0%;
              border: 1px solid rgb(107, 107, 107) !important;
              border: #979797 1px solid;
            "
            v-model="email"
          />
        </div>

        <div class="mb-3">
          <label class="form-label">{{ $t("PHONE") }}:</label>
          <input
            type="text"
            class="form-control"
            style="
              border-radius: 0%;
              border: 1px solid rgb(107, 107, 107) !important;
              border: #979797 1px solid;
            "
            v-model="phone"
            @input="handlePhoneInput"
          />
        </div>

        <div class="mb-3">
          <button
            class="btn btn-primary savebtnprofile"
            style="
              width: 100%;
              border-radius: 0px;
              background-color: #7fccf4 !important;
              border: none !important;
              font-size: 1.21vw;
            "
            @click="submitForm"
          >
            {{ $t("SAVE") }}
          </button>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label class="form-label">{{ $t("Язык") }}:</label>
            <select
              class="form-control"
              v-model="selectedLanguage"
              @change="changeLanguage"
            >
              <option value="en">English</option>
              <option value="ru">Русский</option>
              <option value="kz">Қазақ</option>
            </select>
          </div>
        </div>
      </div>
      <img ref="image" style="display: none" />
    </div>

    <div
      class="modal fade"
      id="cropImageModal"
      tabindex="-1"
      aria-labelledby="cropImageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="cropImageModalLabel">
              Обрезать изображение
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <vue-avatar
              :width="400"
              :height="400"
              :rotation="rotationAvatar"
              :scale="scaleAvatar"
              ref="vueavatarAvatar"
              @vue-avatar-editor:image-ready="onImageReadyAvatar"
            >
            </vue-avatar>
            <br />
            <div>
              <div class="row">
                <div class="col-2">Увеличение</div>
                <div class="col-10">
                  <input
                    type="range"
                    min="1"
                    max="3"
                    step="0.02"
                    v-model="scaleAvatar"
                  />
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-2">Вращение</div>
                <div class="col-10">
                  <input
                    type="range"
                    min="0"
                    max="360"
                    step="1"
                    v-model="rotationAvatar"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Закрыть
            </button>
            <button
              @click="saveClicked"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Обрезать и отправить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { VueAvatar } from "vue-avatar-editor-improved";
import Cabartistbar from "@/components/Cabartistbar.vue";

export default {
  components: {
    VueAvatar,
    Cabartistbar,
  },
  data() {
    return {
      selectedLanguage: this.$i18n.locale,
      imageUrl: "https://via.placeholder.com/150",
      selectedFile: null,
      username: "",
      email: "",
      phone: "",
      selectedGenres: [],
      genres: [],
      rotationAvatar: 0,
      scaleAvatar: 1,
    };
  },
  methods: {
    changeLanguage() {
      localStorage.setItem("selectedLanguage", this.selectedLanguage);
      this.$i18n.locale = this.selectedLanguage;
    },
    getUserInfo() {
      let token = localStorage.getItem("token");

      axios
        .post(
          "https://backend.chesnok.kz/api/me",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.username = response.data.name;
          this.email = response.data.email;
          this.phone = response.data.phone;

          localStorage.setItem("isArtist", response.data.is_artist);
          localStorage.setItem("username", response.data.name);
          localStorage.setItem("avatar", response.data.avatar);
          this.imageUrl =
            "https://backend.chesnok.kz/storage/" +
            response.data.avatar;
        })
        .catch((error) => {});
    },
    getUserInfoAvatar() {
      let token = localStorage.getItem("token");

      axios
        .post(
          "https://backend.chesnok.kz/api/me",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          localStorage.setItem("isArtist", response.data.is_artist);
          localStorage.setItem("username", response.data.name);
          localStorage.setItem("avatar", response.data.avatar);
          this.imageUrl =
            "https://backend.chesnok.kz/storage/" +
            response.data.avatar;
        })
        .catch((error) => {});
    },

    saveClicked() {
      console.log("saveClicked - был запущен");

      const token = localStorage.getItem("token");

      var canvas = this.$refs.vueavatarAvatar.getImageScaled();
      var img = canvas.toDataURL();
      this.$refs.image.src = img;

      // Convert dataURL to Blob
      fetch(img)
        .then((res) => res.blob())
        .then((blob) => {
          const formData = new FormData();
          formData.append("image", blob, "filename.png");
          formData.append("username", this.username);
          formData.append("email", this.email);
          formData.append("phone", this.phone);

          axios
            .post(
              "https://backend.chesnok.kz/api/edituser",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              this.getUserInfoAvatar();
            });
        });
    },
    fetchGenres() {
      axios
        .post("https://backend.chesnok.kz/api/genres")
        .then((response) => {
          this.genres = response.data;
        });
    },
    handlePhoneInput(event) {
      let value = event.target.value;
      if (value.length === 1 && value !== "+") {
        this.phone = "+7(" + value;
      }
    },
    handleFileUploads() {
      console.log("выполняется");
      this.files = this.$refs.files.files;
      console.log("file " + this.files);
    },
    async submitForm() {
      let token = localStorage.getItem("token");

      if (!this.files) {
        console.log("Изображение не выбрано");
        const formData = new FormData();
        formData.append("username", this.username);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("token", token);
        try {
          const response = await axios.post(
            "https://backend.chesnok.kz/api/edituser",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          // document.location.reload();
          localStorage.setItem("username", this.username);

          this.getUserInfo();
          document.location.reload();
        } catch (error) {}
      } else {
        const formData = new FormData();
        formData.append("image", this.files[0]);
        formData.append("username", this.username);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        formData.append("token", token);
        try {
          const response = await axios.post(
            "https://backend.chesnok.kz/api/edituser",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          // document.location.reload();
          localStorage.setItem("username", this.username);

          this.getUserInfo();
          document.location.reload();
        } catch (error) {}
      }
    },
  },
  created() {
    if (!localStorage.getItem("isLogged")) {
      document.location.href = "/";
      exit;
    } 
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
      this.$i18n.locale = savedLanguage;
    }
    this.getUserInfo();
    this.fetchGenres();
  },
};
</script>

<style scoped>
.changeavatarp {
  width: 100%;
  margin: 0;
  border-radius: 0px;
  background-color: #7fccf4 !important;
  border: none !important;
  font-size: 1.21vw;
}

.circle {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: #7fccf4;
  border-radius: 50%;
  margin-right: 0.5em;
  vertical-align: middle;
  /* Centers the circle vertically in line with the text */
}

.profile-image {
  max-width: 100%;
  height: auto;
}

.file-input {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file-input input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

@media (max-width: 980px) {
  .profile-image {
    max-width: 50%;
    margin-left: 25%;
    height: auto;
  }

  .file-input {
    width: 50% !important;
    margin-left: 25% !important;
  }

  .changeavatarp {
    width: 100%;
    margin: 0;
    border-radius: 0px;
    background-color: #7fccf4 !important;
    border: none !important;
    font-size: 4vw;
  }

  .form-label {
    font-size: 8pt;
  }

  .form-control {
    border: 2px solid rgb(182, 181, 181) !important;
  }

  .savebtnprofile {
    font-size: 4vw !important;
  }

  .cabartistbloc {
    background: #f2f2f2 !important;
  }

  .editusermobilemanager {
    width: 92% !important;
    margin-left: 4% !important;
  }
}
</style>