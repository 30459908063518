<template>
  <div class="container p-5">
    <div class="div">
      <div class="div-2">О компании чеснок</div>
      <div style="font: 400 18px/32px Montserrat, sans-serif;">
        <span style="font-weight: 700">CHESNOK.KZ</span> <br> Компания CHESNOK.KZ –
        один из крупнейших в Казахстане и регионе поставщиков услуг по обеспечению
        мероприятий системами автономного энергоснабжения.<br /><span style="font-weight: 600">Наши клиенты</span>
        – это кинотеатры, концертные залы, спортивные сооружения, торговые центры,
        медицинские учреждения, заводы, а так же большинство event-компаний .<br /> <br />Сотрудники 
        компании CHESNOK.KZ – профессионалы высочайшего класса, специалисты с
        многолетним опытом работы, прошедшие специальное обучение и имеющие
        аккредитацию и допуски государственного образца для работы на
        электроустановках. Отличительной чертой CHESNOK.KZ является комплексность 
        предоставляемых услуг. Цель нашей компании – это предоставление Клиенту
        полного цикла услуг по электроснабжению мероприятия, а так же обеспечение
        уровня сервиса, соответствующего международным стандартам. Наши
        высококвалифицированные специалисты выполнят комплекс работ любой
        сложности для обеспечения бесперебойного электропитания: проведут
        необходимую консультацию, произведут расчет мощности и подбор
        оборудования, осуществят поставку, установку, пусконаладочные работы на
        объекте, а так же круглосуточное дежурство.<br />
      </div>
    </div>
  </div>
</template>



<style scoped>
.div {
  display: flex;
  flex-direction: column;
  color: #000;
}

.div-2 {
  color: #cd0b0b;
  width: 100%;
  font: 600 30px Montserrat, sans-serif;
}

@media (max-width: 991px) {
  .div-2 {
    max-width: 100%;
  }
}

.div-3 {
  margin-top: 20px;
  width: 100%;
  font: 400 18px/32px Montserrat, sans-serif;
}

@media (max-width: 991px) {
  .div-3 {
    max-width: 100%;
  }
}

.div-4 {
  margin-top: 20px;
  width: 100%;
  font: 500 20px/160% Montserrat, sans-serif;
}

@media (max-width: 991px) {
  .div-4 {
    max-width: 100%;
  }
}</style>