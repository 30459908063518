<template>
  <div class="col-12 mt-5">
    <img src="../assets/firstimage.png" style="width: 100%" class="" />
  </div>
  <div class="d-flex justify-left  p-4">
    <div class="row">
      <div class="col-12 p-0" style="text-align:center">
      </div>

      <div style="text-align: left;">
        <h5 class="card-title mb-3 mt-3" style="font-size: 16pt"><b style="color: gray">Вы новый пользователь?</b></h5>
      </div>

      <form @submit.prevent="register">
        <div class="mb-3">
          <label for="name" class="form-label" style="font-size: 10pt">Имя</label>
          <input style="border-radius: 0px; border: 1px solid gray; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.6);"
            id="name" v-model="user.name" type="text" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label" style="font-size: 10pt">Email</label>
          <input style="border-radius: 0px; border: 1px solid gray; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.6);"
            id="email" v-model="user.email" type="email" class="form-control" />
        </div>
        <div class="mb-3 form-check">
          <input style="border-radius: 0px; border: 1px solid gray; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.6);"
            v-model="user.isArtist" type="checkbox" class="form-check-input" id="artist" />
          <label for="artist" class="form-check-label" style="font-size: 10pt">Я артист</label>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label" style="font-size: 10pt">Пароль</label>
          <input style="border-radius: 0px; border: 1px solid gray; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.6);"
            id="password" v-model="user.password" type="password" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="passwordConfirmation" class="form-label" style="font-size: 10pt">Подтверждение пароля</label>
          <input style="border-radius: 0px; border: 1px solid gray; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.6);"
            id="passwordConfirmation" v-model="user.password_confirmation" type="password" class="form-control" />
        </div>
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-primary w-100 p-2"
            style="background-color: #7fccf4 !important; border: none !important; border-radius: 0px; font-size: 15pt"><b>Создать
              аккаунт</b></button>

        </div>

        <div class="mb-3 mt-3" style="display: flex; align-items: center; justify-content: center; text-align: center; font-size: 10pt;" >
          <p>Нажимая кнопку входа, вы принимаете условия <span style="text-decoration: underline;">Пользовательского
              соглашения</span> и <span style="text-decoration: underline;">Политики конфиденциальности</span></p>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { parsePhoneNumber } from 'libphonenumber-js';

export default {
  setup() {
    const credentials = ref({
      email: '',
      password: '',
    });
    const user = ref({
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      isArtist: false,
    });
    const showTerms = ref(false);

    const register = async () => {
      if (user.value.password != user.value.password_confirmation) {
        alert('Пароли должны совпадать');
        return;
      }

      axios.post('https://backend.chesnok.kz/api/register', {
        name: user.value.name,
        email: user.value.email,
        password: user.value.password,
        isartist: user.value.isArtist
      })
        .then(response => {
          localStorage.setItem('token', response.data.token)
          // this.$router.push('/')
          alert('Регистрация прошла успешна, вы можете войти на сайт');
          window.location.href = '/login';

        })
        .catch(error => {
          console.log(error)
        })
    }

    const login = async () => {
      const isEmail = validateEmail(credentials.value.email);
      if (isEmail) {
        await loginWithEmail();
      } else {
        await loginWithPhoneNumber();
      }
    };

    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const loginWithEmail = async () => {
      try {
        const response = await axios.post('https://backend.chesnok.kz/api/login', {
          email: credentials.value.email,
          password: credentials.value.password,
        });
        handleLoginResponse(response);
      } catch (error) {
        console.error(error);
        alert('Данные не найдены');
      }
    };

    const loginWithPhoneNumber = async () => {
      try {
        const phoneNumber = parsePhoneNumber(credentials.value.email, 'KZ');
        if (!phoneNumber.isValid()) {
          console.error('Invalid phone number');
          return;
        }
        const formattedPhoneNumber = phoneNumber.number;
        const response = await axios.post('https://backend.chesnok.kz/api/login', {
          email: formattedPhoneNumber,
          password: credentials.value.password,
        });
        handleLoginResponse(response);
      } catch (error) {
        console.error('Invalid input. Please enter a valid phone number or email address');
      }
    };


    const handleLoginResponse = (response) => {
      localStorage.setItem('isArtist', response.data.user.is_artist);
      localStorage.setItem('isLogged', true);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('username', response.data.user.name);
      localStorage.setItem('avatar', response.data.user.avatar);

      if (response.data.user.is_artist) {
        window.location.href = '/cabinet/profileslist';
      } else {
        window.location.href = '/';
      }
    };

    return { credentials, showTerms, login, register,user };
  },
};
</script>