<template>
  <div class="calendar-widget">
    <div class="text-h6 calendar-header" style="border-bottom: 1px solid lightgray !important; padding-bottom: 10px;">
      <div
        class="calendar-controls d-flex justify-content-between align-items-center"
      >
        <a icon="keyboard_arrow_left" flat @click="previousMonth">
            <img src="../assets/chevron-left.png"  alt="" >

          <!-- <i class="m-2 bi bi-arrow-left"></i> -->
        </a>
        <span class="calendar-month">{{ currentMonth }} {{ currentYear }}</span>
        <a icon="keyboard_arrow_right" flat @click="nextMonth">
            <img src="../assets/chevron-right.png"  alt="" >

          <!-- <i class="m-2 bi bi-arrow-right"></i> -->
        </a>
      </div>
    </div>

    <div class="calendar-grid">
      <div v-for="day in days" :key="day" class="calendar-day">{{ day }}</div>
      <div
        v-for="date in dates"
        :key="date.id"
        :class="{
          'calendar-date': true,
          'is-today': date.isToday,
          'is-selected': selectedDate && selectedDate.id === date.id,
          'has-event': hasEvent(date),
          'is-unselectable': !date.isSelectable,
        }"
        @click="selectDate(date)"
      >
        {{ date.value }}
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      days: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
      currentMonth: "",
      currentYear: "",
      dates: [],
      selectedDate: null,
    };
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.initializeCalendar();
  },
  methods: {
    previousMonth() {
      const currentDate = new Date(
        this.currentYear,
        this.getMonthIndex(this.currentMonth) - 1,
        1
      );
      this.currentMonth = this.getMonthName(currentDate.getMonth());
      this.currentYear = currentDate.getFullYear();
      this.generateDates(currentDate);
    },

    nextMonth() {
      const currentDate = new Date(
        this.currentYear,
        this.getMonthIndex(this.currentMonth) + 1,
        1
      );
      this.currentMonth = this.getMonthName(currentDate.getMonth());
      this.currentYear = currentDate.getFullYear();
      this.generateDates(currentDate);
    },

    getMonthIndex(monthName) {
      const monthNames = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ];
      return monthNames.indexOf(monthName);
    },
    hasEvent(date) {
      const dateString = this.buildDateString(date);
      return this.events.some((event) => event.date === dateString);
    },
    initializeCalendar() {
      const currentDate = new Date();
      this.currentMonth = this.getMonthName(currentDate.getMonth());
      this.currentYear = currentDate.getFullYear();
      this.generateDates(currentDate);
    },
    selectDate(date) {
      if (date.value && date.isSelectable) {
        this.selectedDate = date;
        this.$emit("date-selected", this.buildDateString(date));
      }
    },
    buildDateString(date) {
      const year = this.currentYear;
      const monthNames = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ];
      const monthIndex = monthNames.indexOf(this.currentMonth);
      const day = date.value;
      const selectedDate = new Date(year, monthIndex, day);
      return selectedDate.toISOString().slice(0, 10);
    },
    getMonthName(monthIndex) {
      const monthNames = [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ];
      return monthNames[monthIndex];
    },
    generateDates(date) {
      const monthStartDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
      ).getDay();
      const daysInMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();
      this.dates = [];
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      for (let i = 1; i <= daysInMonth; i++) {
        const currentDate = new Date(date.getFullYear(), date.getMonth(), i);
        this.dates.push({
          id: i,
          value: i,
          isToday:
            i === date.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear(),
          isSelectable: currentDate >= today,
        });
      }
      const emptyDays = (monthStartDay || 7) - 1;
      for (let i = 0; i < emptyDays; i++) {
        this.dates.unshift({ id: `empty-${i}`, value: "", isToday: false });
      }
    },
  },
};
</script>
  
<style scoped>
.calendar-date.has-event {
  background-color: #6d80ec;
  color: #fff;
}
body.dark-theme{

}

.is-unselectable {
  color: gray;
  cursor: not-allowed;
}

.calendar-date.is-selected {
  background-color: #3f51b5;
  color: #fff;
}

.calendar-widget {
  background: #cd0b0b14 !important;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  padding: 14px;
}
body.dark-theme .calendar-widget{
    background: #575757 !important;
}
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 8px;
}

.calendar-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-day,
.calendar-date {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  font-size: 1vw;
}

.calendar-month {
  font-size: 1vw;
}

@media (max-width: 700px) {
  .calendar-month {
    font-size: 3vw;
  }

  .calendar-day,
  .calendar-date {
    font-size: 2vw !important;
  }
}

.calendar-day {
  font-weight: 600;
  color: #444;
}

.calendar-date {
  /* background-color: #fff; */
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}

.calendar-date.is-today {
  background-color: rgb(255, 3, 3);
  border-radius: 46px;
  color: rgb(179, 179, 179);
}

.calendar-date:hover {
  /* background-color: rgba(70, 14, 14, 0.05); */
  cursor: pointer;
}

.calendar-date:active {
  background-color: rgba(255, 4, 4, 0.15);
}

@media (max-width: 600px) {
  .calendar-widget {
    max-width: 100%;
  }

  .calendar-grid {
    grid-gap: 4px;
  }

  .calendar-day,
  .calendar-date {
    font-size: 1vw;
    height: 32px;
  }
}
</style>