<template>
    <div id="app">
        <div class="container">
            <!-- Button trigger modal -->
            <div class="row">
                <div class="col-3">
                    <img :src="'https://backend.chesnok.kz/storage/' + profile.avatar"
                        style="width: 100%; border-radius: 6px" alt="">
                    <button type="button" class="btn btn-primary mt-3" data-bs-toggle="modal"
                        data-bs-target="#cropImageModal" style="width: 100%;">
                        Изменить аватар
                    </button>
                    <div class="pt-3">
                        <p>
                            Текущий рейтинг - <b>{{ profile.rating }} из 100</b>
                            <span data-bs-toggle="modal" data-bs-target="#ratingExplanationModal"
                                style="background: linear-gradient(to right, rgb(168, 188, 209), rgb(235, 226, 235)); border-radius: 50%; padding: 0.2em;">
                                <i style="color:rgb(12, 14, 13);" class="m-2 bi bi-question"></i>
                            </span>
                        </p>
                    </div>

                </div>
                <div class="col-8" v-if="profile">
                    <input type="text" class="form-control m-3" v-model="profile.name" />
                    <textarea id="description" class="form-control m-3" v-model="profile.description" required></textarea>



                    <div class="form-control m-3">
                        <p>Категории</p>
                        <v-select v-model="selectedCategories" :options="categories" multiple label="name"
                            :reduce="(label) => label.id"></v-select>
                    </div>

                    <div class="form-control m-3">
                        <p>Города</p>
                        <v-select v-model="selectedCities" :options="cities" multiple label="city_name"
                            :reduce="(label) => label.id"></v-select>
                    </div>


                    <div class="form-control m-3">
                        <p>Языки</p>
                        <v-select v-model="selectedLanguages" :options="languages" multiple label="title"
                            :reduce="(label) => label.id"></v-select>
                    </div>

                    <div class="form-control m-3">
                        <p>Выбрать кухню</p>
                        <v-select v-model="selectedGenres" :options="genres" multiple label="name"
                            :reduce="(label) => label.id"></v-select>
                    </div>




                    <div class="row p-3">
                        <div class="col-12">
                            <input class="form-control" filled placeholder="Средний чек" v-model="averagecheck" />
                        </div>
                    </div>



                    <button type="button" class="btn btn-primary m-3" @click="uploadInfo">
                        Обновить информацию
                    </button>
                </div>
            </div>

            <div v-if="images.length > 0">
                <Drag :initialImages="images" :profileid="$route.params.id" @update:images="images = $event"></Drag>
            </div>

            <div v-else>
                <Drag :initialImages="images" :profileid="$route.params.id" @update:images="images = $event"></Drag>
            </div>
            <!-- ГАЛЕРЕЯ -->
            <!-- <div class="row mt-5 galery">
                <div class="col-12" style="text-align: right;">
                    <a class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#cropImageGaleryModal">Добавить
                        изображение</a>
                </div>
                <div class="col-4 p-3 position-relative" v-for="image in profile.images">
                    <img class="itemGalery" :src="'https://backend.chesnok.kz/storage/' + image.path" alt="">
                    <button class="btn btn-danger position-absolute top-0 end-0" @click="deleteImage(image.id)">X</button>
                </div>
            </div> -->

            <!-- ГАЛЕРЕЯ -->
            <div class="row mt-5 galery">
                <div class="col-12" style="text-align: right;">
                    <a class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addvideo">Добавить
                        видео</a>
                </div>
                <div class="col-4 p-3 position-relative" v-for="video in profile.videos">

                    <iframe :src="`https://www.youtube.com/embed/${video.code}`" width="100%" height="200" frameborder="0"
                        allow="autoplay; encrypted-media" allowfullscreen></iframe>

                    <button class="btn btn-danger position-absolute top-0 end-0" @click="deleteVideo(video.id)">X</button>
                </div>
            </div>






















            <img ref="image" style="display: none;">
            <!-- Modal -->
            <div class="modal fade" id="cropImageModal" tabindex="-1" aria-labelledby="cropImageModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="cropImageModalLabel">Обрезать изображение</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <vue-avatar :width="400" :height="400" :rotation="rotationAvatar" :scale="scaleAvatar"
                                ref="vueavatarAvatar" @vue-avatar-editor:image-ready="onImageReadyAvatar">
                            </vue-avatar>
                            <br>
                            <div>
                                <div class="row">
                                    <div class="col-2">Увеличение</div>
                                    <div class="col-10"> <input type="range" min="1" max="3" step="0.02"
                                            v-model="scaleAvatar" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-2">Вращение</div>
                                    <div class="col-10"> <input type="range" min="0" max="360" step="1"
                                            v-model="rotationAvatar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                            <button @click="saveClicked" type="button" class="btn btn-primary">Обрезать и отправить</button>
                        </div>
                    </div>
                </div>
            </div>



            <!-- ОТПРАВКА ИЗОБРАЖЕНИЯ ДЛЯ ГАЛЕРЕИ -->
            <div class="modal fade" id="cropImageGaleryModal" tabindex="-1" aria-labelledby="cropImageModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="cropImageModalLabel">Обрезать изображение</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <vue-avatar :width="600" :height="300" :rotation="rotationGallery" :scale="scaleGallery"
                                ref="vueavatarGallery" @vue-avatar-editor:image-ready="onImageReadyGallery">
                            </vue-avatar>
                            <br>
                            <div>
                                <div class="row">
                                    <div class="col-2">Увеличение</div>
                                    <div class="col-10"> <input type="range" min="1" max="3" step="0.02"
                                            v-model="scaleGallery" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-2">Вращение</div>
                                    <div class="col-10"> <input type="range" min="0" max="360" step="1"
                                            v-model="rotationGallery" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                            <button @click="saveClickedGalery" type="button" class="btn btn-primary">Обрезать и
                                отправить</button>
                        </div>
                    </div>
                </div>
            </div>





            <!-- ОТПРАВКА ИЗОБРАЖЕНИЯ ДЛЯ ВИДЕО -->
            <div class="modal fade" id="addvideo" tabindex="-1" aria-labelledby="cropImageModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="cropImageModalLabel">Добавить видео</h5>
                        </div>
                        <div class="modal-body">
                            <input type="text" class="form-control m-3" placeholder="Url видео в youtube" v-model="code">
                        </div>
                        <div class="modal-footer">
                            <a class="btn btn-primary" data-bs-dismiss="modal">Закрыть</a>
                            <a class="btn btn-primary" @click="submitVideo">Загрузить</a>
                        </div>
                    </div>
                </div>
            </div>
            <br>
        </div>

        <!-- ОТПРАВКА ИЗОБРАЖЕНИЯ ДЛЯ ВИДЕО -->
        <div class="modal fade" id="ratingExplanationModal" tabindex="-1" aria-labelledby="ratingExplanationModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-body">
                        <p><b>Правила начисления балов за анкету</b></p>
                        <p>Аватар: 5 баллов</p>
                        <p>Описание больше 150 символов: 5 баллов</p>
                        <p>Категории, больше одной: 10 баллов</p>
                        <p>Языки, больше одного: 10 баллов</p>
                        <p>Жанры, больше одного: 10 баллов</p>
                        <p>Изображения, больше 4: 30 баллов</p>
                        <p>Видео, больше 3: 30 баллов</p>
                    </div>
                    <div class="modal-footer">
                        <a class="btn btn-primary" data-bs-dismiss="modal">Закрыть</a>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
import { VueAvatar } from 'vue-avatar-editor-improved';
import axios from 'axios';
import Drag from '@/components/Drag.vue'
import vSelect from 'vue-select'

export default {
    name: 'App',
    components: {
        VueAvatar,
        Drag,
        vSelect,
    },
    data() {
        return {
            code: '',
            averagecheck: '',
            showRatingExplanationModal: false,
            rotationAvatar: 0,
            scaleAvatar: 1,
            rotationGallery: 0,
            scaleGallery: 1,
            image: null,
            profile: [],
            images: [],
            selectedCities: [],
            selectedGenres: [],
            selectedCategories: [],
            selectedLanguages: [],

            genres: [],
            categories: [],
            languages: [],
            cities: [],
        };
    },
    created() {
        this.getProfileInfo()
        this.getCities();
        this.getLanguages();
        this.fetchCategories();
        this.fetchGenres();
    },
    methods: {

        toggleCategory(category) {
            const index = this.selectedCategories.indexOf(category.id);
            if (index > -1) {
                this.selectedCategories.splice(index, 1);
            } else {
                this.selectedCategories.push(category.id);
            }
        },
        toggleCity(city) {
            const index = this.selectedCities.indexOf(city.id);
            if (index > -1) {
                this.selectedCities.splice(index, 1);
            } else {
                this.selectedCities.push(city.id);
            }
        },
        toggleLanguage(language) {
            const index = this.selectedLanguages.indexOf(language.id);
            if (index > -1) {
                this.selectedLanguages.splice(index, 1);
            } else {
                this.selectedLanguages.push(language.id);
            }
        },
        toggleGenre(genre) {
            const index = this.selectedGenres.indexOf(genre.id);
            if (index > -1) {
                this.selectedGenres.splice(index, 1);
            } else {
                this.selectedGenres.push(genre.id);
            }
        },
        async fetchCategories() {
            let token = localStorage.getItem('token');
            axios.get('https://backend.chesnok.kz/api/categories', {}, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(response => {
                    this.categories = response.data.filter(category => category.id === 8);
                    if (this.categories.length > 0) {
                        this.selectedCategory = this.categories[0]
                    }
                }).catch(error => { console.log(error) })
        },
        submitVideo() {
            let token = localStorage.getItem('token');

            const url = this.code;
            const params = new URLSearchParams(new URL(url).search);
            const videoCode = params.get('v');
            console.log(videoCode); // I9Y0M5k5E5w
            this.code = videoCode;
            axios.post('https://backend.chesnok.kz/api/uploadVideo', {
                id: this.$route.params.id,
                code: this.code
            }, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(response => {
                    this.showUploaderVideoDialog = false;
                    this.getProfileInfo();
                    this.code = '';
                }).catch(error => {
                })
        },
        async deleteImage(id) {
            let token = localStorage.getItem('token');
            axios.post('https://backend.chesnok.kz/api/deleteimg', { id: id }, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(response => { this.getProfileInfo() }).catch(error => { console.log(error) })
        },
        async deleteVideo(id) {
            let token = localStorage.getItem('token');
            axios.post('https://backend.chesnok.kz/api/deletevideo', { id: id }, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(response => { this.getProfileInfo() }).catch(error => { console.log(error) })
        },
        fetchGenres() {
            axios.post('https://backend.chesnok.kz/api/genres').then(response => {
                this.genres = response.data.filter(genre => genre.category_id === 8);
            })
        },
        uploadInfo() {
            const token = localStorage.getItem('token');

            axios.post('https://backend.chesnok.kz/api/uploadInfo', {
                id: this.$route.params.id,
                name: this.profile.name,
                profile: this.profile,
                description: this.profile.description,
                cities: JSON.stringify(this.selectedCities),
                genres: JSON.stringify(this.selectedGenres),
                categories: JSON.stringify(this.selectedCategories),
                languges: JSON.stringify(this.selectedLanguages),
                average: this.averagecheck,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                if (response.data.status) {
                    this.getProfileInfo();
                } else {
                    console.log('Failed to upload image');
                }
            });
        },
        getProfileInfo() {
            axios
                .post('https://backend.chesnok.kz/api/getProfileInfo', {
                    id: this.$route.params.id,
                })
                .then(response => {
                    this.profile = response.data;
                    this.images = response.data.images;

                    this.selectedCities = response.data.cities.map(city => city.id);

                    this.selectedGenres = response.data.genres.map(genre => genre.id);

                    this.selectedCategories = response.data.categories.map(category => category.id);

                    this.selectedLanguages = response.data.languages.map(language => language.id);
                    this.averagecheck = response.data.average_check;
                });
        },
        getCities() {
            axios.get('https://backend.chesnok.kz/api/getCities', {
            })
                .then(response => {
                    this.cities = response.data
                }).catch(error => {
                })
        },
        getLanguages() {
            axios.get('https://backend.chesnok.kz/api/getLanguages', {
            })
                .then(response => {
                    this.languages = response.data
                }).catch(error => {
                })
        },





        saveClickedGalery() {
            console.log('saveClickedGalery - был запущен');
            const token = localStorage.getItem('token');

            var canvas = this.$refs.vueavatarGallery.getImageScaled();
            var img = canvas.toDataURL();
            this.$refs.image.src = img;

            // Convert dataURL to Blob
            fetch(img)
                .then((res) => res.blob())
                .then((blob) => {
                    const formData = new FormData();
                    formData.append('image', blob, 'filename.png');
                    formData.append('profile_id', this.$route.params.id);

                    axios.post('https://backend.chesnok.kz/api/uploadImages', formData, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        this.getProfileInfo();
                    });
                });
        },

        saveClicked() {
            console.log('saveClicked - был запущен');

            const token = localStorage.getItem('token');

            var canvas = this.$refs.vueavatarAvatar.getImageScaled();
            var img = canvas.toDataURL();
            this.$refs.image.src = img;

            // Convert dataURL to Blob
            fetch(img)
                .then((res) => res.blob())
                .then((blob) => {
                    const formData = new FormData();
                    formData.append('image', blob, 'filename.png');
                    formData.append('profile_id', this.$route.params.id);

                    axios.post('https://backend.chesnok.kz/api/uploadAvatar', formData, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        this.getProfileInfo();
                    });
                });
        },
        onImageReadyAvatar() {
            this.scaleAvatar = 1;
            this.rotationAvatar = 0;
        },
        onImageReadyGallery() {
            this.scaleGallery = 1;
            this.rotationGallery = 0;
        },
    },
};
</script>
<style scoped>
.galery {
    background-color: antiquewhite;
    padding: 3%
}

.itemGalery {
    width: 100%
}

@import "~bootstrap/dist/css/bootstrap.css";
</style>