<template>
  <div class="image-upload" style="background-color: rgba(153, 156, 156, 0.055);">
    <div class="image-upload__dropzone" @dragover.prevent @drop="dropToNew">
      <div class="row">
        <div class="dropzone-inner">
          <input type="file" multiple @change="addImages" ref="fileInput" style="display: none">
          <button @click="clickFileInput">  {{ $t('ADD_IMAGES') }} </button>
        </div>
      </div>
    </div>
    <div class="image-upload__preview"  v-for="(image, index) in images" :key="image.id">
      <div class="img-wrapper">
        <img :src="image.path" alt="" @dragstart="dragStart(index)" @dragover.prevent @drop="drop(index)">
        <button class="remove-button" @click="removeImage(index)">×</button> <!-- Here's your remove button -->
      </div>
    </div>
    <div v-for="(image, index) in images" :key="image.id">
    </div>
    <div>
      <a class="btn btn-info w-100" @click="submitImages">СОХРАНИТЬ ИЗМЕНЕНИЯ В ГАЛЕРЕЕ </a>
    </div>
  </div>





  <!-- Modal -->
  <div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmModalLabel">Подтверждение</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Вы уверены, что хотите удалить это изображение?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="removeImage">Удалить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
export default {
  props: {

    profileid: {
      type: String,
      default: () => 0
    },
    initialImages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      images: [],
      draggingIndex: null,
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('dragover', this.preventDragover);
    document.body.removeEventListener('drop', this.preventDragover);
  },
  mounted() {
    console.log('kkuka')
    if (this.initialImages) {
      this.images = this.initialImages.map((image, index) => ({
        ...image,
        order: image.order || index, // если у изображения нет order, используем индекс
        path: `https://backend.chesnok.kz/storage/${image.path}`,
        typeImage: 'old', // устанавливаем typeImage как 'old'
      }));
    }
    document.body.addEventListener('dragover', this.preventDragover);
    document.body.addEventListener('drop', this.preventDragover);
  },
  methods: {
    preventDragover(e) {
      e.preventDefault();
    },
    submitImages() {
      console.log('saveClickedGalery - был запущен');
      const token = localStorage.getItem('token');

      const imagesData = this.images.map(image => ({
        id: image.id,
        path: image.path,
        profile_id: this.profileid,
        order: image.order,
        typeImage: this.initialImages.some(initialImage => initialImage.id === image.id) ? 'old' : 'new', // проверяем есть ли изображение в начальных данных
      }));

      axios.post('https://backend.chesnok.kz/api/uploadImages', {
        'images': imagesData,
        'profile_id': this.profileid
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          // this.getProfileInfo();
          alert('Изображение загружено успешно')
        }).catch(e=>{
          alert('Изображение имеет неверный формат, или слишком большой размер')
        });
    },
    addImages(e) {
      const files = Array.from(e.target.files);
      for (let file of files) {
        if (!file.type.startsWith('image/')) continue;

        const reader = new FileReader();
        reader.onload = e => {
          this.images.push({
            id: Date.now(),
            path: e.target.result,
            order: this.images.length + 1000, // используем длину массива как значение order
            typeImage: 'new', // устанавливаем typeImage как 'new'
          });
        }
        reader.readAsDataURL(file);
      }
    },

    removeImage(index) {
      this.images.splice(index, 1);
      this.submitImages();

    },
    dragStart(index) {
      this.draggingIndex = index;
    },
    drop(index) {
      const draggingImg = this.images[this.draggingIndex];
      this.images.splice(this.draggingIndex, 1);
      this.images.splice(index, 0, draggingImg);

      // Исправляем значения order
      this.images.forEach((img, i) => {
        img.order = i;
      });

      this.draggingIndex = null;
    },
    dropToNew(e) {
      const files = e.dataTransfer.files;
      if (files.length) {
        this.addImages({ target: { files } });
      }
    },
    clickFileInput() {
      this.$refs.fileInput.click();
    },
  },
}
</script>

<style scoped>
.image-upload__preview {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 10px;
  margin: 10px;
  vertical-align: top;
  transition: transform 0.2s ease;
  cursor: move;
  position: relative;
  /* added */
}

.remove-button {
  position: absolute;
  /* added */
  top: 10px;
  /* added */
  right: 10px;
  /* added */
  background-color: rgba(255, 255, 255, 0.5);
  /* added */
  border: none;
  /* added */
  font-size: 20px;
  /* added */
  cursor: pointer;
  /* added */
}

.img-wrapper {
  width: 200px;
  height: 150px;
  overflow: hidden;
}

.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-upload__dropzone {
  border: 2px dashed #ccc;
  padding: 50px;
  text-align: center;
  color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone-inner {
  border: 1px solid #ccc;
  padding: 10px;
}
</style>