<template>
  <div class="container">
    <div class="row my-4">
      <div class="col-12">
        <div
          class="btn-container d-flex justify-content-start justify-content-md-center overflow-auto"
        >
          <div class="text-center mx-2">
            <button
              class="btn btn-outline-secondary btn-date"
              @click="setFilter('today')"
            >
              Сегодня
              <br />
              <div>{{ todayDate }}</div>
            </button>
          </div>
          <div class="text-center mx-2">
            <button
              class="btn btn-outline-secondary btn-date"
              @click="setFilter('week')"
            >
              На этой неделе <br />
              <div>{{ weekDate }}</div>
            </button>
          </div>
          <div class="text-center mx-2">
            <button
              class="btn btn-outline-secondary btn-date"
              @click="setFilter('weekend')"
            >
              На выходных <br />
              <div>{{ weekendDate }}</div>
            </button>
          </div>
          <div class="text-center mx-2">
            <button
              class="btn btn-outline-secondary btn-date"
              @click="setFilter('month')"
            >
              На месяц <br />
              <div>{{ monthDate }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div @click="openNews(news.id)"
        class="col-md-6 col-lg-3 mb-4"
        v-for="(news, index) in filteredNews"
        :key="index"
      >
        <div class="card h-100" style="border: none; background: none !important;">
          <img
            :src="'https://backend.chesnok.kz/storage/' + news.main_image"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body p-0" style="background: none !important;">
            <h5 class="p-0 mt-3">{{ news.short_description }}</h5>
          </div>
          <btn class="btn btn-podr-right">Подробнее</btn>
          <btn class="btn btn-podr-left">{{ formatDate(news.created_at)  }}</btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { parseISO, format } from "date-fns";

export default {
  data() {
    return {
      newsList: [
        {
          image: "assets/image1.png",
          title: "Название блока новости 1",
          description: "Краткое описание новости 1",
          date: "2024-07-22",
        },
        {
          image: "assets/image2.png",
          title: "Название блока новости 2",
          description: "Краткое описание новости 2",
          date: "2024-05-22",
        },
        // Добавьте остальные новости здесь
      ],
      filter: "today",
    };
  },
  created() {
    localStorage.setItem("active", null);
    this.getNews();
  },
  computed: {
    filteredNews() {
      const now = new Date();
      return this.newsList.filter((news) => {
        const newsDate = new Date(news.created_at);
        switch (this.filter) {
          case "today":
            return (
              newsDate.getDate() === now.getDate() &&
              newsDate.getMonth() === now.getMonth() &&
              newsDate.getFullYear() === now.getFullYear()
            );
          case "week":
            const startOfWeek = new Date(
              now.setDate(now.getDate() - now.getDay())
            );
            const endOfWeek = new Date(
              now.setDate(now.getDate() - now.getDay() + 7)
            );
            return newsDate >= startOfWeek && newsDate <= endOfWeek;
          case "weekend":
            const saturday = new Date(
              now.setDate(now.getDate() - now.getDay() + 6)
            );
            const sunday = new Date(
              now.setDate(now.getDate() - now.getDay() + 7)
            );
            return newsDate >= saturday && newsDate <= sunday;
          case "month":
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            const endOfMonth = new Date(
              now.getFullYear(),
              now.getMonth() + 1,
              0
            );
            return newsDate >= startOfMonth && newsDate <= endOfMonth;
          default:
            return true;
        }
      });
    },
    todayDate() {
      const now = new Date();
      return `${now.getDate()} ${this.getMonthName(now.getMonth())}`;
    },
    weekDate() {
      const now = new Date();
      const startOfWeek = new Date(
        now.setDate(now.getDate() - now.getDay() + 1)
      );
      const endOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 6));
      return `${startOfWeek.getDate()} - ${endOfWeek.getDate()} ${this.getMonthName(
        now.getMonth()
      )}`;
    },
    weekendDate() {
      const now = new Date();
      const saturday = new Date(now.setDate(now.getDate() - now.getDay() + 6));
      const sunday = new Date(now.setDate(now.getDate() - now.getDay() + 7));
      return `${saturday.getDate()} - ${sunday.getDate()} ${this.getMonthName(
        now.getMonth()
      )}`;
    },
    monthDate() {
      const now = new Date();
      return `1 - ${new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        0
      ).getDate()} ${this.getMonthName(now.getMonth())}`;
    },
  },
  methods: {
    openNews(id) {
      document.location.href = '/news/' + id;
    },
    formatDate(isoDateString) {
      // Преобразуйте ISO строку в объект даты
      const date = parseISO(isoDateString);
      // Отформатируйте дату в нужный формат
      return format(date, "dd.MM.yyyy");
    },
    async getNews() {
      axios
        .get("https://backend.chesnok.kz/api/news")
        .then((response) => {
          this.newsList = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setFilter(filter) {
      this.filter = filter;
    },
    getMonthName(monthIndex) {
      const monthNames = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      return monthNames[monthIndex];
    },
  },
};
</script>

<style scoped>
.btn-podr-right {
  background: rgba(128, 128, 128, 0.303);
  border-radius: 15px;
  width: 30%;
  font-size: 8pt;
  position: absolute;
  right: 5%;
  top: 150px;
  color: white;
}
.btn-podr-left {
  background: rgba(128, 128, 128, 0.303);
  border-radius: 15px;
  width: 30%;
  font-size: 8pt;
  position: absolute;
  left: 5%;
  top: 150px;
  color: white;

}

.btn-date {
  color: black;
  font-weight: 600;
  border: none;
  background: #fcf3f3;
  padding: 10px 20px;
  border-radius: 12px;
}
body.dark-theme .btn-date {
  background: #454545;
  color: white;
}
body.dark-theme .btn-date div {
  background: #454545;
  color: gray !important;
}
.btn-date div {
  color: gray;
  font-weight: 400;
}
.card-img-top {
  border-radius: 15px;
  height: 200px;
  object-fit: cover;
}
.btn-container {
  white-space: nowrap;
}
.btn {
  flex-shrink: 0;
}
@media (max-width: 767.98px) {
  .btn-container {
    overflow-x: auto;
  }
}
</style>