<template>
  <div>
    <div
      class="dropdown"
      style="
        margin-left: 0px !important;
        padding: 0px !important;
        margin-right: 20px;
      "
    >
      <a
        class="gpsbtn"
        style="margin-left: 1%"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          loading="lazy"
          src="../assets/geoblack.svg"
          class="flex-shrink-0 geoblack"
          style="width: 11px; aspect-ratio: 0.65; margin-right: 5px"
        />
        
        <img
          loading="lazy"
          src="../assets/geowhite.svg"
          class="flex-shrink-0 geowhite"
          style="width: 11px; aspect-ratio: 0.65; margin-right: 5px"
        />
        <!-- <img src="../assets/location.png" class="selectcityicon" style="margin-left: 0px;" /> -->
        <span class="city_name" style="font-weight: 100">{{
          selectedCity ? selectedCity.city_name : "Выберите город"
        }}</span>
        <img src="../assets/arrow-right.png" class="arrow-icon" />
      </a>
      <ul
        class="dropdown-menu cityselectdropdown"
        aria-labelledby="dropdownMenuButton"
        style="z-index: 999999 !important"
      >
        <li v-for="city in cities" :key="city.id" @click="selectCity(city)">
          <a
            class="dropdown-item city_name"
            href="#"
            style="font-weight: 100"
            >{{ city.city_name }}</a
          >
        </li>
      </ul>
    </div>

    <!-- Модальное окно -->
    <div v-if="showModal" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div v-if="!cityConfirmed">
            <h3>
              Мы определили ваш город как {{ confirmationCity.city_name }}. Это
              верно?
            </h3>
            <a
              class="btn btn-info m-2"
              @click="
                cityConfirmed = true;
                selectCity(confirmationCity);
                showModal = false;
              "
              >Да</a
            >
            <a class="btn btn-primary m-2" @click="cityConfirmed = true">Нет</a>
          </div>
          <div v-if="cityConfirmed">
            <p>Выберите ваш город:</p>
            <ul>
              <li
                v-for="city in cities"
                :key="city.id"
                @click="
                  selectCity(city);
                  showModal = false;
                "
                style="list-style-type: none"
              >
                <a class="btn btn-info btn-sm m-1">{{ city.city_name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      cities: [],
      selectedCity: JSON.parse(localStorage.getItem("selectedCity")) || null,
      checkCityVhod: localStorage.getItem("checkCityVhod") || false,
      showModal: false,
      confirmationCity: null,
      cityConfirmed: false,
    };
  },
  methods: {
    async getCities() {
      try {
        const response = await axios.get(
          "https://backend.chesnok.kz/api/getCities"
        );
        this.cities = response.data;
        this.addWholeKazakhstan();
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async (position) => {
            const nearestCity = this.getNearestCity(
              position.coords.latitude,
              position.coords.longitude
            );
            console.log("Ближайший город:", nearestCity.city_name);
            if (this.checkCityVhod == false) {
              this.showCityConfirmationModal(nearestCity);
            }
          });
        }
      } catch (error) {
        console.error("Ошибка при получении списка городов:", error);
      }
    },
    showCityConfirmationModal(city) {
      this.confirmationCity = city;
      this.cityConfirmed = false; // Обнуляем при каждом новом вызове модального окна
      this.showModal = true;
    },
    addWholeKazakhstan() {
      const wholeKazakhstan = {
        id: 0,
        city_name: "Весь Казахстан",
        latitude: null,
        longitude: null,
      };
      const hasWholeKazakhstan = this.cities.some((city) => city.id === 0);
      if (!hasWholeKazakhstan) {
        this.cities.unshift(wholeKazakhstan);
      }
    },
    getNearestCity(lat, lon) {
      let nearestCity = null;
      let shortestDistance = Infinity;

      this.cities.forEach((city) => {
        if (city.latitude && city.longitude) {
          const distance = this.calculateDistance(
            lat,
            lon,
            city.latitude,
            city.longitude
          );
          if (distance < shortestDistance) {
            shortestDistance = distance;
            nearestCity = city;
          }
        }
      });
      return nearestCity;
    },
    calculateDistance(lat1, lon1, lat2, lon2) {
      const dx = lat2 - lat1;
      const dy = lon2 - lon1;
      return Math.sqrt(dx * dx + dy * dy);
    },
    selectCity(city) {
      this.selectedCity = city;
      localStorage.setItem("selectedCity", JSON.stringify(city));
      localStorage.setItem("checkCityVhod", true);
      window.location.reload();
    },
    showCityConfirmationModal(city) {
      this.confirmationCity = city;
      this.showModal = true;
    },
  },
  created() {
    this.getCities();
  },
};
</script>

<style scoped>


 .geowhite {
  display: none;
}
 .geoblack {
  display: block;
}
body.dark-theme .geowhite {
  display: block;
}
body.dark-theme .geoblack {
  display: none;
}

body.dark-theme .cityselectdropdown {
  background: #3e3e3e !important;
}

.gpsbtn {
  display: flex; /* Включаем Flexbox */
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  text-decoration: none;
  color: black;
}

.arrow-icon {
  margin-left: 5px;
  height: 1.4vh;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  width: 80%;
  max-width: 500px;
}

.modal-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.city_name,
h3,
p,
.dropdown-item {
  max-width: 100%;
  font: 500 15px Montserrat, sans-serif;
  font-size: 15px !important;
}
body.dark-theme .dropdown-item:hover {
  max-width: 100%;
  font: 500 15px Montserrat, sans-serif;
  font-size: 15px !important;
  background-color: rgba(255, 0, 0, 0.093) !important;
}
</style>
