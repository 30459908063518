<template>
  <div class="col-12 mt-5">
    <img src="../assets/firstimage.png" style="width: 100%" class="" />
  </div>
  <div class="d-flex justify-left  p-4">
    <div class="row">
      <div class="col-12 p-2" style="text-align:left">
        <h5 class="mb-4 mt-0" style="color:gray; font-size: 17pt"><b>Войти</b></h5>
      </div>

      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="emailOrPhone" style="color: rgb(105, 105, 105); margin-bottom: 5px; " class="form-label">Email /
            телефон</label>
          <input id="emailOrPhone" v-model="credentials.email"
            style="border-radius: 0px; border: 1px solid gray; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.6);"
            type="text" class="form-control">
        </div>

        <div class="mb-3">
          <label for="password" style="color: rgb(105, 105, 105); margin-bottom: 5px;" class="form-label">Пароль</label>
          <input id="password" v-model="credentials.password"
            style="border-radius: 0px; border: 1px solid gray; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.6);"
            type="password" class="form-control">
        </div>

        <div class="mb-3" style="display: flex; align-items: center; justify-content: center">
          <div class="form-check" style="margin-right: 10px;">
            <input class="form-check-input" type="checkbox" style="border: 2px solid gray; width: 16px; height: 16px;"
              value="" id="remember">
            <p class="m-0 mt-1" style="margin-left: 10px; margin-right: 20px; color: rgb(74, 74, 74);font-size: 8pt;">
              Запомнить</p>
          </div>
          <p class="m-0" style="color: #7fccf4; font-size: 8pt;"><b>Забыли пароль?</b></p>
        </div>

        <div class="mb-3" style="display: flex; align-items: center; justify-content: center">
          <button type="submit" class="btn btn-primary w-100 p-2"
            style="background-color: #7fccf4 !important; border: none !important; border-radius: 0px; font-size: 13pt"><b>Войти</b></button>
        </div>
        <div style="text-align:center">
          <p v-if="erDataLogin" style="color:red">Данные не найдены.</p>

        </div>
        <div class="mb-3" style="display: flex; align-items: center; justify-content: center; text-align: center;">
          <p style="font-size: 8pt;">Нажимая кнопку входа, вы принимаете условия <span
              style="text-decoration: underline;">Пользовательского
              соглашения</span> и <span style="text-decoration: underline;">Политики конфиденциальности</span></p>
        </div>
      </form>
      <div class="col-12 mt-3 mb-3 d-flex align-items-center">
        <hr style="flex: 1; margin: 0 10px; border-top: 1px solid #7fccf4; opacity: 1;">
        <p style="color: #888; font-size: 12px; margin: 0;">Не зарегистрированы?</p>
        <hr style="flex: 1; margin: 0 10px; border-top: 1px solid #7fccf4; opacity: 1;">

      </div>
      <button onclick="window.location.href='/mRegister'" type="submit" class="btn btn-primary w-100 p-2"
        style="border: 2px solid rgb(196, 194, 194) !important;  background-color: #f5fcfe !important;  border-radius: 0px; font-size: 13pt; color:black; "><b>Зарегистрироваться</b></button>

    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { parsePhoneNumber } from 'libphonenumber-js';

export default {
  setup() {
    const credentials = ref({
      email: '',
      password: '',
    });
    const user = ref({
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      isArtist: false,
    });
    let erDataLogin = ref(false);
    const showTerms = ref(false);

    const register = async () => {
      if (user.value.password != user.value.password_confirmation) {
        alert('Пароли должны совпадать');
        return;
      }

      axios.post('https://backend.chesnok.kz/api/register', {
        name: user.value.name,
        email: user.value.email,
        password: user.value.password,
        isartist: user.value.isArtist
      })
        .then(response => {
          localStorage.setItem('token', response.data.token)
          // this.$router.push('/')
          alert('Регистрация прошла успешна, вы можете войти на сайт');
          window.location.href = '/login';

        })
        .catch(error => {
          console.log(error)
        })
    }

    const login = async () => {
      // Обрезаем пробелы для email и пароля
      credentials.value.email = credentials.value.email.trim();
      credentials.value.password = credentials.value.password.trim();
      erDataLogin.value = false;
      const isEmail = validateEmail(credentials.value.email);
      if (isEmail) {
        await loginWithEmail();
      } else {
        await loginWithPhoneNumber();
      }
    };


    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const loginWithEmail = async () => {
      try {
        const response = await axios.post('https://backend.chesnok.kz/api/login', {
          email: credentials.value.email,
          password: credentials.value.password,
        });
        handleLoginResponse(response);
      } catch (error) {
        console.error(error);
        erDataLogin.value = true;
      }
    };

    const loginWithPhoneNumber = async () => {
      try {
        const phoneNumber = parsePhoneNumber(credentials.value.email, 'KZ');
        if (!phoneNumber.isValid()) {
          console.error('Invalid phone number');
          return;
        }
        const formattedPhoneNumber = phoneNumber.number;
        const response = await axios.post('https://backend.chesnok.kz/api/login', {
          email: formattedPhoneNumber,
          password: credentials.value.password,
        });
        handleLoginResponse(response);
      } catch (error) {
        console.error('Invalid input. Please enter a valid phone number or email address');
      }
    };


    const handleLoginResponse = (response) => {
      localStorage.setItem('isArtist', response.data.user.is_artist);
      localStorage.setItem('isLogged', true);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('username', response.data.user.name);
      localStorage.setItem('avatar', response.data.user.avatar);

      if (response.data.user.is_artist) {
        window.location.href = '/cabinet/profileslist';
      } else {
        window.location.href = '/';
      }
    };

    return { credentials, showTerms, erDataLogin, login, register, user };
  },
};
</script>