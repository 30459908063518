<template>
  <div class="language-switcher">
    <button style="border: none; margin:2px;" v-for="lang in languages" :key="lang.value"
      :class="{'active': lang.value === currentLanguage, 'inactive': lang.value !== currentLanguage}" @click="setLanguage(lang.value)">
      <b>{{ lang.text }}</b>
    </button>
  </div>
</template>


<script>
export default {
  name: 'LanguageSwitcher',
  data() {
    return {
      languages: [
        { text: 'ENG', value: 'en' },
        { text: 'РУС', value: 'ru' },
        { text: 'КАЗ', value: 'kz' },
      ],
      currentLanguage: localStorage.getItem('selectedLanguage') || this.$i18n.locale,
    };
  },
  created() {
    if (!localStorage.getItem('selectedLanguage')) {
      localStorage.setItem('selectedLanguage', this.$i18n.locale);
    } else {
      this.$i18n.locale = localStorage.getItem('selectedLanguage');
    }
  },
  methods: {
    setLanguage(lang) {
      this.currentLanguage = lang;
      this.$i18n.locale = lang;
      localStorage.setItem('selectedLanguage', lang);
      this.$emit('languageChanged', lang);
    }
  }
};
</script>

<style scoped>
.language-switcher button {
  font-size: 10pt;
  background-color: white;
  color: grey; /* Серый цвет для всех кнопок */
}

.language-switcher button.active {
  background-color: #7fccf4; /* Бирюзовый цвет фона для активной кнопки */
  color: white; /* Белый цвет текста для активной кнопки */
}

.language-switcher button.inactive {
  /* Стили для неактивных кнопок, если нужны */
}
</style>