<template>
    <div class="payment-success">
      <h1>Во время совершения платежа была получена ошибка</h1>
      <p>Не волнуйтесь, ваши средства остались нетронутыми, пожалуйста попробуйте пополнить баланс еще раз.</p>
      <button @click="goHome">На главную</button>
    </div>
  </template>

  <script>
  export default {
    name: 'PaymentSuccess',
    methods: {
      goHome() {
        this.$router.push('/');
      },
    },
  };
  </script>

  <style scoped>
  .payment-success {
    text-align: center;
    margin-top: 50px;
  }

  .payment-success h1 {
    color: green;
  }

  .payment-success button {
    margin-top: 20px;
    padding: 10px 20px;
  }
  </style>
