<template>
  <div>
    <!-- Шаг 1: Ввод email -->
    <div v-if="step === 1" class="d-flex justify-content-center desktop-view" style="width: 30%; margin-left: 35%">
      <div class="row">
        <div class="col-12 p-2 mb-2" style="text-align: left">
          <span class="mb-4 mt-0" style="font: 300 20px Montserrat, sans-serif"><b>Восстановление пароля</b></span>
        </div>

        <form @submit.prevent="sendResetCode">
          <div class="mb-3 mt-2">
            <input id="email" v-model="email" type="text" class="form-control" placeholder="Email" />
          </div>

          <div class="mb-4 mt-2">
            <button type="submit" class="btn rdbtn w-100"><b>Отправить код</b></button>
          </div>

          <div class="mb-3" style="display: flex; align-items: center; justify-content: center; text-align: center; font-size: 10pt;">
            <p>Вы получите письмо с кодом для восстановления пароля.</p>
          </div>
        </form>
      </div>
    </div>

    <!-- Шаг 2: Ввод кода -->
    <div v-if="step === 2" class="d-flex justify-content-center desktop-view" style="width: 30%; margin-left: 35%">
      <div class="row">
        <div class="col-12 p-2 mb-2" style="text-align: left">
          <span class="mb-4 mt-0" style="font: 300 20px Montserrat, sans-serif"><b>Введите код восстановления</b></span>
        </div>

        <form @submit.prevent="verifyResetCode">
          <div class="mb-3 mt-2">
            <input id="resetToken" v-model="resetToken" type="text" class="form-control" placeholder="Код восстановления" />
          </div>

          <div class="mb-4 mt-2">
            <button type="submit" class="btn rdbtn w-100"><b>Проверить код</b></button>
          </div>

          <div class="mb-3" style="display: flex; align-items: center; justify-content: center; text-align: center; font-size: 10pt;">
            <p>Введите код, отправленный на ваш email.</p>
          </div>
        </form>
      </div>
    </div>

    <!-- Шаг 3: Ввод нового пароля -->
    <div v-if="step === 3" class="d-flex justify-content-center desktop-view" style="width: 30%; margin-left: 35%">
      <div class="row">
        <div class="col-12 p-2 mb-2" style="text-align: left">
          <span class="mb-4 mt-0" style="font: 300 20px Montserrat, sans-serif"><b>Новый пароль</b></span>
        </div>

        <form @submit.prevent="resetPassword">
          <div class="mb-3 mt-2">
            <input id="newPassword" v-model="newPassword" type="password" class="form-control" placeholder="Новый пароль" />
          </div>
          <div class="mb-3">
            <input id="newPasswordConfirmation" v-model="newPasswordConfirmation" type="password" class="form-control" placeholder="Подтвердите новый пароль" />
          </div>

          <div class="mb-4 mt-2">
            <button type="submit" class="btn rdbtn w-100"><b>Обновить пароль</b></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";

export default {
  setup() {
    const email = ref("");
    const resetToken = ref("");
    const newPassword = ref("");
    const newPasswordConfirmation = ref("");
    const step = ref(1);

    const sendResetCode = async () => {
      try {
        const response = await axios.post("https://backend.chesnok.kz/api/send-reset-code", { email: email.value });
        step.value = 2;
        alert(response.data.message);
      } catch (error) {
        console.error(error);
        alert("Ошибка при отправке кода восстановления.");
      }
    };

    const verifyResetCode = async () => {
      try {
        const response = await axios.post("https://backend.chesnok.kz/api/verify-reset-code", {
          email: email.value,
          token: resetToken.value,
        });

        if (response.data.message === "Код подтвержден") {
          step.value = 3;
        } else {
          alert("Неверный код восстановления.");
        }
      } catch (error) {
        console.error(error);
        alert("Ошибка при проверке кода.");
      }
    };

    const resetPassword = async () => {
      if (newPassword.value !== newPasswordConfirmation.value) {
        alert("Пароли не совпадают.");
        return;
      }

      try {
        const response = await axios.post("https://backend.chesnok.kz/api/password-reset", {
          email: email.value,
          token: resetToken.value,
          password: newPassword.value,
          password_confirmation: newPasswordConfirmation.value,
        });
        alert(response.data.message);
        // Перенаправление пользователя после успешного восстановления пароля
        window.location.href = "/login";
      } catch (error) {
        console.error(error);
        alert("Ошибка при обновлении пароля.");
      }
    };

    return {
      email,
      resetToken,
      newPassword,
      newPasswordConfirmation,
      step,
      sendResetCode,
      verifyResetCode,
      resetPassword,
    };
  },
};
</script>

<style scoped>
#email,
#resetToken,
#newPassword,
#newPasswordConfirmation {
  border-radius: 11.722px;
  border-width: 1px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  padding: 14px 10px;
  font: 400 16px/122% Montserrat, sans-serif;
  border-color: rgba(0, 0, 0, 1);
}

.mobile-view {
  display: none !important;
  /* Показать на мобильных устройствах */
}
.desktop-view {
  display: block !important;
  /* Скрыть на мобильных */
}
@media (max-width: 768px) {
  .mobile-view {
    display: block !important;
    /* Показать на мобильных устройствах */
  }
  .desktop-view {
    display: none !important;
    /* Скрыть на мобильных */
  }
}

.rdbtn {
  background-color: #cd0b0b !important;
  margin-top: 30px;
  width: 100%;
  color: #fff;
  white-space: nowrap;
  padding: 15px 60px;
  font: 200 18px/89% Montserrat, sans-serif !important;
}
</style>
