<template>
  <div class="container">
    <SearchForm @search="performSearch" />
    <SearchResults :results="searchResults" :isNicheNety="isNicheNety" />
  </div>
</template>

<script>
import axios from 'axios'
import SearchForm from '../components/SearchForm.vue'
import SearchResults from '../components/SearchResults.vue'

export default {
  components: {
    SearchForm,
    SearchResults,
  },
  data() {
    return {
      searchResults: [],
      isNicheNety: false,
    }
  },
  methods: {
    async performSearch({ search, selectedCities, selectedCategories }) {
      try {
        const response = await axios.post('https://backend.chesnok.kz/api/searchWithParameters', {
          search,
          selectedCities,
          selectedCategories,
        })
        this.searchResults = response.data
        if (response.data.length == 0) {
          this.isNicheNety = true;
        }else{
          this.isNicheNety = false;

        }

        this.searchResults.forEach((item) => {
          if (item.reviews && Array.isArray(item.reviews)) {
            let totalRate = 0;
            let totalReviews = item.reviews.length;
            item.reviews.forEach((review) => {
              totalRate += review.rate;
            });
            item.starrate = totalReviews > 0 ? Math.ceil(totalRate / totalReviews) : 0;
          } else {
            item.starrate = 0;
          }
        });



        console.log('this.isNicheNety');
        console.log(this.isNicheNety);

      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
<style></style>