<template></template>
<script>
export default {
  mounted() {
    console.log('say hi')
    const id = this.$route.params.id;
    const token = this.$route.params.token;
    const name = this.$route.params.name;
    const avatar = this.$route.params.avatar;
    console.log(id);
    console.log(token);

    localStorage.setItem('isArtist', true);
    localStorage.setItem('isLogged', true);
    localStorage.setItem('token', token);
    localStorage.setItem('username', name);
    localStorage.setItem('avatar', avatar);

    document.location.href='/cabinet/edit/'+id;

  }
}
</script>