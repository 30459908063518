<template>
  <div class="container p-5">
    <div class="row" v-if="artist">
      <div class="col-7">
        <!-- АВАТАР -->
        <img
          :src="'https://backend.chesnok.kz/storage/' + artist.avatar"
          style="border-radius: 10%"
          class="product-image"
          alt="Product image"
        />
      </div>
      <div class="col-5 pt-5" style="text-align: center">
        <div class="star-rating">
          <span style=""> <i style="font-size: 14pt">Рейтинг:</i> </span>
          <span
            v-for="(star, index) in 5"
            :key="index"
            class="star"
            style="font-size: 14pt"
            :class="index < artist.starrate ? 'gold' : 'grey'"
          >
            ★
          </span>
        </div>
        <div>
          <h1 style="font-weight: 900; font-size: 3rem">{{ artist.name }}</h1>
        </div>
        <div>
          <button
            type="submit"
            @click="showBackModal = true"
            class="btn rdbtn mt-3"
            style="margin-bottom: 10px; border: none !important"
          >
            <b>Написать</b>
          </button>
        </div>
        <div>
          <h2 class="btnbookmark mt-5">
            <i
              style="color: #7fccf4; font-size: 15pt"
              class="bi bi-bookmark"
              v-if="loggedIn"
              :class="
                bookmarkedProfiles.includes(artist.id)
                  ? 'bookmark'
                  : 'bookmark_border'
              "
              @click.stop="bookmarkProfile(artist.id)"
            ></i>
            <i
              style="color: #7fccf4; font-size: 15pt"
              class="bi bi-bookmark"
              v-else
              :class="
                bookmarkedProfiles.includes(artist.id)
                  ? 'bookmark'
                  : 'bookmark_border'
              "
              @click.stop="bookmarkProfile(artist.id)"
            ></i>
            <span style="font-weight: 400; text-align: center">
              Добавить в избранное</span
            >
          </h2>
        </div>
      </div>
      <div class="col-12 mt-5">
        <h4 class="mt-4 mb-4" style="font-weight: 700">Описание артиста</h4>
        <div style="" class="p-2 categorybutton">
          <template v-for="(cat, index) in artist.categories">
            <a class="btn-tag-category m-1" :href="'/categories/' + cat.id">
              {{ cat.name }}
            </a>
            <span
              v-if="index < artist.categories.length - 1"
              style="margin: 0 5px; border-left: 1px solid #000; height: 1.5em"
            ></span>
          </template>
        </div>
      </div>

      <div class="col-12 mt-5">
        <div class="descriptionbloc">
          <div v-html="artist.description"></div>
        </div>
      </div>

      <div class="col-12 mt-5">
        <galeryWidget
          v-if="artist.images && artist.images.length"
          :images="artist.images"
          @openImage="handleUpdateValue"
        />
      </div>

      <div class="col-12 mt-5">
        <videoGaleryWidget :artist-info="artist" v-if="!isGaleryOpen" />
      </div>
    </div>
  </div>

  <div class="backmodal" v-if="showBackModal" @click="showBackModal = false">
    <div class="modalContent p-3" @click.stop>
      <div class="row">
        <div class="col-1 offset-11">
          <button
            type="button"
            class="btn-close"
            @click="showBackModal = false"
          ></button>
        </div>
        <div class="col-12 col-lg-2">
          <img
            v-if="artist"
            :src="'https://backend.chesnok.kz/storage/' + artist.avatar"
            style="border-radius: 10%"
            class="product-image"
            alt="Product image"
          />
        </div>
        <div class="col-10">
          <div class="row" v-if="artist">
            <div class="star-rating">
              <span style=""> <i style="font-size: 14pt"></i> </span>
              <span
                v-for="(star, index) in 5"
                :key="index"
                class="star"
                style="font-size: 14pt"
                :class="index < artist.starrate ? 'gold' : 'grey'"
              >
                ★
              </span>
            </div>
          </div>
          <div class="row" v-if="artist">
            <h2 style="font-weight: 700">{{ artist.name }}</h2>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-5 mt-5 mt-lg-0" style="align-items: center">
          <Calendar
            style="margin: 0px auto !important"
            @date-selected="onDateSelected"
            v-if="artist"
            :events="artist.calendar"
          />
        </div>
        <div class="col-12 col-lg-7 mt-5 mt-lg-0">
          <textarea
            id="description"
            v-model="textmessage"
            class="form-control p-3 messageinput"
            style="
              min-height: 30vh;
              border: 1px solid gray;
              border-radius: 20px;
            "
            required
            placeholder="Текст обращения"
          ></textarea>
          <button
            type="button"
            class="btn btn-primary mt-4 p-2"
            style="
              width: 100%;
              background-color: #cd0b0b !important;
              border: none !important;
            "
            @click="sendMessageToArtist()"
          >
            {{ $t("SEND") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import Calendar from "../components/Calendar.vue";
import Review from "../components/Review.vue";
import adversefirst from "@/components/Adversefirst.vue";
import galeryWidget from "@/components/viewPage/GaleryCarouselWidget.vue";
import videoGaleryWidget from "@/components/viewPage/VideoGaleryWidget.vue";

export default {
  data() {
    return {
      showBackModal: false,
      bannersItems: [],
      artist: null,
      datetoquestions: "",
      isBookmarked: false,
      bookmarkedProfiles: [],
      selectedImageIndex: 0,
      textmessage: "",
      city: "",
      isGaleryOpen: false,
    };
  },
  components: {
    Calendar,
    Review,
    adversefirst,
    videoGaleryWidget,
    galeryWidget,
  },
  mounted() {
    this.city = JSON.parse(localStorage.getItem("selectedCity"));
    this.fetchArtist();
    this.fetchBookmarkedProfile();
    this.loggedIn = localStorage.getItem("isLogged");
    this.fetchBannerItems();
    this.sendViewProfile();
  },
  methods: {
    stripHtmlTags(htmlString) {
      return htmlString.replace(/<\/?[^>]+(>|$)/g, "");
    },
    wrapFirstSentenceInH3(htmlString) {
      const strippedString = stripHtmlTags(htmlString);
      const firstSentenceMatch = strippedString.match(/^[^.!?]*[.!?]/);
      if (firstSentenceMatch) {
        const firstSentence = firstSentenceMatch[0];
        const remainingString = strippedString.slice(firstSentence.length);
        return `<h3>${firstSentence}</h3>${remainingString}`;
      }
      return `<h3>${strippedString}</h3>`;
    },
    handleUpdateValue(value) {
      console.log(value); // Здесь вы получите булево значение, отправленное из дочернего компонента
      console.log("следим за галереей");
      if (value == true) {
        this.isGaleryOpen = true;
      } else {
        this.isGaleryOpen = false;
      }
      // Вы можете выполнить дополнительные действия на основе полученного значения
    },
    async sendViewProfile() {
      let ip = null;

      try {
        // Сначала получаем IP-адрес
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        ip = ipResponse.data.ip;

        // Если успешно получили IP, отправляем его на API
        if (ip) {
          const profileResponse = await axios.post(
            "https://backend.chesnok.kz/api/viewProfile",
            {
              ip_address: ip,
              profile_id: this.$route.params.id,
            }
          );

          console.log("Profile response: ", profileResponse.data);
        }
      } catch (error) {
        console.error("Error in getAndSendIP: ", error);
      }
    },

    async getAndSendIP(idd) {
      let ip = null;
      try {
        const existingSlideId = localStorage.getItem(idd);

        if (!existingSlideId) {
          localStorage.setItem(idd, idd);
          axios.post("https://backend.chesnok.kz/api/clickSlide", {
            slide_id: idd,
            city_id: this.city.id,
          });
          console.log("мы отправили бабки в профиль");
        } else {
          console.log("Клик по рекламному блоку уже засчитан");
        }

        // const ipResponse = await axios.get('https://api.ipify.org?format=json');
        // ip = ipResponse.data.ip;
        // if (ip) {
        //     const profileResponse = await axios.post('https://backend.chesnok.kz/api/clickSlide', {
        //         ip_address: ip,
        //         slide_id: idd,
        //         city_id: this.city.id,
        //     });
        // }
      } catch (error) {}
    },
    sendClickInfoBanner(id, idip, url) {
      this.getAndSendIP(idip);
      if (url == null) {
        document.location.href = "/show/" + id;
      } else {
        document.location.href = url;
      }
    },
    getCities() {
      axios
        .get("https://backend.chesnok.kz/api/getCities", {})
        .then((response) => {
          this.cities = response.data;
        })
        .catch((error) => {});
    },
    getLanguages() {
      axios
        .get("https://backend.chesnok.kz/api/getLanguages", {})
        .then((response) => {
          this.languages = response.data;
        })
        .catch((error) => {});
    },
    fetchGenres() {
      axios.post("https://backend.chesnok.kz/api/genres").then((response) => {
        this.genres = response.data;
      });
    },
    selectImage(index) {
      console.log("selectImage");
      this.selectedImageIndex = index;
    },
    async bookmarkProfile() {
      if (this.loggedIn) {
        try {
          const token = localStorage.getItem("token");
          let id = this.$route.params.id;
          const response = await axios.post(
            `https://backend.chesnok.kz/api/profiles/${id}/bookmark`,
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          );
          console.log(response.data.message);
          document.location.reload();
          // Здесь вы можете обновить состояние иконки закладки на основе ответа сервера.
        } catch (error) {
          console.error(error);
        }
      } else {
        this.$router.push("/login");
      }
    },
    async fetchBannerItems() {
      axios
        .get("https://backend.chesnok.kz/api/slideban/" + this.city.id, {})
        .then((response) => {
          this.bannersItems = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchBookmarkedProfile() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://backend.chesnok.kz/api/bookmarked-profile",
          { headers: { Authorization: `Bearer ${token}` } }
        );
        this.bookmarkedProfiles = response.data.map((profile) => profile.id);
        this.bookmarkedProfiles.forEach((element) => {
          console.log(" element id - " + element.id);
          console.log(" this.id id - " + this.id);
          if (element == this.id) {
            this.isBookmarked = true;
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    getLanguages() {
      axios
        .get("https://backend.chesnok.kz/api/getLanguages", {})
        .then((response) => {
          this.languages = response.data;
        })
        .catch((error) => {});
    },
    fetchArtist() {
      const id = this.$route.params.id;
      axios
        .post("https://backend.chesnok.kz/api/getProfileInfo", { id })
        .then((response) => {
          this.artist = response.data;
          this.artist.description = this.stripHtmlTags(this.artist.description);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onDateSelected(date) {
      console.log("Выбранная дата:", date);
      const datee = new Date(date);
      datee.setDate(datee.getDate() + 1);
      const newDateString = datee.toISOString().slice(0, 10);

      this.datetoquestions = newDateString;
      this.isedit = false;
      this.artist.calendar.forEach((element) => {
        if (date == element.date) {
          this.isedit = true;
          this.selectedComponent = element.id;
        }
      });
      this.datetoevent = date;
    },
    sendMessageToArtist() {
      let token = localStorage.getItem("token");
      let id = this.id;
      axios
        .post(
          "https://backend.chesnok.kz/api/sendRequestArtist",
          {
            receiverId: this.$route.params.id,
            textMessage: this.textmessage,
            datetoquestions: this.datetoquestions,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.sendmessage = false;
          alert(
            "Сообщение успешно отправлено, вы будете перенаправлены на страницу сообщений"
          );
          document.location.href = "/cabinet/myrequest";
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.logout();
          } else {
            alert("Вы уже отправляли запрос на эту дату!");
          }
        });
    },
  },
};
</script>
  
<style scoped>
.descriptionbloc{
  font-size: 18pt;
}
i {
  cursor: pointer;
  font-size: 1.5vw;
  /* Изменено на vw */
}
.backmodal {
  height: 100vh;
  width: 100vw;
  background: rgba(128, 128, 128, 0.324);
  position: fixed;
  top: 0px;
  z-index: 999999;
}
body.dark-theme .backmodal {
  background: #58585863 !important;
}

body.dark-theme .messageinput {
  background: #58585863 !important;
}
body.dark-theme .messageinput::placeholder {
  color: #908181ea !important;
}
body.dark-theme .modalContent {
  background-color: #292929 !important;
}
.modalContent {
  overflow-y: scroll;
  background: white;
  border-radius: 15px;
  height: 70%;
  margin-left: 20%;
  margin-top: 10%;
  width: 60% !important;
}
.modalContent::-webkit-scrollbar {
  display: none;
}
.categorybutton {
  background-color: #cd0b0b21;
  display: inline-block;
  border-radius: 10px;
}
body.dark-theme .categorybutton {
  background-color: #ffffff1d;
  display: inline-block;
  border-radius: 10px;
}
.rdbtn {
  background-color: #cd0b0b !important;
  margin-top: 30px;
  color: #fff;
  white-space: nowrap;
  padding: 25px 60px;
  font: 500 22px/89% Montserrat, sans-serif;
  border-radius: 44px;
}

.product-image {
  width: 100%;
  /* Изменено на 100% */
  height: auto;
  /* Изменено с height: 100% на auto для сохранения пропорций */
  aspect-ratio: 1 / 1;
  /* Добавлено для обеспечения квадратного аспекта */
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 1px 1px 1px #00000040;
}

.reviews-count {
  border-top: 2px solid #7fccf4;
  border-bottom: 2px solid #7fccf4;
  padding: 0px !important;
  margin: 0px !important;
  text-align: center;
  font-size: 18pt;
  font-weight: 800;
  color: gray;
}

.video-frame {
  width: 100%;
  height: 100%;
  /* Опционально, для поддержания соотношения сторон */
  border: none;
  /* Убираем рамку, если она есть */
}

.square {
  position: relative;
  width: 100%;
}

.square::before {
  content: "";
  display: block;
  padding-top: 100%;
  /* Аспектное соотношение 1:1 */
}

.square-content {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f8f9fa;
  border: 2px solid #9e9d9d;
}

.square-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Этот стиль обеспечивает сохранение пропорций изображения */
}

.recomended-title {
  background-color: #7fccf4;
  color: white;
  padding: 5px;
  font-weight: 700;
}

.product-info {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 6px;
  bottom: 1%;
  left: 1.5%;
  right: 0;
  text-align: center;
}

.rating-area {
  width: 100%;
  background-color: #7fccf4;
  font-weight: bold;
  font-size: 1rem;
  /* Изменено на rem */
  color: white;
}

.description-block {
  background-color: #f2f2f2;
  font-size: 0.9rem;
  /* Изменено на rem */
  color: gray;
}

.show-area {
  margin: 2% 7%;
}

.description-block {
  padding: 20px;
}

.btn-tag-category {
  padding: 10px;
  color: white;
  /* Изменено на rem */
  text-decoration: none;
  font-weight: 200;
  color: black;
}

.titlewebartist {
  font-weight: 700;
  color: gray;
  font-size: 1.2rem;
  /* Изменено на rem */
}

.btnbookmark {
  font-size: 1.1rem;
  /* Изменено на rem */
  font-weight: 700;
  color: gray;
}

.bookmark {
  color: black !important;
}

.carousel-indicators button {
  width: 60px;
  height: 40px;
  background-size: cover;
  border: 0;
}

.carousel-item {
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}

.carousel-item img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

.profilecard {
  background-color: rgb(226, 233, 240);
  min-height: 30vh;
  border-radius: 4%;
}

.profilecard img {
  width: 50%;
  margin-top: 6%;
  border: 1px solid rgb(96, 96, 221);
}

.videobloc iframe {
  padding: 1%;
}

.modal-content {
  width: 90%;
  position: fixed;
  left: 5%;
  height: auto;
  background-color: rgba(172, 172, 172, 0);
}

.modal-body {
  width: 50% !important;
  background-color: rgba(172, 172, 172, 0);
  height: auto;
}
</style>
  