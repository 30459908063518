<template>
    <div class="col-12 mt-5">
        <img src="../assets/secondimage.png" style="width: 100%" class="mb-5" />
    </div>
    <div class="container-fluid p-0" style="text-align:center">

        <div class="jumbotron " style="padding: 0% 10%">
            <h3 style="color: #243971; font-size: 22pt"><b>Добро пожаловать, менеджеры артистов!</b></h3>
            <p class="lead" style="font-size: 18pt;">Вместе с Chesnok.kz мы сделаем процесс бронирования артистов
                максимально прозрачным и простым.
            </p>
            <hr class="my-4">
            <h3 style="color: #243971; font-size: 22pt"><b>Все по чесноку!</b></h3>
            <p class="lead" style="font-size: 18pt;">Инструкции для менеджеров ниже</p>
            <hr class="my-4">
            <p class="lead" style="font-size: 18pt;">Как это работает</p>
            <hr class="my-4">
            <p class="lead" style="font-size: 18pt;">Связаться с нами.</p>
            <hr class="my-4">
        </div>

    </div>
</template>

<script>
export default {
    name: 'UserPage'
}
</script>

<style scoped></style>
