<template>
  <div class="container-fluid mb-5" style="background-color: aliceblue;">
    <div class="container">
      <div class="row">
        <Cabartistbar></Cabartistbar>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="titleViews">
      <div class="col-9">
        <h5 style="color:gray"><b>{{ $t('PROFILE_VIEWS') }}</b></h5>
      </div>
    </div>
    <div class="row" v-if="profiles.length <= 0">
      <p>Вы пока ничего не добавили</p>
    </div>
    <div class="row" v-else>

      <div class="desktopViews">
        <div v-for="(item, index) in profiles" :key="index">
          <div class="card my-card" style="margin:20px">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="text-overline">{{ item.name }}</div>
                </div>
                <div class="col-12">
                  <div class="text-caption text-muted" v-if="item.description != null">
                    {{ item.description }}
                  </div>
                  <div v-else class="text-caption text-muted">
                    Нет описания
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="card-footer">
              <button class="btn btn-link" @click="resetCount(item.id)">
                {{ $t('INQUIRIES') }}: <b>{{ item.dialog_count }}</b>
              </button>
              <button class="btn btn-link">
                {{ $t('PAGE_VIEWS') }}: <b>{{ item.view_count_reseted }}</b>
                <span v-if="item.last_reset" class="text-muted">
                  , {{ $t('RESET_DATE') }} - {{ item.last_reset }}
                </span>
              </button>
              <button class="btn btn-link text-primary" @click="resetCount(item.id)">
                {{ $t('RESET_COUNTER') }}
              </button>
              <button class="btn btn-link text-muted">
                {{ $t('PAGE_VIEWS_ALL_TIME') }}: <b>{{ item.view_count }}</b>,
                {{ $t('RESET_DATE') }} - {{ formatDate(item.created_at) }}
              </button>
            </div>
          </div>
        </div>
      </div>








      <div class="mobileViews">
        <div v-for="(item, index) in profiles" :key="index" class="mb-3">
          <div class="row">
            <div class="col-4">
              <img :src="'https://backend.chesnok.kz/storage/' + item.avatar" style="width: 100%;" alt="">
            </div>
            <div class="col-8" style="background-color: #f2f2f2;">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="text-overline pt-2">{{ item.name }}</div>
                    <div class="col-6 p-1">
                      <div style="width: 100%; background-color: #7fccf4; text-align: center;">
                        <span style="font-size: 6pt; color: white">{{ $t('PAGE_VIEWS') }}</span>
                        <br>
                        <span style="font-size: 12pt; color: white"><b>{{ item.view_count_reseted }}</b></span>
                      </div>
                    </div>
                    <div class="col-6 p-1">
                      <div style="width: 100%; background-color: #7fccf4; text-align: center;">
                        <span style="font-size: 6pt; color: white">{{ $t('INQUIRIES') }}</span>
                        <br>
                        <span style="font-size: 12pt; color: white"><b>{{ item.dialog_count }}</b></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 pt-2" style="text-align: center;">
              <a class="btn btn-primary" style="border-radius: 0px; font-size: 3vw; width: 100%;"
                @click="resetCount(item.id)">{{ $t('RESET_COUNTER') }}</a>
              <p class="pt-1" style="margin-bottom:1vh; font-size:2.8vw; color: gray;">Дата сброса - {{ $t('RESET_DATE') }} - {{ formatDate(item.created_at) }}
              </p>
              <hr class="m-0">
            </div>
          </div>



        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import Cabartistbar from '@/components/Cabartistbar.vue';

export default {
  name: 'ProfileList',
  setup() {
    if (!localStorage.getItem("isLogged")) {
      document.location.href = "/";
      exit;
    }
    const profiles = ref([]);

    const fetchProfiles = () => {
      let token = localStorage.getItem('token');
      axios
        .post('https://backend.chesnok.kz/api/profiles', {}, {
          headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => {
          profiles.value = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    };

    const resetCount = (id) => {
      let token = localStorage.getItem('token');
      axios
        .post('https://backend.chesnok.kz/api/resetViews', { id }, {
          headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            fetchProfiles();
          }
        })
        .catch(error => {
          console.log(error);
        });
    };

    const formatDate = (date) => {
      return date.substr(0, 10);
    };

    onMounted(() => {
      fetchProfiles();
    });

    return {
      profiles,
      resetCount,
      formatDate
    };

  },
  components: { Cabartistbar }

};
</script>

<style scoped>
.my-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.text-overline {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
}

.text-caption {
  font-size: 0.75rem;
}

.mobileViews {
  display: none;
}

@media (max-width: 980px) {
  .titleViews {
    padding-left: 5vw;
  }

  .mobileViews {
    display: block;
    padding-left: 8vw;
    padding-right: 8vw;

  }

  .desktopViews {
    display: none;
  }
}
</style>
