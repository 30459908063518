<template>
    <div class="row">


        <div class="col-1">
            <h2>Видео</h2>
        </div>
        <div class="col-11 " style="text-align: right;">
            <button @click="scrollLeft" style="background: none !important;" class="scroll-btn left-btn">
                <img src="../../assets/left.png" alt="">
            </button>
            <button @click="scrollRight" style="background: none !important;" class="scroll-btn right-btn">
                <img src="../../assets/right.png" alt="">
            </button>
        </div>
    

    <div class="col-12">
        <div ref="scrollContainer" class="scroll-container">
            <div class="video" v-for="video in artistInfo.videos" :key="video.id">
                <iframe class="video-frame" :src="'https://www.youtube.com/embed/' + video.code" frameborder="0"
                    allowfullscreen>
                </iframe>
            </div>
        </div>
    </div>


</div></template>

<script>
export default {
    name: 'VideoGallery',
    props: {
        artistInfo: {
            type: Object,
            required: true
        },
    },
    methods: {
        scrollLeft() {
            this.$refs.scrollContainer.scrollBy({ left: -this.$refs.scrollContainer.offsetWidth / 2, behavior: 'smooth' });
        },
        scrollRight() {
            this.$refs.scrollContainer.scrollBy({ left: this.$refs.scrollContainer.offsetWidth / 2, behavior: 'smooth' });
        }
    }
}
</script>

<style scooped>
.scroll-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scroll-container::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
}

.video {
    flex: 0 0 82%;
    /* Каждое видео занимает 50% ширины контейнера, чтобы показывать по 2 видео */
    box-sizing: border-box;
    padding: 10px;
}

.video-frame {
    width: 100%;
    height: 300px;
    border-radius: 10%;
    /* Высота может быть адаптирована под ваш дизайн */
}
.scroll-btn {
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

</style>
