<template>
  <div class="container favourite-view-container" style="min-height: 80vh">
    <div class="p-md-3 desktop-view">
      <div class="g-3">
        <div class="row">
          <div class="row p-3 mb-4">
            <span style="font-size: 21pt" class="p-0 m-0">Избранное</span>
          </div>
          <div
            class="row p-3 mb-4 favitems"
            style=""
            v-for="result in favoritePositions"
          >
            <div class="col-3 d-flex" style="align-items: center">
              <img
                class="product-image"
                :src="'https://backend.chesnok.kz/storage/' + result.avatar"
                style="width: 100%; object-fit: cover; text-align: center"
              />
            </div>
            <div class="col-9">
              <div class="row">
                <div class="col-12">
                  <h3
                    style="
                      text-transform: uppercase;
                      font: 700 40px Montserrat, sans-serif;
                    "
                  >
                    {{ result.name }}
                  </h3>
                </div>
                <div class="col-6">
                  <div
                    v-if="!result.hideprice"
                    style="
                      color: rgba(0, 0, 0, 0.8);
                      font-family: Montserrat, sans-serif !important;
                      font-weight: 400;
                      flex-grow: 1;
                      flex-grow: 1;
                    "
                  >
                    <div v-if="result.from == null && result.before == null">
                      <p>
                        <li>
                          <span style="color: gray">Стоимость:</span>
                          <b>Гонорар по запросу</b>
                        </li>
                      </p>
                    </div>
                    <div v-if="result.from != null && result.before == null">
                      <p>
                        <li>
                          <span style="color: gray">Стоимость:</span>
                          <b>Гонорар от {{ result.from }}</b>
                        </li>
                      </p>
                    </div>
                    <div v-if="result.from == null && result.before != null">
                      <p>
                        <li>
                          <span style="color: gray">Стоимость:</span>
                          <b>Гонорар до {{ result.before }}</b>
                        </li>
                      </p>
                    </div>
                    <div v-if="result.from != null && result.before != null">
                      <p>
                        <li>
                          <span style="color: gray">Стоимость:</span>
                          <b
                            >Гонорар ({{ result.from }} -
                            {{ result.before }})</b
                          >
                        </li>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <li
                    style="
                      color: rgba(0, 0, 0, 0.8);
                      font-family: Montserrat, sans-serif !important;
                      font-weight: 400;
                      flex-grow: 1;
                      flex-grow: 1;
                    "
                  >
                    <span style="color: gray">Рейтинг:</span>
                    <span
                      v-for="(star, index) in 5"
                      :key="index"
                      class="star"
                      :class="index < result.starrate ? 'gold' : 'grey'"
                    >
                      ★
                    </span>
                    <span v-if="result.reviews.length > 0"></span>
                  </li>
                </div>
                <div class="col-6 mt-2">
                  <li
                    style="
                      color: rgba(0, 0, 0, 0.8);
                      font-family: Montserrat, sans-serif !important;
                      font-weight: 400;
                      flex-grow: 1;
                      flex-grow: 1;
                    "
                  >
                    <span style="color: gray"
                      >Отзывы: <b>{{ result.reviews.length }}</b></span
                    >
                  </li>
                </div>

                <div class="col-12 mt-5">
                  <div class="row">
                    <div
                      class="col-3 p-3"
                      @click="go(result)"
                      style="cursor: pointer"
                    >
                      <span
                        style="
                          color: #cd0b0b;
                          font-family: Montserrat, sans-serif;
                          flex-grow: 1;
                          flex-basis: auto;
                          font-size: 16pt;
                          font-weight: 600;
                        "
                        >Написать</span
                      >
                    </div>
                    <div
                      class="col-3 p-3"
                      @click="go(result)"
                      style="cursor: pointer"
                    >
                      <span
                        style="
                          color: black;
                          font-family: Montserrat, sans-serif;
                          flex-grow: 1;
                          flex-basis: auto;
                          font-size: 16pt;
                          font-weight: 600;
                        "
                        >Смотреть фото
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cb65ed88738e8cbca137bd201e3b929af0df66ce0b973cba6e517782edcdd0c?"
                          class="img"
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-view">
          <div class="row p-3 mb-4">
            <span style="font-size: 21pt" class="p-0 m-0">Избранное</span>
          </div>
          <div class="row favitems m-1 mb-5" v-for="result in favoritePositions">
            <div class="col-12">
              <div class="row">
                <div class="col-12 pt-4">
                  <h3
                    style="
                      text-transform: uppercase;
                      font: 700 30px Montserrat, sans-serif;
                    "
                  >
                    {{ result.name }}
                  </h3>
                </div>
                <div class="col-12 d-flex mt-2" style="align-items: center">
                  <img
                    class="product-image"
                    :src="'https://backend.chesnok.kz/storage/' + result.avatar"
                    style="width: 100%; object-fit: cover; text-align: center; border-radius: 20px;"
                  />
                </div>
                <div class="col-12 pl-4 pb-0 mb-0 mt-3">
                  <div
                    v-if="!result.hideprice"
                    style="
                      color: rgba(0, 0, 0, 0.8);
                      font-family: Montserrat, sans-serif !important;
                      font-weight: 400;
                    "
                  >
                    <div v-if="result.from == null && result.before == null" >
                      <p class="p-0 m-0">
                        <li>
                          <span class="titlefav">Стоимость: </span>
                          <b class="valuefav"> Гонорар по запросу</b>
                        </li>
                      </p>
                    </div>
                    <div v-if="result.from != null && result.before == null">
                      <p>
                        <li>
                          <span class="titlefav">Стоимость:</span>
                          <b class="valuefav">Гонорар от {{ result.from }}</b>
                        </li>
                      </p>
                    </div>
                    <div v-if="result.from == null && result.before != null">
                      <p>
                        <li>
                          <span class="titlefav">Стоимость:</span>
                          <b class="valuefav">Гонорар до {{ result.before }}</b>
                        </li>
                      </p>
                    </div>
                    <div v-if="result.from != null && result.before != null">
                      <p>
                        <li>
                          <span class="titlefav">Стоимость:</span>
                          <b class="valuefav"
                            >Гонорар ({{ result.from }} -
                            {{ result.before }})</b
                          >
                        </li>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <li
                    style="
                      color: rgba(0, 0, 0, 0.8);
                      font-family: Montserrat, sans-serif !important;
                      font-weight: 400;
                      flex-grow: 1;
                      flex-grow: 1;
                    "
                  >
                    <span class="titlefav">Рейтинг:</span>
                    <span
                      v-for="(star, index) in 5"
                      :key="index"
                      class="star"
                      :class="index < result.starrate ? 'gold' : 'grey'"
                    >
                      ★
                    </span>
                    <span v-if="result.reviews.length > 0"></span>
                  </li>
                </div>
                <div class="col-12 mt-2">
                  <li
                    style="
                      color: rgba(0, 0, 0, 0.8);
                      font-family: Montserrat, sans-serif !important;
                      font-weight: 400;
                      flex-grow: 1;
                      flex-grow: 1;
                    "
                  >
                    <span class="titlefav"
                      >Отзывы: <b class="valuefav">{{ result.reviews.length }}</b></span
                    >
                  </li>
                </div>

                <div class="col-12 mt-5">
                  <div class="row">
                    <div
                      class="col-12 p-3"
                      @click="go(result)"
                      style="cursor: pointer"
                    >
                      <span
                        style="
                          color: #cd0b0b;
                          font-family: Montserrat, sans-serif;
                          flex-grow: 1;
                          flex-basis: auto;
                          font-size: 16pt;
                          font-weight: 400;
                        "
                        >Написать артисту</span
                      >
                    </div>
                    <div
                      class="col-12 p-3"
                      @click="go(result)"
                      style="cursor: pointer"
                    >
                      <span
                        style="
                          color: black;
                          font-family: Montserrat, sans-serif;
                          flex-grow: 1;
                          flex-basis: auto;
                          font-size: 16pt;
                          font-weight: 400;
                        "
                        >Смотреть фото
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cb65ed88738e8cbca137bd201e3b929af0df66ce0b973cba6e517782edcdd0c?"
                          class="img"
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
  setup() {
    if (!localStorage.getItem("isLogged")) {
      document.location.href = "/";
      exit;
    }

    const favoritePositions = ref([]);

    const sendMessage = (id) => {
      fetch("https://test-backend.digital-samurai.kz/sendMessageToArtist", {
        method: "POST",
        body: JSON.stringify({ positionId: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const goArtist = (id) => {
      // this.$router.push('/show/'+id)
      window.document.location.href = "/show/" + id;
      // this.$router.push('/show/'+id);
    };

    const fetchBookmarkedProfiles = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://backend.chesnok.kz/api/bookmarked-profiles",
          { headers: { Authorization: `Bearer ${token}` } }
        );
        favoritePositions.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      fetchBookmarkedProfiles();
    });

    return {
      favoritePositions,
      sendMessage,
      goArtist,
    };
  },
};
</script>

<style scoped>
li{
  color:gray !important;
}
b{
  color: black !important;
}
.favourite-view-container {
  background: #fff;
}
body.dark-theme .favourite-view-container {
  background: #2C2C2C;
}
@media (max-width: 768px) {
  .mobile-view {
    display: block;
    /* Показать на мобильных устройствах */
  }

  .desktop-view {
    display: none;
    /* Скрыть на мобильных */
  }
}

/* Медиа-запрос для десктопов */
@media (min-width: 769px) {
  .desktop-view {
    display: block;
    /* Показать на десктопах */
  }

  .mobile-view {
    display: none;
    /* Скрыть на десктопах */
  }
}

.favitems {
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}
.titlefav{
  color: gray !important;
}
body.dark-theme .titlefav{
  color: rgba(255, 255, 255, 0.829) !important;
}

.valuefav{
  color: black !important;
}
body.dark-theme .valuefav{
  color: rgb(255, 255, 255) !important;
}

body.dark-theme .favitems {
  background: #3e3e3e;
  box-shadow: none;
}
.product-image {
  width: 100%;
  border-radius: 50px;
  /* Изменено на 100% */
  height: auto;
  /* Изменено с height: 100% на auto для сохранения пропорций */
  aspect-ratio: 1 / 1;
  /* Добавлено для обеспечения квадратного аспекта */
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 1px 1px 1px #00000040;
}
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
  padding-left: 10%;
}

.card:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  /* 1:1 Aspect Ratio */
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid gray;
}

.name-overlay {
  position: absolute;
  bottom: 10%;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Полупрозрачный черный фон */
  color: white;
  text-align: center;
  padding: 5px;
}

@media (max-width: 980px) {
  .mobiledescriptionfavourites {
    font-size: 8pt !important;
  }
  .blocdesc {
    margin-left: 4% !important;
  }
}
</style>