<template>
  <div
    class="d-flex justify-content-center desktop-view"
    style="width: 30%; margin-left: 35%"
  >
    <div class="row">
      <div style="text-align: center">
        <h5 class="card-title mb-3 mt-3"><b>Вы новый пользователь?</b></h5>
      </div>

      <form @submit.prevent="register">
        <div class="mb-3">
          <input
            id="name"
            v-model="user.name"
            placeholder="Имя"
            style=""
            type="text"
            class="form-control inputreg"
          />
        </div>
        <div class="mb-3">
          <input
            id="email"
            v-model="user.email"
            placeholder="Email"
            style=""
            type="email"
            class="form-control inputreg"
          />
        </div>
        <div class="mb-3 form-check">
          <input
            v-model="user.isArtist"
            type="checkbox"
            class="form-check-input"
            id="artist"
          />
          <label for="artist" class="form-check-label">Я артист</label>
        </div>
        <div class="mb-3">
          <input
            id="password"
            v-model="user.password"
            placeholder="Пароль"
            style=""
            type="password"
            class="form-control inputreg"
          />
        </div>
        <div class="mb-3">
          <input
            id="passwordConfirmation"
            v-model="user.password_confirmation"
            placeholder="Подтверждение пароля"
            style=""
            type="password"
            class="form-control inputreg"
          />
        </div>
        <div class="mb-4 mt-2">
          <button type="submit" class="btn rdbtn w-100">
            <b>Создать аккаунт</b>
          </button>
        </div>
        <div class="mb-4 mt-2" style="text-align: center">
          <span><b style="color: #cd0b0b">Войти</b></span>
        </div>

        <div
          class="mb-3 mt-3"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          "
        >
          <p>
            Нажимая кнопку входа, вы принимаете условия
            <span style="text-decoration: underline"
              >Пользовательского соглашения</span
            >
            и
            <span style="text-decoration: underline"
              >Политики конфиденциальности</span
            >
          </p>
        </div>
      </form>
    </div>
  </div>

  <div
    class="d-flex justify-content-center mobile-view p-3"
    style="width: 100%"
  >
    <div class="row">
      <div style="text-align: center">
        <h5 class="card-title mb-3 mt-3" style="font-weight: 300;"><b>Вы новый пользователь?</b></h5>
      </div>

      <form @submit.prevent="register">
        <div class="mb-3">
          <input
            id="name"
            v-model="user.name"
            placeholder="Имя"
            style=""
            type="text"
            class="form-control inputreg"
          />
        </div>
        <div class="mb-3">
          <input
            id="email"
            v-model="user.email"
            placeholder="Email"
            style=""
            type="email"
            class="form-control inputreg"
          />
        </div>
        <div
          class="mb-3 form-check d-flex align-items-center justify-content-center"
        >
          <input
            v-model="user.isArtist"
            type="checkbox"
            class="form-check-input " style="margin-right: 10px !important; border-radius: 1px solid red !important;"
            
            id="artist"
          />
          <label for="artist" class="form-check-label"> Я артист</label>
        </div>

        <div class="mb-3">
          <input
            id="password"
            v-model="user.password"
            placeholder="Пароль"
            style=""
            type="password"
            class="form-control inputreg"
          />
        </div>
        <div class="mb-3">
          <input
            id="passwordConfirmation"
            v-model="user.password_confirmation"
            placeholder="Подтверждение пароля"
            style=""
            type="password"
            class="form-control inputreg"
          />
        </div>
        <div class="mb-4 mt-2">
          <button type="submit" style="padding: 15px !important;" class="btn rdbtn w-100">
            <b style="font-weight: 400;">Создать аккаунт</b>
          </button>
        </div>
        <div class="mb-4 mt-2" style="text-align: center; font-weight: 300;">
          <span><b style="color: #cd0b0b" >Войти</b></span>
        </div>

        <div
          class="mb-3 mt-3"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          "
        >
          <p style="font-size: 10pt;">
            Нажимая кнопку входа, вы принимаете условия
            <span style="text-decoration: underline"
              >Пользовательского соглашения</span
            >
            и
            <span style="text-decoration: underline"
              >Политики конфиденциальности</span
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
body.dark-theme input[type="text"]::placeholder {
  color: #999;
}

body.dark-theme input[type="email"]::placeholder {
  color: #999;
}

body.dark-theme input[type="password"]::placeholder {
  color: #999;
}

.mobile-view {
  display: none !important;
  /* Показать на мобильных устройствах */
}
.desktop-view {
  display: block !important;
  /* Скрыть на мобильных */
}
@media (max-width: 768px) {
  .mobile-view {
    display: block !important;
    /* Показать на мобильных устройствах */
  }
  .desktop-view {
    display: none !important;
    /* Скрыть на мобильных */
  }
}

.inputreg {
  border-radius: 11.722px;
  border-width: 1px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  padding: 14px 10px;
  font: 400 16px/122% Montserrat, sans-serif;
  border-color: rgba(0, 0, 0, 1);
}

body.dark-theme .inputreg {
  background: #3e3e3e !important;
  color: rgba(255, 255, 255, 0.499) !important;
  border: 0.5px rgba(255, 255, 255, 0.355) solid;
}

.rdbtn {
  background-color: #cd0b0b !important;
  width: 100%;
  color: #fff;
  white-space: nowrap;
  padding: 25px 0px !important;
  font: 400 20px/84% Montserrat, sans-serif;
}
</style>  
<script>
import { ref } from "vue";
import axios from "axios";
import { parsePhoneNumber } from "libphonenumber-js";

export default {
  setup() {
    const credentials = ref({
      email: "",
      password: "",
    });
    const loginErrors = ref({
      email: "",
      password: "",
    });

    const registrationErrors = ref({
      name: "",
      email: "",
      password: "",
      passwordConfirmation: "",
    });
    const user = ref({
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      isArtist: false,
    });
    const showTerms = ref(false);

    const register = async () => {
      if (user.value.password != user.value.password_confirmation) {
        alert("Пароли должны совпадать");
        return;
      }

      axios
        .post("https://backend.chesnok.kz/api/register", {
          name: user.value.name,
          email: user.value.email,
          password: user.value.password,
          isartist: user.value.isArtist,
        })
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          // this.$router.push('/')
          alert("Регистрация прошла успешна, вы можете войти на сайт");
          window.location.href = "/login";
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const login = async () => {
      const isEmail = validateEmail(credentials.value.email);
      if (isEmail) {
        await loginWithEmail();
      } else {
        await loginWithPhoneNumber();
      }
    };

    const validateEmail = (email) => {
      email = email.trim();
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const loginWithEmail = async () => {
      try {
        const response = await axios.post(
          "https://backend.chesnok.kz/api/login",
          {
            email: credentials.value.email.trim(),
            password: credentials.value.password.trim(),
          }
        );
        handleLoginResponse(response);
      } catch (error) {
        console.error(error);
        alert("Данные не найдены");
      }
    };

    const loginWithPhoneNumber = async () => {
      try {
        const phoneNumber = parsePhoneNumber(credentials.value.email, "KZ");
        if (!phoneNumber.isValid()) {
          console.error("Invalid phone number");
          return;
        }
        const formattedPhoneNumber = phoneNumber.number;
        const response = await axios.post(
          "https://backend.chesnok.kz/api/login",
          {
            email: formattedPhoneNumber.trim(),
            password: credentials.value.password.trim(),
          }
        );
        handleLoginResponse(response);
      } catch (error) {
        console.error(
          "Invalid input. Please enter a valid phone number or email address"
        );
      }
    };

    const handleLoginResponse = (response) => {
      localStorage.setItem("isArtist", response.data.user.is_artist);
      localStorage.setItem("isLogged", true);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("username", response.data.user.name);
      localStorage.setItem("avatar", response.data.user.avatar);

      if (response.data.user.is_artist) {
        window.location.href = "/cabinet/profileslist";
      } else {
        window.location.href = "/";
      }
    };

    return { credentials, showTerms, login, register, user };
  },
};
</script>