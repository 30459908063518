<template>
  <div class="container">
    <div class="row">
      <nav aria-label="breadcrumb" style="margin: 1%">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" style="cursor: pointer" @click="goBack">
            Мои анкеты
          </li>
          <li class="breadcrumb-item" style="cursor: pointer">Календарь</li>
        </ol>
      </nav>
      <div class="col-12">
        <h5>
          Добавление события для "<b>{{ profile.name }}</b
          >"
        </h5>
      </div>
      <div class="col-6">
        <Calendar
          :events="calendaritems"
          @date-selected="onDateSelected"
        ></Calendar>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-12">
            <textarea
              v-model="editor"
              class="form-control"
              placeholder="Описание события"
              rows="5"
            ></textarea>
          </div>
          <div
            class="col-12 text-right"
            style="margin-top: 0.5%"
            v-if="!isedit"
          >
            <button
              class="btn btn-primary"
              v-if="editor != '' && datetoevent != ''"
              @click="sendCalendarEvent()"
            >
              Добавить событие
            </button>
          </div>
          <div class="col-12 text-right" style="margin-top: 0.5%" v-if="isedit">
            <button
              class="btn btn-primary"
              v-if="editor != '' && datetoevent != ''"
              @click="updateCalendarEvent()"
            >
              Изменить событие
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Calendar from "../../components/Calendar.vue"; // путь до компонента Calendar.vue

export default {
  data() {
    return {
      name: "",
      datetoevent: "",
      selectedDate: "", // Добавленное свойство
      editor: "",
      calendaritems: [],
      profile: [],
      isedit: false,
      selectedComponent: null,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    Calendar,
  },
  mounted() {
    console.log(this.$route.params.id); // Выводим значение ID в консоль
    this.getCalendarItems();
  },
  created() {
    if (!localStorage.getItem("isLogged")) {
      document.location.href = "/";
      exit;
    }
  },
  methods: {
    onDateSelected(date) {
      this.selectedDate = date;
      this.editor = ""; // Сброс значения поля "описание события"
    },
    goBack() {
      this.$router.go(-1);
    },
    getCalendarItems() {
      let token = localStorage.getItem("token");
      const id = this.$route.params.id;

      axios
        .post(
          "https://backend.chesnok.kz/api/getcalendar",
          {
            proffessionalid: this.$route.params.id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.calendaritems = response.data.calendars;
          this.profile = response.data.profile;
        })
        .catch((error) => {});
    },

    onDateSelected(date) {
      console.log("Выбранная дата:", date);
      this.isedit = false;
      this.calendaritems.forEach((element) => {
        if (date == element.date) {
          this.editor = element.description;
          this.isedit = true;
          this.selectedComponent = element.id;
        }
      });
      if (this.isedit == false) {
        this.editor = "";
      }
      this.datetoevent = date;
    },
    sendCalendarEvent() {
      let token = localStorage.getItem("token");
      let id = this.$route.params.id;
      axios
        .post(
          "https://backend.chesnok.kz/api/addcalendar",
          {
            proffessionalid: this.$route.params.id,
            date: this.datetoevent,
            description: this.editor,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("all its good");
          document.location.reload();

          this.getCalendarItems();
          //this.items = response.data;
        })
        .catch((error) => {});
    },
    updateCalendarEvent() {
      let token = localStorage.getItem("token");
      let id = this.$route.params.id;
      axios
        .post(
          "https://backend.chesnok.kz/api/updatecalendar",
          {
            elementid: this.selectedComponent,
            proffessionalid: this.$route.params.id,
            date: this.datetoevent,
            description: this.editor,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("all its good");
          document.location.reload();
          this.getCalendarItems();
          //this.items = response.data;
        })
        .catch((error) => {});
    },
  },
  computed: {
    gettextday(date) {
      calendaritems.forEach((element) => {
        console.log(element);
      });
    },
  },
};
</script>
