<template>
  <div class="container">
    <div
      v-if="isNicheNety == true"
      @click="this.$router.push('/createRequest')"
    >
      <p>
        К сожалению, поиск не дал результатов, но вы можете написать
        <button class="btn btn-primary">запрос</button> , и наши менеджеры
        постараются подобрать для вас то, что вам нужно.
      </p>
    </div>
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showModal = false">&times;</span>
        <h2>Создать запрос</h2>
        <form @submit.prevent="handleSubmit">
          <label for="title">Заголовок:</label>
          <input
            id="title"
            v-model="requestTitle"
            type="text"
            class="form-control"
            required
          />

          <label for="category">Категория:</label>
          <select
            id="category"
            class="form-control"
            v-model="requestCategory"
            required
          >
            <!-- Вставьте здесь свои категории -->
            <option :value="category.id" v-for="category in categories">
              {{ category.name }}
            </option>
          </select>

          <label for="city">Город:</label>
          <input
            id="city"
            v-model="requestCity"
            type="text"
            class="form-control"
            required
          />

          <label for="description">Описание:</label>
          <textarea
            id="description"
            class="form-control"
            v-model="requestDescription"
            required
          ></textarea>

          <label for="phone">Телефон:</label>
          <input
            id="phone"
            v-model="requestPhone"
            type="text"
            class="form-control"
            v-mask="'8(###)###-##-##'"
            required
          />

          <label for="estimated_date">Предполагаемая дата события:</label>
          <input
            id="estimated_date"
            v-model="estimatedDate"
            type="date"
            class="form-control"
            required
          />
          <button
            @click="sendSearchRequest"
            class="m-3 btn btn-primary"
            :disabled="
              !requestTitle ||
              !requestCategory ||
              !requestCity ||
              !requestDescription ||
              !requestPhone
            "
          >
            Отправить запрос
          </button>
        </form>
      </div>
    </div>
    <div class="desktopitems">
      <div class="row p-3 mb-4 pagitem" v-for="result in paginatedData">
        <div class="col-3 d-flex justify-content-center align-items-center">
          <img
            class="product-image"
            :src="'https://backend.chesnok.kz/storage/' + result.avatar"
            style="width: 100%; object-fit: cover"
            alt=""
          />
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-12">
              <h3
                style="
                  text-transform: uppercase;
                  font: 700 40px Montserrat, sans-serif;
                "
              >
                {{ result.name }}
              </h3>
            </div>
            <div class="col-6">
              <div
                v-if="!result.hideprice"
                style="
                  color: rgba(0, 0, 0, 0.8);
                  font-family: Montserrat, sans-serif !important;
                  font-weight: 400;
                  flex-grow: 1;
                  flex-grow: 1;
                "
              >
                <div v-if="result.from == null && result.before == null">
                  <p style="font-weight: 14pt">
                    <li class="titlerow">
                      <span style="color: gray; font-weight: 14pt"
                        >Стоимость:</span
                      >
                      Гонорар по запросу
                    </li>
                  </p>
                </div>
                <div v-if="result.from != null && result.before == null">
                  <p style="font-weight: 14pt">
                    <li class="titlerow">
                      <span style="color: gray; font-weight: 14pt"
                        >Стоимость:</span
                      >
                      Гонорар от {{ result.from }}
                    </li>
                  </p>
                </div>
                <div v-if="result.from == null && result.before != null">
                  <p style="font-weight: 14pt">
                    <li class="titlerow">
                      <span style="color: gray; font-weight: 14pt"
                        >Стоимость:</span
                      >
                      Гонорар до {{ result.before }}
                    </li>
                  </p>
                </div>
                <div v-if="result.from != null && result.before != null">
                  <p style="font-weight: 14pt">
                    <li class="titlerow">
                      <span style="color: gray; font-weight: 14pt"
                        >Стоимость:</span
                      >
                      Гонорар ({{ result.from }} - {{ result.before }})
                    </li>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <li
                class="titlerow"
                style="
                  color: rgba(0, 0, 0, 0.8);
                  font-family: Montserrat, sans-serif !important;
                  font-weight: 200;
                  flex-grow: 1;
                  flex-grow: 1;
                "
              >
                <span style="color: gray; font-size: 14pt !important"
                  ><i>Рейтинг</i>:</span
                >
                <span
                  v-for="(star, index) in 5"
                  :key="index"
                  class="star"
                  style="font-size: 14pt"
                  :class="index < result.starrate ? 'gold' : 'grey'"
                >
                  ★
                </span>
                <span v-if="result.reviews.length > 0"></span>
              </li>
            </div>
            <div class="col-6 mt-2">
              <li
                class="titlerow"
                style="
                  color: rgba(0, 0, 0, 0.8);
                  font-family: Montserrat, sans-serif !important;
                  font-weight: 400;
                  flex-grow: 1;
                  flex-grow: 1;
                "
              >
                <span style="color: gray; font-weight: 14pt"
                  >Отзывы:
                  <span style="color: black">{{
                    result.reviews.length
                  }}</span></span
                >
              </li>
            </div>

            <div class="col-12 mt-5">
              <div class="row">
                <div
                  class="col-3 p-3"
                  @click="go(result)"
                  style="cursor: pointer"
                >
                  <span
                    style="
                      color: #cd0b0b;
                      font-family: Montserrat, sans-serif;
                      flex-grow: 1;
                      flex-basis: auto;
                      font-size: 16pt;
                      font-weight: 600;
                    "
                    >Написать</span
                  >
                </div>
                <div
                  class="col-3 p-3"
                  @click="go(result)"
                  style="cursor: pointer"
                >
                  <span
                    style="
                      color: black;
                      font-family: Montserrat, sans-serif;
                      flex-grow: 1;
                      flex-basis: auto;
                      font-size: 16pt;
                      font-weight: 600;
                    "
                    >Смотреть фото
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cb65ed88738e8cbca137bd201e3b929af0df66ce0b973cba6e517782edcdd0c?"
                      class="img"
                  /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav
        aria-label="Page navigation example"
        v-if="paginatedData.length > 100"
      >
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 0 }">
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(currentPage - 1)"
              >Назад</a
            >
          </li>
          <li
            class="page-item"
            v-for="page in pageCount"
            :key="page"
            :class="{ active: page === currentPage + 1 }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(page - 1)"
              >{{ page }}</a
            >
          </li>
          <li
            class="page-item"
            :class="{ disabled: currentPage === pageCount - 1 }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(currentPage + 1)"
              >Далее</a
            >
          </li>
        </ul>
      </nav>
    </div>

    <div class="mobileitems">
      <div class="row p-3 mb-4 pagitem" v-for="result in paginatedData">
        <div class="col-12">
          <div class="row">
            <div class="col-12 pb-2 pt-2">
              <h3
                style="
                  text-transform: uppercase;
                  font: 700 20px Montserrat, sans-serif;
                "
              >
                {{ result.name }}
              </h3>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center">
              <img
                class="product-image"
                :src="'https://backend.chesnok.kz/storage/' + result.avatar"
                style="width: 100%; object-fit: cover; border-radius: 15px"
                alt=""
              />
            </div>
            <div class="col-12 pt-2 m-0" >
              <div
                v-if="!result.hideprice"
                style="
                  color: rgba(0, 0, 0, 0.8);
                  font-family: Montserrat, sans-serif !important;
                  font-weight: 400;
                  flex-grow: 1;
                  flex-grow: 1;
                "
              >
                <div v-if="result.from == null && result.before == null" class="pt-2">
                  <p style="font-weight: 14pt">
                    <li class="titlerow" style="color: gray">
                      <span style="color: gray; font-weight: 14pt"
                        >Стоимость:</span
                      >
                      <span style="color: black">Гонорар по запросу</span>
                    </li>
                  </p>
                </div>
                <div v-if="result.from != null && result.before == null">
                  <p style="font-weight: 14pt">
                    <li class="titlerow" style="color:gray">
                      <span style="color: gray; font-weight: 14pt"
                        >Стоимость:</span
                      >
                      <span style="color: black">Гонорар от {{ result.from }}</span>
                    </li>
                  </p>
                </div>
                <div v-if="result.from == null && result.before != null">
                  <p style="font-weight: 14pt">
                    <li class="titlerow">
                      <span style="color: gray; font-weight: 14pt"
                        >Стоимость:</span
                      >
                      Гонорар до {{ result.before }}
                    </li>
                  </p>
                </div>
                <div v-if="result.from != null && result.before != null">
                  <p style="font-weight: 14pt">
                    <li class="titlerow">
                      <span style="color: gray; font-weight: 14pt"
                        >Стоимость:</span
                      >
                      Гонорар ({{ result.from }} - {{ result.before }})
                    </li>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 m-0">
              <li
                class="titlerow"
                style="
                  color: gray;
                  font-family: Montserrat, sans-serif !important;
                  font-weight: 200;
                  flex-grow: 1;
                  flex-grow: 1;
                "
              >
                <span style="color: gray; font-size: 12pt !important"
                  ><i>Рейтинг</i>:</span
                >
                <span
                  v-for="(star, index) in 5"
                  :key="index"
                  class="star"
                  style="font-size: 14pt"
                  :class="index < result.starrate ? 'gold' : 'grey'"
                >
                  ★
                </span>
                <span v-if="result.reviews.length > 0"></span>
              </li>
            </div>
            <div class="col-12 mt-3">
              <li
                class="titlerow"
                style="
                  color: gray;
                  font-family: Montserrat, sans-serif !important;
                  font-weight: 400;
                  flex-grow: 1;
                  flex-grow: 1;
                "
              >
                <span style="color: gray; font-weight: 14pt"
                  >Отзывы:
                  <span style="color: black">{{
                    result.reviews.length
                  }}</span></span
                >
              </li>
            </div>

            <div class="col-12 mt-5">
              <div class="row">
                <div
                  class="col-12 p-3"
                  @click="go(result)"
                  style="cursor: pointer"
                >
                  <span
                    style="
                      color: #cd0b0b;
                      font-family: Montserrat, sans-serif;
                      flex-grow: 1;
                      flex-basis: auto;
                      font-size: 16pt;
                      font-weight: 400;
                    "
                    >Написать</span
                  >
                </div>
                <div
                  class="col-12 p-3"
                  @click="go(result)"
                  style="cursor: pointer"
                >
                  <span
                    style="
                      color: black;
                      font-family: Montserrat, sans-serif;
                      flex-grow: 1;
                      flex-basis: auto;
                      font-size: 16pt;
                      font-weight: 400;
                    "
                    >Смотреть фото
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/5cb65ed88738e8cbca137bd201e3b929af0df66ce0b973cba6e517782edcdd0c?"
                      class="img"
                  /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav
        aria-label="Page navigation example"
        v-if="paginatedData.length > 100"
      >
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 0 }">
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(currentPage - 1)"
              >Назад</a
            >
          </li>
          <li
            class="page-item"
            v-for="page in pageCount"
            :key="page"
            :class="{ active: page === currentPage + 1 }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(page - 1)"
              >{{ page }}</a
            >
          </li>
          <li
            class="page-item"
            :class="{ disabled: currentPage === pageCount - 1 }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="changePage(currentPage + 1)"
              >Далее</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["results", "isNicheNety"],
  data() {
    return {
      cities: [],
      categories: [],
      selectedCities: "",
      selectedCategories: "",
      estimatedDate: "",
      requestPhone: "",
      currentPage: 0,
      perPage: 5,
      showModal: false, // для управления модальным окном
      requestTitle: "", // для хранения вводимого заголовка
      requestCategory: "", // для хранения выбранной категории
      requestCity: "", // для хранения выбранного города
      requestDescription: "", // для хранения вводимого описания
    };
  },
  computed: {
    paginatedData() {
      const start = this.currentPage * this.perPage;
      const end = start + this.perPage;
      return this.results.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.results.length / this.perPage);
    },
  },
  methods: {
    go(profile) {
      try {
        if (profile.type != "1") {
          this.$router.push("/show/" + profile.id);
        } else {
          this.$router.push("/show/food/" + profile.id);
        }
      } catch (error) {
        this.$router.push("/show/" + profile.id);
      }
    },
    changePage(page) {
      if (page >= 0 && page < this.pageCount) {
        this.currentPage = page;
      }
    },
    sendSearchRequest() {
      axios
        .post("https://backend.chesnok.kz/api/createRequestSearch", {
          title: this.requestTitle,
          category: this.requestCategory,
          city: this.requestCity,
          description: this.requestDescription,
          phone: this.requestPhone,
          estimated_date: this.estimatedDate,
        })
        .then((response) => {
          alert("Ваша заявка успешно зарегистрирована");
          this.showModal = false;
          this.requestTitle = "";
          this.requestCategory = "";
          this.requestCity = "";
          this.requestDescription = "";
        });
    },
    async getInfo() {
      try {
        const citiesResponse = await axios.get(
          "https://backend.chesnok.kz/api/getCities"
        );
        this.cities = citiesResponse.data;

        const categoriesResponse = await axios.get(
          "https://backend.chesnok.kz/api/categories"
        );
        this.categories = categoriesResponse.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    console.log("щас бущдет г ет инфо");
    this.getInfo();
  },
};
</script>

<style scoped>
.mobileitems {
  display: none;
}
body.dark-theme .titlerow {
  color: rgba(255, 255, 255, 0.8) !important;
}
body.dark-theme .pagitem {
  padding: 30px !important;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  background: #3e3e3e;
}

.pagitem {
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.star {
  font-size: 1.5em;
  /* измените размер звезд по вашему усмотрению */
  color: grey;
}

.star.gold {
  color: gold;
}

.container {
  min-height: 80vh;
}

.product {
  margin-bottom: 2em;
  padding: 1em;
  border: 1px solid #ccc;
  /* рамка */
  border-radius: 10px;
  /* закругления */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* тени */
  overflow: hidden;
  /* чтобы закругления были видны */
}

.product:hover {
  box-shadow: 0 2px 5px rgba(32, 117, 214, 0.3);
}

.product .image-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Добавлено свойство flex-wrap для переноса изображений на новую строку */
}

.image-containerrrsearch {
  width: 23%;
  /* Уменьшена ширина контейнера для изображений */
  height: 20vh;
  margin: 10px;
  /* Добавлен отступ между изображениями */
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .image-containerrrsearch {
    width: 41%;
    /* Половина ширины минус небольшой отступ */
  }
  .desktopitems {
    display: none;
  }
  .mobileitems {
    display: block;
  }
}

@media (max-width: 300px) {
  .image-containerrrsearch {
    width: 90%;
    /* Половина ширины минус небольшой отступ */
  }
}

.product-image {
  width: 100%;
  border-radius: 50px;
  /* Изменено на 100% */
  height: auto;
  /* Изменено с height: 100% на auto для сохранения пропорций */
  aspect-ratio: 1 / 1;
  /* Добавлено для обеспечения квадратного аспекта */
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 1px 1px 1px #00000040;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>