<template>
  <div style="text-align: center">
    <hr style="border: 4px solid #7fccf4; opacity: 1" />
    <a href="https://play.google.com/store/games?hl=ru&gl=US&pli=1">
      <img src="../assets/apps.jpg" style="width: 96%" alt="" />
    </a>
  </div>

  <div style="text-align: right; margin-right: 3vw; padding-top: 1vh">
    <img
      class="m-2"
      style="width: 15vw !important; margin-left: 3.4% !important"
      src="../assets/icons.png"
    />
  </div>
</template>
<style></style>
