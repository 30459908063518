<template>
  <div class="mt-2" style="padding-left: 3%; padding-right: 3%">

    <div class="row">
      <div class="col-3">
        <img src="../assets/scorsezejpg.jpg" style="width: 20vw; height: 20vw;" class="mb-5" />

      </div>
      <div class="col-9">
        <span style="font-size: 7pt; color: gray;">12.04.2023</span> <br>
        <p class="m-0" style="font-size: 6pt; color: rgb(0, 0, 0);"><div class="circle"></div><b>Мартин Скорсезе завел аккаунт на сайте с отзывами на кино</b></p>
        <p style="font-size: 9pt; color: gray;">Мартин Скорсезе завел страничку на сайте с
          отзывами к фильмам Letterboxd. Он взял себе
          ник @mscorsese. Режиссер опубликовал список
          «фильмов-компаньонов», которые он
          рекомендует смотреть подряд. Он признался,
          что такие ленты возвращают его в детство.
          «Всегда что-то узнаешь, видишь что-то в новом
          свете, потому что каждый фильм находится в
          диалоге с каждым другим. Чем больше разница
          между картинами, тем лучше», — написал
          Скорсезе.</p>
      </div>
    </div>

  </div>
</template>

<script setup>

</script>

<style scoped>

.circle {
    width: 10px;
    height: 10px;
    background-color: #7fccf4;
    border-radius: 50%;
    margin-right: 10px;
  }

</style>
