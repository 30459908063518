
<template>
  <div id="carouselComponent1" class="carousel slide" data-bs-ride="carousel">
    <ol class="carousel-indicators carousel-indicator-top-carousel">
      <li v-for="(item, index) in carouselItems" :key="'indicator-' + item.id" data-bs-target="#carouselComponent1"
        :data-bs-slide-to="index" :class="{ active: index === 0 }"></li>
    </ol>
    <div class="carousel-inner">
      <div v-for="(item, index) in carouselItems" :key="item.id" class="carousel-item" :class="{ active: index === 0 }">
        <img @click="go(item.profile_id, item.id, item.profile_link)" :src="'https://backend.chesnok.kz/storage/' + item.image" class="d-block w-100" alt="Slide image">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      carouselItems: [],
      city: "",
    };
  },
  methods: {
    go(id, slide_id, url) {
      // Проверяем, существует ли slide_id в localStorage
      const existingSlideId = localStorage.getItem(slide_id);

      if (!existingSlideId) {
        localStorage.setItem(slide_id, slide_id);
        axios.post("https://backend.chesnok.kz/api/clickSlide", {
          slide_id: slide_id,
          city_id: this.city.id,
        });
        console.log("мы отправили бабки в профиль");
      } else {
        console.log("Клик по рекламному блоку уже засчитан");
      }
      if(id != null){
        this.$router.push("/show/" + id);
      }else{
        document.location = url;
      }
    },
    async fetchCarouselItems() {
      try {
        const response = await axios.get('https://backend.chesnok.kz/api/topslide/' + this.city.id);
        this.carouselItems = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.city = JSON.parse(localStorage.getItem("selectedCity"));
    this.fetchCarouselItems();
  },
};
</script>

<style scoped>
.carousel-indicators li {
  margin: 3px 0;  /* Добавьте меньший вертикальный маргин */
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ebebeb;
}

.carousel-indicators .active {
  background-color: #f8f8f8;
}

.carousel-indicators {
  list-style: none;
}

.carousel-indicators li {
  text-indent: 0;
}

.carousel-item {
  height: 4wvh;
  /* Задаем высоту аналогичную второму компоненту */
  object-fit: cover;
  object-position: center;
}

@media (max-width: 576px) {
  .carousel-item {
    height: 10vh;
  }

  .carousel-indicators li {
    margin: 3px 0;
  }
}
</style>
