<template>
  <div class="container">
    <div class="desktop-view " style="position: relative; padding-top: 30px; padding-bottom: 30px;">
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-between align-items-left">
            <!-- Левая часть с логотипом и навигацией -->
            <div class="d-flex align-items-left">
              <img
                loading="lazy"
                @click="go('/')"
                src="../../src/assets/logo-black.png"
                class="flex-shrink-0 black-logo"
                style="
                  margin: 1%;
                  width: 124px;
                  height: 25px;
                  aspect-ratio: 5.56;
                  margin-right: 20px;
                  cursor: pointer;
                "
              />
              <img
                loading="lazy"
                @click="go('/')"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc030b5ad7463b4d5c794fc19f39012659495dc4b4f2ebb19e89843ed9f54695?"
                class="flex-shrink-0 white-logo"
                style="
                  width: 130px;
                  aspect-ratio: 5.56;
                  margin-right: 20px;
                  cursor: pointer;
                "
              />
              <div class="d-flex align-items-center ms-3">
                <div class="px-2 menu-item-desktop" @click="shownav = !shownav">
                  Каталог
                </div>
                <div
                  class="px-2 menu-item-desktop"
                  @mouseenter="showGuide = true"
                  @mouseleave="showGuide = false"
                >
                  Гид
                  <div v-show="showGuide" class="sub-menu">
                    <div class="sub-menu-item" @click="go('/customer')">
                      Вы заказчик?
                    </div>
                    <div class="sub-menu-item" @click="go('/managers')">
                      Вы профи?
                    </div>
                    <div class="sub-menu-item" @click="go('/about')">О нас</div>
                  </div>
                </div>
                <div class="px-2 menu-item-desktop" @click="go('/news')">
                  Новости
                </div>
                <div
                  class="px-2 menu-item-desktop"
                  style="white-space: nowrap"
                  @click="go('/advancedsearch')"
                >
                  Расширенный поиск
                </div>
              </div>
            </div>
            <!-- Правая часть с аватаром, локацией и кнопкой входа -->
            <div class="d-flex align-items-center">
              <img
                loading="lazy"
                :src="changeThemeImgUrl"
                class="flex-shrink-0 night-mode"
                style="width: 55px; aspect-ratio: 1.72"
                @click="changeTheme()"
              />
              <div class="d-flex align-items-center ms-3">
                <div class="ms-2" style="white-space: nowrap">
                  <!-- Весь Казахстан -->
                  <CitySelect />
                </div>
              </div>
              <div class="ms-3">
                <button
                  class="btn btn-dark rounded-pill px-4 py-1"
                  @click="go('/login')"
                  v-if="!loggedIn"
                >
                  Войти
                </button>
              </div>
              <!-- УВЕДОМЛЕНИЯ -->
              <div class="ms-3" v-if="loggedIn">
                <div class="dropdown position-relative">
                  <button
                    class="btn dropdown-toggle no-caret notibutton"
                    type="button"
                    style="background-color: gainsboro; position: relative"
                    id="notificationDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span
                      class="badge rounded-pill bg-danger notification-badge"
                      v-if="notifications.length > 0"
                      >{{ notifications.length }}</span
                    >
                    <i class="bi bi-bell p-0"></i>
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu-end notiblock"
                    aria-labelledby="notificationDropdown"
                  >
                    <li class="p-3" v-if="notifications.length > 0">
                      <q-btn
                        color="primary"
                        @click="clearNotifies()"
                        style="cursor: pointer"
                        >{{ $t("MARK_AS_READ") }}</q-btn
                      >
                    </li>
                    <hr />
                    <li
                      v-for="notification in notifications"
                      :key="notification.id"
                    >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="noti(notification)"
                        >{{ notification.text }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <!-- ПРОФИЛЬ -->
              <div>
                <div class="dropdown ms-3" v-if="loggedIn">
                  <button
                    class="btn btn-secondary dropdown-toggle userbtn"
                    style=""
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ username }}
                  </button>
                  <ul
                    class="dropdown-menu dropdownusermenu"
                    v-if="isArtist == 'false'"
                  >
                    <li>
                      <a class="dropdown-item" href="/cabinet/myrequest"
                        >Сообщения
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/cabinet/bookmarks"
                        >Избранное</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/cabinet/editaccountuser"
                        >Редактировать профиль</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click="logout"
                        >Выйти</a
                      >
                    </li>
                  </ul>
                  <ul class="dropdown-menu dropdownusermenu" v-else>
                    <li>
                      <a class="dropdown-item" href="/cabinet/profileslist">{{
                        $t("questionary")
                      }}</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/cabinet/views">{{
                        $t("views")
                      }}</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/cabinet/requests">{{
                        $t("requests")
                      }}</a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="/cabinet/editaccountprofile"
                        >{{ $t("profile") }}</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="/cabinet/marketing">{{
                        $t("marketing")
                      }}</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="/cabinet/offers">{{
                        $t("special_requests")
                      }}</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click="logout">{{
                        $t("logout")
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="drpdownmenu2" v-show="shownav" v-click-outside="closeNav">
          <div class="row justify-content-center">
            <div class="col-2" v-for="(column, index) in columns" :key="index">
              <li v-for="category in column" :key="category.id">
                <a class="dropdown-item" :href="'/categories/' + category.id">{{
                  category.name
                }}</a>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-view">
      <div class="row p-2">
        <div class="col-3" @click="changemobilemenu()">
          <img
            src="../assets/mobileicon.png"
            class="white-logo m-1"
            v-if="!isMobileMenuOpen"
            alt=""
          />
          <img src="../assets/xicon.png" class="white-logo m-1" v-else alt="" />

          <img
            src="../assets/mobileiconblack.png"
            class="black-logo m-1"
            v-if="!isMobileMenuOpen"
            alt=""
          />
          <img
            src="../assets/xiconblack.png"
            class="black-logo m-1"
            v-else
            alt=""
          />
        </div>
        <div class="col-6" style="text-align: center">
          <!-- <img src="../assets/logo.png" @click="go('/')" alt="" /> -->

          <img
            loading="lazy"
            @click="go('/')"
            src="../../src/assets/logo-black.png"
            class="flex-shrink-0 black-logo"
            style="
              margin: 1%;
              width: 124px;
              height: 25px;
              aspect-ratio: 5.56;
              margin-right: 20px;
              cursor: pointer;
            "
          />
          <img
            loading="lazy"
            @click="go('/')"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc030b5ad7463b4d5c794fc19f39012659495dc4b4f2ebb19e89843ed9f54695?"
            class="flex-shrink-0 white-logo"
            style="
              width: 130px;
              aspect-ratio: 5.56;
              margin-right: 20px;
              cursor: pointer;
            "
          />
        </div>
        <div class="col-3">
          <button
            class="btn btn-dark rounded-pill py-1 w-100"
            @click="go('/login')"
            style="font-size: 10pt"
            v-if="!loggedIn"
          >
            Войти
          </button>
          <button
            class="btn btn-dark rounded-pill py-1 w-100"
            @click="logout"
            style="font-size: 10pt"
            v-if="loggedIn"
          >
            Выйти
          </button>
        </div>
      </div>
    </div>

    <div class="mobile-block" v-if="isMobileMenuOpen">
      <div
        class="row"
        style="padding-top: 2%; padding-right: 6%; padding-left: 5%"
      >
        <div class="col-3" @click="changemobilemenu()">
          <img src="../assets/xicon.png" style="margin-left: 10px" alt="" />
        </div>
        <div class="col-6" style="text-align: center">
          <img
            loading="lazy"
            @click="go('/')"
            src="../../src/assets/logo-black.png"
            class="flex-shrink-0 black-logo"
            style="
              margin: 1%;
              width: 124px;
              height: 25px;
              aspect-ratio: 5.56;
              margin-right: 20px;
              cursor: pointer;
            "
          />
          <img
            loading="lazy"
            @click="go('/')"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc030b5ad7463b4d5c794fc19f39012659495dc4b4f2ebb19e89843ed9f54695?"
            class="flex-shrink-0 white-logo"
            style="
              width: 130px;
              aspect-ratio: 5.56;
              margin-right: 20px;
              cursor: pointer;
            "
          />
        </div>
        <div class="col-3">
          <button
            class="btn btn-dark rounded-pill py-1 w-100"
            @click="go('/login')"
            style="font-size: 10pt"
            v-if="!loggedIn"
          >
            Войти
          </button>
          <button
            class="btn btn-dark rounded-pill py-1 w-100"
            @click="logout"
            style="font-size: 10pt"
            v-if="loggedIn"
          >
            Выйти
          </button>
        </div>

        <div class="col-12">
          <hr style="border-bottom: #efefef 1px solid" />
        </div>

        <div class="col-6">
          <CitySelect />
        </div>
        <div class="col-6" style="text-align: right">
          <button
            class="btn dropdown-toggle no-caret notibutton"
            type="button"
            style="background-color: gainsboro; position: relative"
            id="notificationDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span
              class="badge rounded-pill bg-danger notification-badge"
              v-if="notifications.length > 0"
              >{{ notifications.length }}</span
            >
            <i class="bi bi-bell p-0"></i>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-end notiblock"
            style="
              width: 90% !important;
              height: 50% !important;
              overflow: scroll !important;
            "
            aria-labelledby="notificationDropdown"
          >
            <li class="p-3" v-if="notifications.length > 0">
              <q-btn
                color="primary"
                @click="clearNotifies()"
                style="cursor: pointer"
                >{{ $t("MARK_AS_READ") }}</q-btn
              >
            </li>
            <hr />
            <li v-for="notification in notifications" :key="notification.id">
              <a class="dropdown-item" href="#" @click="noti(notification)">{{
                notification.text
              }}</a>
            </li>
          </ul>
        </div>

        <div class="col-12 mt-3" v-if="loggedIn">
          <!-- навигация обычного юзера -->
          <div v-if="isArtist == 'false'">
            <div
              class="px-2 pt-3 menu-item-desktop"
              @click="go('/cabinet/myrequest')"
            >
              Сообщения
            </div>
            <div
              class="px-2 pt-3 menu-item-desktop"
              @click="go('/cabinet/bookmarks')"
            >
              Избранное
            </div>

            <div
              class="px-2 pt-3 menu-item-desktop"
              @click="go('/cabinet/editaccountuser')"
            >
              Редактировать профиль
            </div>
          </div>
          <!-- навигация менеджера -->
          <div v-if="isArtist == 'true'">
            <div
              class="px-2 pt-3 menu-item-desktop"
              @click="go('/cabinet/profileslist')"
            >
              Анкеты
            </div>
            <div
              class="px-2 pt-3 menu-item-desktop"
              @click="go('/cabinet/views')"
            >
              Просмотры
            </div>
            <div
              class="px-2 pt-3 menu-item-desktop"
              @click="go('/cabinet/requests')"
            >
              Запросы
            </div>
            <div
              class="px-2 pt-3 menu-item-desktop"
              @click="go('/cabinet/editaccountprofile')"
            >
              Редактировать профиль
            </div>
            <div
              class="px-2 pt-3 menu-item-desktop"
              @click="go('/cabinet/marketing')"
            >
              Маркетинг
            </div>
            <div
              class="px-2 pt-3 menu-item-desktop"
              @click="go('/cabinet/offers')"
            >
              Специальные предложения
            </div>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="px-2 menu-item-desktop" @click="shownav = !shownav">
            Каталог
            <img
              class="black-logo"
              src="../assets/arrow-right-red.png"
              style="height: 16px"
              alt=""
            />
            <img
              class="white-logo"
              src="../assets/arrow-right.png"
              style="height: 16px"
              alt=""
            />
          </div>
        </div>
        <div class="col-12">
          <div v-if="shownav" style="width: 99%">
            <div class="row justify-content-between">
              <div class="row p-4">
                <div
                  class="col-6"
                  v-for="(column, index) in columns.slice(
                    0,
                    Math.ceil(columns.length / 2)
                  )"
                  :key="'col-' + index"
                >
                  <li
                    v-for="category in column"
                    :key="category.id"
                    style="list-style-type: none"
                  >
                    <a
                      class="dropdown-item"
                      :href="'/categories/' + category.id"
                      >{{ category.name }}</a
                    >
                  </li>
                </div>
                <div
                  class="col-6"
                  v-for="(column, index) in columns.slice(
                    Math.ceil(columns.length / 2)
                  )"
                  :key="'col-' + index + '-second'"
                >
                  <li
                    v-for="category in column"
                    :key="category.id"
                    style="list-style-type: none"
                  >
                    <a
                      class="dropdown-item"
                      :href="'/categories/' + category.id"
                      >{{ category.name }}</a
                    >
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 pt-3">
          <div
            class="px-2 menu-item-desktop"
            @click="showGuide = !showGuide"
            @mouseenter="showGuide = true"
            @mouseleave="showGuide = false"
          >
            Гид
            <div v-show="showGuide" class="sub-menu">
              <div class="sub-menu-item" @click="go('/customer')">
                Вы заказчик?
              </div>
              <div class="sub-menu-item" @click="go('/managers')">
                Вы профи?
              </div>
              <div class="sub-menu-item" @click="go('/about')">О нас</div>
            </div>
          </div>
          <div class="px-2 pt-3 menu-item-desktop" @click="go('/news')">
            Новости
          </div>
          <div
            class="px-2 pt-3 menu-item-desktop"
            style="white-space: nowrap"
            @click="go('/advancedsearch')"
          >
            Расширенный поиск
          </div>
        </div>
        <div class="col-12 p-3">
          <img
            loading="lazy"
            :src="changeThemeImgUrl"
            class="flex-shrink-0 night-mode"
            style="width: 55px; aspect-ratio: 1.72"
            @click="changeTheme()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CitySelect from "../components/CitySelect.vue";

export default {
  components: {
    CitySelect,
  },
  data() {
    return {
      isDarkTheme: false,
      notifications: [],
      loggedIn: false,
      shownav: false,
      showGuide: false,
      isArtist: false,
      categories: [],
      changeThemeImgUrl: "",
      username: "",
      isMobileMenuOpen: false,
      originalSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/016a871e4297c93f53028484a65a4537e941d6feaf0c0eaf6db72a72fb77a060?",
      hoverSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/de4a684c6d267af7c2d65cd1c865cd86584e2d73b5266e0a81f6bbf7b1a01b4b?",
    };
  },
  methods: {
    closeNav(){
      console.log('закрываем')
    },
    noti(noti) {
      this.isMobileMenuOpen = false;
      console.log(noti);
      if (noti.type == "message") {
        localStorage.setItem("activeChatId", noti.chat_id);
        console.log(this.$router.currentRoute.value.path);
        console.log("-");
        if (this.$router.currentRoute.value.path == "/cabinet/requests") {
          console.log("hi");
          window.location.reload();
        } else {
          console.log("--");
          console.log(this.isArray);
          if (this.isArtist == "false") {
            this.$router.push("/cabinet/myrequest");
          } else {
            this.$router.push("/cabinet/requests");
          }
        }
      }
      let a = localStorage.getItem("activeChatId");
      console.log(a);
    },
    changeTheme() {
      let theme;
      if (localStorage.getItem("darkTheme") == null) {
        //ночная тема = тру
        theme = false;
      }
      if (localStorage.getItem("darkTheme") == "false") {
        theme = true;
      } else if (localStorage.getItem("darkTheme") == "true") {
        theme = false;
      }

      console.log("вытаскиваем инфу по ночной теме до изменений");
      console.log(localStorage.getItem("darkTheme"));
      console.log(theme);

      localStorage.setItem("darkTheme", theme);

      document.body.classList.toggle("dark-theme", theme);
      if ((theme = true)) {
        this.changeThemeImgUrl =
          "https://cdn.builder.io/api/v1/image/assets/TEMP/016a871e4297c93f53028484a65a4537e941d6feaf0c0eaf6db72a72fb77a060?";
      } else {
        this.changeThemeImgUrl =
          "https://cdn.builder.io/api/v1/image/assets/TEMP/de4a684c6d267af7c2d65cd1c865cd86584e2d73b5266e0a81f6bbf7b1a01b4b?";
      }
      console.log("вытаскиваем инфу по ночной теме после изменений");
      console.log(localStorage.getItem("darkTheme"));
      document.location.reload();
    },

    changemobilemenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    logout() {
      localStorage.removeItem("isLogged");
      this.loggedIn = false;
      localStorage.removeItem("isArtist");
      localStorage.removeItem("isLogged");
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("avatar");
      window.location.href = "/";
    },
    go(link) {
      this.isMobileMenuOpen = false;
      this.$router.push(link);
    },
    getCategoriesInfo() {
      axios
        .get("https://backend.chesnok.kz/api/categories")
        .then((response) => {
          this.categories = response.data;
        })
        .catch((error) => {});
    },
    changeImage(event) {
      event.target.src = this.hoverSrc;
    },
    resetImage(event) {
      event.target.src = this.originalSrc;
    },
    getUserInfo() {
      console.log("GetUserInfo() ---- ");
      console.log(this.loggedIn);
      if (this.loggedIn == "true") {
        console.log("Истина");
        let token = localStorage.getItem("token");

        axios
          .post(
            "https://backend.chesnok.kz/api/me",
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            this.user = response;
            axios
              .post(
                "https://backend.chesnok.kz/api/notifies",
                {
                  id: this.user.id,
                },
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then((response) => {
                this.notifications = response.data.data;
                console.log(this.notifies);
              })
              .catch((error) => {});
          })
          .catch((error) => {});
      } else {
        console.log("вышли");
      }
    },
  },
  computed: {
    columns() {
      const columns = [];
      const columnCount = 5;
      const itemsPerColumn = Math.ceil(this.categories.length / columnCount);
      for (let i = 0; i < columnCount; i++) {
        columns.push(
          this.categories.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn)
        );
      }
      return columns;
    },
  },
  created() {
    if (localStorage.getItem("darkTheme") === "true") {
      this.isDarkTheme = true;
      document.body.classList.add("dark-theme");
    }

    this.getCategoriesInfo();
    console.log("ckeck is logged");

    console.log(localStorage.getItem("isLogged"));
    this.loggedIn = localStorage.getItem("isLogged") || null;
    console.log(this.loggedIn);
    this.getUserInfo();
    this.username = localStorage.getItem("username");

    console.log("ckeck is isArtist");

    this.isArtist = localStorage.getItem("isArtist");
    console.log(this.isArtist);

    console.log("проверяем тему при открытии");
    console.log(localStorage.getItem("darkTheme"));
    if (localStorage.getItem("darkTheme") == "true") {
      this.changeThemeImgUrl =
        "https://cdn.builder.io/api/v1/image/assets/TEMP/016a871e4297c93f53028484a65a4537e941d6feaf0c0eaf6db72a72fb77a060?";
    } else {
      this.changeThemeImgUrl =
        "https://cdn.builder.io/api/v1/image/assets/TEMP/de4a684c6d267af7c2d65cd1c865cd86584e2d73b5266e0a81f6bbf7b1a01b4b?";
    }
    // if(localStorage.getItem("darkTheme") = true){
    //     this.changeThemeImgUrl = 'https://cdn.builder.io/api/v1/image/assets/TEMP/016a871e4297c93f53028484a65a4537e941d6feaf0c0eaf6db72a72fb77a060?'
    //   }else{
    //     this.changeThemeImgUrl = 'https://cdn.builder.io/api/v1/image/assets/TEMP/016a871e4297c93f53028484a65a4537e941d6feaf0c0eaf6db72a72fb77a060?';
    //   }
  },
  mounted() {},
};
</script>

<style scoped>
/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
  .mobile-view {
    display: block;
    /* Показать на мобильных устройствах */
  }

  .desktop-view {
    display: none;
    /* Скрыть на мобильных */
  }
}

/* Медиа-запрос для десктопов */
@media (min-width: 769px) {
  .desktop-view {
    display: block;
    /* Показать на десктопах */
  }

  .mobile-view {
    display: none;
    /* Скрыть на десктопах */
  }
}

.mobile-block {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100000;
  left: 0%;
  top: 0%;
  overflow-y: scroll;
}

body.dark-theme .mobile-block {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #3e3e3e;
  z-index: 100000;
  left: 0%;
  top: 0%;
}

body.dark-theme .notiblock {
  background: #3e3e3e;
  color: rgba(255, 255, 255, 0.475);
}

body.dark-theme .notibutton {
  background: #ff96964f !important;
}

body.dark-theme .userbtn {
  background-color: #3e3e3e !important;
  color: rgba(255, 255, 255, 0.806);
  border-radius: 25px;
}

body.dark-theme .dropdownusermenu {
  background-color: #3e3e3e !important;
  color: rgba(255, 255, 255, 0.806);
}

.userbtn {
  background-color: white;
  color: black;
  border-radius: 25px;
}

.black-logo {
  display: none;
}
.white-logo {
  display: inline;
}
body.dark-theme .white-logo {
  display: none;
}
body.dark-theme .black-logo {
  display: inline;
}

body.dark-theme .btn-dark {
  background: white !important;
  color: black;
}

.no-caret::after {
  display: none !important;
}

.notification-badge {
  position: absolute !important;
  top: -5px;
  left: -5px;
  transform: translate(-50%, -50%);
}

.menu-item-desktop {
  cursor: pointer;
  position: relative;
  font-weight: 300;
}

.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 150px;
}

.sub-menu-item {
  padding: 10px 20px;
  cursor: pointer;
}

.sub-menu-item:hover {
  background: #f0f0f0;
}

.drpdownmenu2 {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 100%;
  padding-bottom: 3vh;
  list-style-type: none;
  border-bottom-left-radius: 8px;
  /* Скругление нижнего левого угла */
  border-bottom-right-radius: 8px;
  /* Скругление нижнего правого угла */
}
body.dark-theme .drpdownmenu2 {
  background-color: #3e3e3e;
  color: #fff;
  border-radius: 8px;
  padding-top: 3vh;
}
body.dark-theme .sub-menu {
  background-color: #3e3e3e;
  color: #fff;
  border-radius: 8px;
  padding-top: 3vh;
}
</style>
