<template>
  <div class="container p-0">
    <div class="div p-0">
      <div class="div-2">
        <!-- Специальное предложение -->
        <span>{{ $t('SPECIAL') }}</span>
      </div>
      <img loading="lazy" 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/80231082d21146716fbffcf1dc7cc9f3b8c185e0011604b8696a52f72a7fb7c2?"
        class="img imgspecial" />
    </div>
    <div class="col-12 p-0 m-0 shadow-transition" v-if="recommendedPosts">
      <div class="mt-3 " style="">
        <div class="row p-1">
          <div v-for="item in paginatedItems" :key="item.id" class="col-6 col-md-3 p-2" style="cursor: pointer;"
            @click="go(item)">
            <div class="image-wrapper"
              style="position: relative; width: 100%; padding-top: 100%; overflow: hidden;">
              <img :src="'https://backend.chesnok.kz/storage/' + item.avatar" class="product-image" alt="Product image"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; border-radius: 10%;" />
              <button class="details-button">Подробнее</button>
            </div>
            <div class="info-overlay">
              <div style="min-height: 45px;">
                <h5 style=" font: 600 17px Montserrat, sans-serif;" class="nameartist">{{ item.name }}</h5>

              </div>
              <div v-if="!item.hideprice">
                <p class="fee" v-if="item.from == null && item.before == null" style="  font-family: Montserrat, sans-serif;">
                  <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/df7b540c24999b7db31bd581337f6357f667f49685117b4cb91e9684492ea096?"
                    class="img img-i-for-white" /> 
                    
                  <img loading="lazy"
                    src="../../src/assets/i.png"
                    class="img img-i-for-black" /> 

                    {{ $t('FEE_ON_REQUEST') }}</p>
                <p class="fee" v-if="item.from != null && item.before == null" style="  font-family: Montserrat, sans-serif;">
                  <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/df7b540c24999b7db31bd581337f6357f667f49685117b4cb91e9684492ea096?"
                    class="img img-i-for-white" />
                    
                    <img loading="lazy"
                    src="../../src/assets/i.png"
                    class="img img-i-for-black" /> 
                    
                    Гонорар от {{ formatPrice(item.from) }} ₸</p>
                <p class="fee" v-if="item.from == null && item.before != null" style="  font-family: Montserrat, sans-serif;">
                  <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/df7b540c24999b7db31bd581337f6357f667f49685117b4cb91e9684492ea096?"
                    class="img img-i-for-white" />
                    
                    <img loading="lazy"
                    src="../../src/assets/i.png"
                    class="img img-i-for-black" /> 

                    Гонорар до {{ formatPrice(item.before) }} ₸</p>
                <p class="fee" v-if="item.from != null && item.before != null" style="  font-family: Montserrat, sans-serif;">
                  <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/df7b540c24999b7db31bd581337f6357f667f49685117b4cb91e9684492ea096?"
                    class="img img-i-for-white" />
                    
                    <img loading="lazy"
                    src="../../src/assets/i.png"
                    class="img img-i-for-black" /> 


                    Гонорар ({{ formatPrice(item.from) }} ₸ - {{
                      formatPrice(item.before) }} ₸)</p>
              </div>
              <div v-else>
                <p style=" font-family: Montserrat, sans-serif;" class="fee"> 
                  <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/df7b540c24999b7db31bd581337f6357f667f49685117b4cb91e9684492ea096?"
                    class="img" /> {{ $t('FEE_ON_REQUEST') }}</p>
              </div>
            </div>
          </div>
        </div>
        <nav v-if="totalPages > 1" aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
              <a class="page-link" href="#" aria-label="Previous" @click="changePage(currentPage - 1)">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
              <a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
              <a class="page-link" href="#" aria-label="Next" @click="changePage(currentPage + 1)">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      recommendedPosts: [],
      isLoading: true,
      city: "",
      currentPage: 1,
      itemsPerPage: 8,
    };
  },
  methods: {
    formatPrice(price) {
      return Number(price).toLocaleString("ru-RU");
    },
    go(profile) {
      if (profile.type != "1") {
        this.$router.push("/show/" + profile.id);
      } else {
        this.$router.push("/show/foo" + profile.id);
      }
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    async fetchRecommendedPosts() {
      axios.post("https://backend.chesnok.kz/api/recomended", { id: this.city.id })
        .then((response) => {
          this.recommendedPosts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.city = JSON.parse(localStorage.getItem("selectedCity"));
    this.fetchRecommendedPosts();
  },
  computed: {
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.recommendedPosts.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.recommendedPosts.length / this.itemsPerPage);
    },
  },
};
</script>

<style scooped>
body {
  font-family: "Proxima Nova", sans-serif;
}

.img-i-for-black{
  display: none !important;
}

.img-i-for-white{
  display: inline;
}

body.dark-theme .img-i-for-white{
  display: none !important;
}

body.dark-theme .img-i-for-black{
  display: inline !important;
}

.info-overlay {
  padding-top: 10px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

.top-badge {
  background-color: #d9d9d9;
  color: rgb(0, 0, 0);
}

.star {
  font-size: 1.5em;
  color: grey;
}

.title-container {
  padding: 0px !important;
  margin: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.lineleft {
  flex-grow: 1;
  height: 8px;
  background-color: #7fccf4;
  margin: 0px 30px 0px 0px;
}

.lineright {
  flex-grow: 1;
  height: 8px;
  background-color: #7fccf4;
  margin: 0px 0px 0px 30px;
}

.anonse {
  font-size: 1.02em;
  color: #223971;
  margin: 0px !important;
}

.star.gold {
  color: gold;
}

.product {
  text-align: center;
  margin-bottom: 2em;
  padding: 1em;
  min-height: 39vh;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid grey !important;
}

.product:hover {
  box-shadow: 0 2px 5px rgba(32, 117, 214, 0.3);
}

.product .image-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.image-container {
  width: 23%;
  height: 20vh;
  margin: 10px;
  position: relative;
  overflow: hidden;
}
.div-2{
    font-weight: 500 !important;
  }
@media (max-width: 768px) {
  .image-container {
    width: 41%;
  }
  .imgspecial{
    display: none;
  }
  .div-2{
    font-weight: 500 !important;
  }
}

@media (max-width: 300px) {
  .image-container {
    width: 90%;
  }
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.details-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: rgba(128, 128, 128, 0.5); /* Полупрозрачный серый цвет */
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 11px;
  font-family: Montserrat, sans-serif;
}
</style>
