<template>
  <li class="nav-item dropdown">
    <a class="nav-link  m-1" href="#" style="color:#223971 !important" id="navbarDropdownMenuLink" role="button"
      data-bs-toggle="dropdown" aria-expanded="false">
      <span style="font-size: 1.19vw; color: #223971 !important;">КАТАЛОГ</span>
    </a>
    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
      <li v-for="category in categories" :key="category.id">
        <a class="dropdown-item" :href="'/categories/' + category.id">{{ category.name }}</a>
      </li>
    </ul>
  </li>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
  setup() {
    const categories = ref([]);

    const getCategories = async () => {
      try {
        const response = await axios.get('https://backend.chesnok.kz/api/categories');
        categories.value = response.data;
        console.log(response.data)
      } catch (error) {
        console.error('Ошибка при получении списка категорий:', error);
      }
    };

    onMounted(getCategories);

    return {
      categories
    };
  }
}
</script>

<style >
body {
  font-family: Montserrat, sans-serif !important;
}

/* Добавьте здесь свои стили */</style>