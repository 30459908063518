<template>
  <!-- Основной контейнер, адаптируется для мобильных устройств -->
  <div class="container h-100" :class="{ 'mobile-container': !isLargeScreen }" >
    <!-- Контейнер для десктопного режима -->
    <div class="row h-100" style="margin-bottom: 10% !" v-if="isLargeScreen">
      <!-- Список контактов -->
      <div class="col-3 contact-list" style="height: 80%; overflow-y: auto">
        <div class="pt-3" style="text-align: center">
          <p style="font-weight: 100">Сообщения / Чат</p>
        </div>

        <div class="pt-3 mb-4">
          <input type="text" class="w-100 form-control searchformdesktop" placeholder="Поиск" />
        </div>
        <!-- Один элемент контакта -->
        <div
          class="contactitem row p-3 m-0 mb-3"
          v-for="(contact, index) in contacts"
          :key="index"
          :class="{ 'active-contact': activeChat.id === contact.id }"
          @click="
            fetchChatMessages(contact.id);
            handleContactClick(contact.id);
            activateMessageScroll(true);
          "
          style="display: flex; align-items: center"
        >
          <!-- Аватар контакта -->
          <div
            class="col-2 p-0 d-flex justify-content-center align-items-center"
          >
            <img
              class="product-image"
              :src="
                'https://backend.chesnok.kz/storage/' +
                contact.messages[0].receiver_avatar
              "
              style="width: 100%"
            />
          </div>

          <!-- Информация о контакте -->
          <div class="col-10">
            <p class="p-0 m-0">
              {{ contact.messages[0].receiver_name }}
              <span
                v-if="contact.unread_messages_count > 0"
                class="badge bg-danger"
              >
                {{ contact.unread_messages_count }}
              </span>
            </p>
            <p class="p-0 m-0" style="color: gray">
              {{ contact.last.content }}
            </p>
          </div>
        </div>
      </div>

      <!-- История сообщений -->
      <div class="col-9 message-history">
        <div
          v-if="receiver_name"
          class="title-header-desktop"
        >
          <div class="row align-items-center p-4  contact-header-desktop">
            <div class="col-1 ">
              <div class="p-0 d-flex justify-content-center align-items-center">
                <img
                  class="product-image"
                  :src="'https://backend.chesnok.kz/storage/' + receiver_avatar"
                  style="width: 100%"
                />
              </div>
            </div>
            <div class="col-11 d-flex align-items-center">
              <h5>{{ receiver_name }}</h5>
            </div>
          </div>
        </div>
        <div id="message-container" class="messages">
          <!-- Одно сообщение -->
          <div>
            <div
              v-for="(message, index) in selectedContactMessages"
              :key="index"
              class="message my-2"
              :class="
                sender == message.sender_id
                  ? 'user-message text-end'
                  : 'contact-message'
              "
              :id="index === selectedContactMessages.length - 1 ? 'last' : ''"
            >
              {{ message.content }}
              <br />
              <small
                class="text-muted"
                style="font-weight: 100; font-size: 10pt"
                >{{ formatMessageDate(message.updated_at) }}</small
              >
            </div>
          </div>
        </div>

        <!-- Поле ввода нового сообщения -->
        <div
          class="input-area border-top py-2"
          v-if="activeChat.length > 0"
          style="height: 10%"
        >
          <input
            class="form-control me-2"
            v-model="newMessage"
            v-on:keyup.enter="sendMessage"
          />
          <button class="btn btn-primary" @click="sendMessage">
            Отправить
            <img src="../../assets/search.png" class="mt-3" alt="Search" />
          </button>
        </div>

        <!-- Поле ввода нового сообщения, если чат не подтвержден -->
        <div class="input-area border-top py-2" v-else style="height: 10%">
          <input
            class="form-control me-2"
            v-model="newMessage"
            v-on:keyup.enter="sendMessage"
          />
          <button
            class="btn btn-primary btn-sm"
            disabled
            @click="resetChatConfirmation()"
            v-if="activeChat.confirmed == 0"
          >
            Диалог не подтвержден
          </button>
          <img
            @click="sendMessage()"
            style="cursor: pointer"
            v-if="activeChat.confirmed == 1"
            src="../../assets/sendmessages.png"
            class="mt-1"
            alt="Search"
          />
        </div>
      </div>
    </div>

    <!-- Контейнер для мобильного режима -->
    <div v-else>
      <!-- Кнопка возврата к списку контактов -->

      <!-- Список контактов -->
      <div
        class="col-12 col-md-4 contact-list-mobile"
        style="
          height: 20% !important;
          box-shadow: none;
          overflow-x: auto;
          display: flex;
          flex-direction: row;
          scrollbar-width: none;  /* Firefox */
          -ms-overflow-style: none;  /* Internet Explorer 10+ */
        "
      >
        <!-- Один элемент контакта -->
        <div
          class="contactitem row p-4"
          v-for="(contact, index) in contacts"
          :key="index"
          :class="{ 'active-contact': activeChat.id === contact.id }"
          @click="
            activateMessageScroll(true);
            fetchChatMessages(contact.id);
            handleContactClick(contact.id);
          "
          style="
            display: flex;
            align-items: center;
            box-shadow: none !important;
          "
        >
          <!-- Аватар контакта -->
          <div class="col-12 p-0">
            <img
              :src="
                'https://backend.chesnok.kz/storage/' +
                contact.messages[0].receiver_avatar
              "
              style="
                width: 15vw;
                height: 15vw;
                object-fit: cover;
                border-radius: 500px;
              "
            />
          </div>
        </div>
      </div>
      <div
        class="col-12 p-1"
        style="padding-top: 10px"
        v-if="activeChat.id > 0"
      >
        <div
          class="row mt-3 p-2 contact-header"
          style="
            border: 0.4px solid lightgray;
            border-radius: 15px 15px 0px 0px;
          "
        >
          <div class="col-3 ">
            <img
              :src="
                'https://backend.chesnok.kz/storage/' +
                activeChat.messages[0].receiver_avatar
              "
              style="
                width: 15vw;
                height: 15vw;
                object-fit: cover;
                border-radius: 500px;
              "
            />
          </div>
          <div class="col-9">
            {{ activeChat.messages[0].receiver_name }}
          </div>
        </div>
      </div>



      
      <!-- История сообщений -->
      <div class="col-12 p-1 message-history mb-5  " v-if="activeChat.length != 0" style="">
        <div
          id="message-container"
          class="messages"
          style="height: 45vh !important"
        >
          <!-- Одно сообщение -->
          <div
            v-for="(message, index) in selectedContactMessages"
            :key="index"
            class="message my-2"
            :class="
              sender == message.sender_id
                ? 'user-message text-end'
                : 'contact-message'
            "
            :id="index === selectedContactMessages.length - 1 ? 'last' : ''"
          >
            {{ message.content }}
            <br />
            <small class="text-muted" style="font-size: 2vw">{{
              formatMessageDate(message.updated_at)
            }}</small>
          </div>
        </div>

        <!-- Поле ввода нового сообщения -->
        <div
          class="input-area border-top py-2"
          v-if="activeChat.length > 0"
          style="height: 10%"
        >
          <input
            class="form-control me-2"
            v-model="newMessage"
            v-on:keyup.enter="sendMessage"
          />
          <button class="btn btn-primary" @click="sendMessage">
            Отправить
          </button>

        </div>

        <!-- Поле ввода нового сообщения, если чат не подтвержден -->
        <div class="input-area border-top py-2" v-else style="height: 10%">
          <input
            class="form-control me-2"
            v-model="newMessage"
            v-on:keyup.enter="sendMessage"
          />
          <button
            class="btn btn-primary btn-sm"
            disabled
            @click="resetChatConfirmation()"
            v-if="activeChat.confirmed == 0"
          >
            Диалог не подтвержден
          </button>
          <!-- <button
            class="btn btn-primary"
            @click="sendMessage()"
            v-if="activeChat.confirmed == 1"
          >
            Отправить
          </button> -->
          <img
            @click="sendMessage()"
            style="cursor: pointer"
            v-if="activeChat.confirmed == 1"
            src="../../assets/sendmessages.png"
            class="mt-0"
            alt="Search"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { nextTick } from "vue";

export default {
  data() {
    return {
      receiver_name: "",
      contacts: [],
      selectedContactMessages: [],
      activeChat: [],
      newMessage: "",
      sender: 0,
      golast: false,
      isLargeScreen: window.innerWidth >= 900,
      prevMessagesCount: 0,
      messagesUpdater: null,
      receiver_avatar: "",
    };
  },
  filters: {
    formatDate: function (value) {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleString(); // Это форматирует дату в человекочитаемый вид
    },
  },
  methods: {
    formatMessageDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      const date = new Date(dateString);
      return date.toLocaleDateString("ru-RU", options);
    },
    activateMessageScroll(value) {
      this.golast = value;
    },
    handleContactClick(contactId) {
      // Останавливаем текущие интервалы (если они запущены)
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      if (this.intervalIdMess) {
        clearInterval(this.intervalIdMess);
      }

      // Запускаем необходимые функции
      this.fetchChatMessages(contactId);
    },
    startMessagesUpdater() {
      this.messagesUpdater = setInterval(() => {
        if (this.activeChat && this.activeChat.id) {
          this.fetchChatMessages(this.activeChat.id);
        }
      }, 5000);
    },
    stopMessagesUpdater() {
      clearInterval(this.messagesUpdater);
    },
    updateScreenSize() {
      this.isLargeScreen = window.innerWidth >= 900;
    },
    resetChatConfirmation() {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.chesnok.kz/api/resetChatConfirmation",
          {
            id: this.activeChat.id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.loadMessagesInitial();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendMessage() {
      console.log("sendmessage");
      let token = localStorage.getItem("token");

      if (this.activeChat.confirmed == 1) {
        axios
          .post(
            "https://backend.chesnok.kz/api/sendMessage",
            {
              chat_id: this.activeChat.id,
              sender_id: this.activeChat.messages[0].sender_id,
              receiver_id: this.activeChat.messages[0].receiver_id,
              content: this.newMessage,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            this.fetchChatMessages(this.activeChat.id);
            this.newMessage = "";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    markMessagesAsRead(id) {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.chesnok.kz/api/markAsRead",
          {
            messages: this.selectedContactMessages,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log(response.data);
          this.loadMessagesInitial();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startAutoUpdateOnlyMessages(chat_id) {
      if (this.intervalIdMess) {
        clearInterval(this.intervalIdMess);
      }
      this.intervalIdMess = setInterval(() => {
        this.fetchChatMessages(chat_id);
      }, 10000); // Обновление каждые 10 секунд (10000 миллисекунд)
    },
    fetchChatMessages(chat_id) {
      const currentMessageCount = this.selectedContactMessages.length;

      this.startAutoUpdateOnlyMessages(chat_id);

      this.contacts.forEach((element) => {
        if (element.id == chat_id) {
          this.activeChat = element;
          console.log("смотри че присвоили");
          console.log(element);
          this.receiver_name = element.messages[0].receiver_name;
          this.receiver_avatar = element.messages[0].receiver_avatar;
          this.sender = element.messages[0].sender_id;
        }
      });

      try {
        let token = localStorage.getItem("token");
        axios
          .post(
            "https://backend.chesnok.kz/api/getChatMessages",
            {
              id: chat_id,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            const newMessagesCount =
              response.data.length - this.prevMessagesCount;

            this.selectedContactMessages = response.data;
            this.prevMessagesCount = response.data.length;
            this.markMessagesAsRead(chat_id);

            const newMessageCount = response.data.length;

            if (newMessageCount > currentMessageCount) {
              this.scrollToBottom();
              console.log("запущаем скролл ту ботом");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error("Error fetching chat messages:", error);
      }
    },
    scrollToBottom() {
      nextTick(() => {
        const messageContainer = document.getElementById("message-container");
        if (messageContainer) {
          messageContainer.scrollTop = messageContainer.scrollHeight;
        }
      });
    },
    logout() {
      localStorage.removeItem("isLogged");
      window.location.href = "#/login";
    },
    loadMessagesInitial() {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.chesnok.kz/api/getUserChats",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.contacts = response.data;

          console.log("вот список активных контактов:");
          this.contacts.forEach((element) => {
            console.log(element.id);
          });
          let a = localStorage.getItem("activeChatId");
          console.log("вот id из storage - " + a);

          this.contacts.forEach((element) => {
            if (element.id == a) {
              console.log("Присваеватся новый актив чат");
              console.log(this.activeChat);
              this.activeChat = element;
              this.receiver_name = element.messages[0].receiver_name;
              this.fetchChatMessages(element.id);
            }
          });

          localStorage.removeItem("activeChatId");
          console.log(response);
        })
        .catch((error) => {
          // this.logout();
          console.log(error);
        });
    },
  },
  created() {
    if (!localStorage.getItem("isLogged")) {
      document.location.href = "/";
      exit;
    }
  },
  mounted() {
    window.addEventListener("resize", this.updateScreenSize);
    this.loadMessagesInitial();
    this.startMessagesUpdater();
    setInterval(this.loadMessagesInitial, 10000);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
};
</script>

<!-- CSS Styles moved to separate file -->

<style scoped>
.container {
  height: 79vh !important;
}
.contact-list-mobile{
}
.mobile-container {
  height: 100vh !important;
}

.active-contact {
  background-color: #7e7e7e9a;
  color: black;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.3) !important;
  border-radius: 15px !important;
}
.product-image {
  width: 100%;
  border-radius: 50px;
  /* Изменено на 100% */
  height: auto;
  /* Изменено с height: 100% на auto для сохранения пропорций */
  aspect-ratio: 1 / 1;
  /* Добавлено для обеспечения квадратного аспекта */
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 1px 1px 1px #00000040;
}
.contactitem {
  cursor: pointer !important;
  background: white;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}
body.dark-theme .contactitem{
  background: #65656583;
}
.contactitem:hover {
}

.topbar {
  background-color: gainsboro !important;
  padding: 3%;
}

.chat-page {
  padding: 16px;
}

.fixed-full {
  background-color: rgba(228, 228, 228, 0.295);
  margin: 3%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.contact-list {
  height: 70vh !important;
  overflow-y: hidden;
  background-color: #f3f3f3;
  border-radius: 15px;
}

.title-header-desktop{
  border: 0.3px solid lightgrey;
            min-height: 10vh;
            border-radius: 10px 10px 0px 0px;
}

body.dark-theme .title-header-desktop{
  background: #ffffff2e !important;
  border: 0.4px solid rgba(211, 211, 211, 0.474);
}


.message-history {
  max-height: 600px;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  height: 60vh !important;
}

.user-message {
  background-color: #cd0b0b89;
  color: white;
  display: inline-block;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  float: right;
  clear: both;
}

.contact-message {
  border: 1px solid rgb(117, 114, 114);
  color: #333;
  display: inline-block;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  float: left;
  clear: both;
}
body.dark-theme .contact-message {
  border: 1px solid rgb(117, 114, 114);
  background: gray;
  color: #333;
  display: inline-block;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
  float: left;
  clear: both;
}

.input-area {
  padding: 8px;
  border-top: 1px solid #e0e0e0;
}

.contact-list {
  height: 100%;
  overflow-y: auto;
  /* border-right: 1px solid #e0e0e0; */
}

body.dark-theme .searchformdesktop{
  background: #3E3E3E;
  border: 1px solid rgba(255, 255, 255, 0.184)
}
body.dark-theme .searchformdesktop::placeholder{
  color: lightgray;
}
.q-item-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-area {
  display: flex;
  align-items: center;
  padding: 8px;
}

.input-area .q-input {
  flex: 1;
  margin-right: 8px;
}

.message {
  max-width: 60%;
  word-wrap: break-word;
}

.messages {
  padding-bottom: 8px;
}
body.dark-theme .contact-list{
  background: #3E3E3E !important;
}

.messages::-webkit-scrollbar {
  width: 6px;
}

.messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.contact-list::-webkit-scrollbar {
  width: 6px;
}

.contact-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.contact-list-mobile{
}

body.dark-theme .contact-list-mobile{
background: none;
}
body.dark-theme .contact-header{
  background: #3e3e3e;
  border: 0.4px solid grey !important;
}
@media only screen and (max-width: 600px) {
  .contact-list {
    width: 100%;
  }
  .contactitem{
    background: none;
  }

}
</style>
