<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <img
          :src="'https://backend.chesnok.kz/storage/' + news.main_image"
          style="
            width: 100%;
            height: 30vh;
            border-radius: 35px;
            object-fit: cover;
          "
        />
      </div>
      <div class="col-12 mt-3">
        <div v-html="news.short_description" class="descriptionDiv"></div>
      </div>
      <div class="col-12 mt-3">
        <div v-html="news.full_description" class="descriptionDiv"></div>
      </div>

      <div class="col-12 mt-5">
        <h4><b>Недавние новости</b></h4>
      </div>
      <div class="col-12 mb-5">
        <div class="row">
          <div
            @click="openNews(newsitem.id)"
            class="col-md-6 col-lg-3 mb-4"
            v-for="(newsitem, index) in anyNews"
            :key="index"
          >
            <div
              class="card h-100"
              style="border: none; background: none !important"
            >
              <img
                :src="'https://backend.chesnok.kz/storage/' + newsitem.main_image"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body p-0" style="background: none !important">
                <h5 class="p-0 mt-3">{{ newsitem.short_description }}</h5>
              </div>
              <btn class="btn btn-podr-right">Подробнее</btn>
              <btn class="btn btn-podr-left">{{
                formatDate(newsitem.created_at)
              }}</btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <hr style="border: 4px solid #7fccf4; opacity: 1;" class="mt-4" /> -->
</template>
<script>
import axios from "axios";
import { parseISO, format } from "date-fns";

export default {
  data() {
    return {
      newsId: null,
      news: [],
      anyNews: [],
    };
  },
  components: {},
  mounted() {
    this.getNewsInfo();
    this.getAnynews();
  },
  methods: {
    openNews(id) {
      document.location.href = '/news/' + id;
    },
    formatDate(isoDateString) {
      // Преобразуйте ISO строку в объект даты
      const date = parseISO(isoDateString);
      // Отформатируйте дату в нужный формат
      return format(date, "dd.MM.yyyy");
    },
    getNewsInfo() {
      axios
        .get("https://backend.chesnok.kz/api/news/" + this.$route.params.id)
        .then((response) => {
          this.news = response.data;
          console.log("вот ответ по новости");
          console.log(this.news);
          console.log(response.data);
        });
    },
    getAnynews() {
      axios
        .post("https://backend.chesnok.kz/api/anyNews/", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.anyNews = response.data;
        });
    },
  },
};
</script>
<style scooped>
body {
  font-family: Montserrat, sans-serif !important;
}
.btn-podr-right {
  background: rgba(128, 128, 128, 0.303) !important;
  border-radius: 15px;
  width: 30%;
  font-size: 8pt !important;
  position: absolute;
  right: 5%;
  top: 150px;
  color: white !important;
}
.btn-podr-left {
  background: rgba(128, 128, 128, 0.303) !important;
  border-radius: 15px;
  width: 30%;
  font-size: 8pt !important;
  position: absolute;
  left: 5%;
  top: 150px;
  color: white !important;

}

.btn-date {
  color: black;
  font-weight: 600;
  border: none;
  background: #fcf3f3;
  padding: 10px 20px;
  border-radius: 12px;
}
body.dark-theme .btn-date {
  background: #454545;
  color: white;
}
body.dark-theme .btn-date div {
  background: #454545;
  color: gray !important;
}
.btn-date div {
  color: gray;
  font-weight: 400;
}
.card-img-top {
  border-radius: 15px;
  height: 200px;
  object-fit: cover;
}
.btn-container {
  white-space: nowrap;
}
.btn {
  flex-shrink: 0;
}
.btn-date div {
  color: gray;
  font-weight: 400;
}
.card-img-top {
  border-radius: 15px;
  height: 200px;
  object-fit: cover;
}

.main-cont {
  padding: 0px 10% !important;
}

.descriptionDivMobile {
  display: none;
}

.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #7fccf4;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 1vh;
}

.square-image {
  width: 100%;
  padding-top: 100%;
  /* Это обеспечивает квадратную форму */
  position: relative;
  overflow: hidden;
}

.descriptiondiv p h1 h2 h3 h4 h5 b i {
  padding: 0px;
  margin: 0px;
}

.fulldescriptionDiv {
  color: rgb(73, 72, 72) !important;
  font-size: 16pt;
}

.fulldescriptionDiv p h1 h2 h3 h4 h5 b i {
  color: rgb(85, 84, 84) !important;
}

.square-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.demo-image {
  width: 90%;
  max-width: 300px;
  height: 1px;
}

.news-item {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
}

@media (max-width: 980px) {
  .main-cont {
    padding: 0px 5% !important;
  }

  .datenews {
    font-size: 9pt;
    color: black !important;
  }

  .descriptionDiv {
    display: none;
  }

  .fulldescriptionDiv {
    color: gray;
    font-size: 10pt !important;
  }

  .fulldescriptionDiv p h1 h2 h3 h4 h5 b i {
    color: gray !important;
    font-size: 10pt !important;
  }

  .descriptionDivMobile {
    display: block;
  }

  .circle {
    margin-top: 0.4vh;
    width: 12px;
    height: 12px;
  }
}
</style>
