<template>
  <div class="container p-0">
    <div class="row p-0 m-0">
      <div
        class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
        v-for="(ad, index) in ads"
        :key="ad.id"
        :class="{'first': index % 2 === 0, 'second': index % 2 !== 0}"
      >
        <img
          :src="'https://backend.chesnok.kz/storage/' + ad.image"
          alt="Ad Image"
          style="width: 100%; border-radius: 25px;"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const ads = ref([]);

onMounted(async () => {
  try {
    const response = await axios.get('https://backend.chesnok.kz/api/getadverse/3');
    ads.value = response.data;
  } catch (error) {
    console.error("Ошибка при получении рекламы:", error);
  }
});
</script>

<style scoped>
.first{
  padding-left: 0px !important;
}
.second{
  padding-right: 0px;
}
.adversity {
  margin-top: 1%;
  height: 21vh;
  background-color: whitesmoke;
}

.custom-grid {
  display: flex;
  flex-wrap: wrap;
}

.adversity-block {
  flex-basis: 100%;
  /* занимает всю ширину в мобильной версии */
  margin-bottom: 4px;
  /* Добавляем немного отступа между блоками */
}

/* Медиа-запрос для экранов больше 600px */
@media (min-width: 600px) {
  .adversity-block {
    flex-basis: calc(100% - 4px);
    /* каждый блок занимает половину ширины, учитывая отступ */
  }
}

@media (max-width: 900px) {

  .first{
  padding-left: 0px !important;
  padding-right: 0px !important;

}
.second{
  padding-left: 0px !important;

  padding-right: 0px !important;
}


  .adversity-block {
    flex-basis: calc(100% - 4px);
    /* каждый блок занимает половину ширины, учитывая отступ */
  }

  .adversity {
    margin-top: 1%;
    height: 11vh;
    background-color: whitesmoke;
  }
}
</style>
