
<template>
  <div class="container">
    <div class="row">
      <col-12>
        <h3>{{ $t("USER_REQUESTS") }}</h3>
      </col-12>
      <col-12>
        <div class="product" style="cursor: pointer" v-for="item in requests">
          <div>
            <p>
              Название заявки - {{ item.title }}.
              <i style="color: gray">
                на - {{ formatDate(item.pivot.updated_at) }}</i
              >
            </p>
          </div>
          <div>
            <p>Локация - {{ item.city }}</p>
          </div>

          <div>
            <p>Описание - {{ item.description }}</p>
          </div>
          <div>
            <p>
              Контакты заказчика будут доступны после покупки заявки, стоимость
              - 1000 тг
            </p>
          </div>
          <div>
            <a
              class="btn btn-info"
              @click="paycontact(item.id)"
              v-if="item.pivot.is_paid == false"
              >{{ $t("BUY_CONTACT") }}
            </a>
            <div v-else>
              <a
                class="btn btn-info"
                v-if="item.phone"
                :href="'tel:' + item.phone"
                >{{ item.phone }}</a
              >
              <a class="btn btn-info" v-else>Номер телефона не указан</a>
            </div>
          </div>
        </div>
      </col-12>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "App",
  components: {},
  data() {
    return {
      requests: [],
    };
  },
  created() {
    if (!localStorage.getItem("isLogged")) {
      document.location.href = "/";
      exit;
    }
    this.fetchRequests();
  },
  methods: {
    async fetchRequests() {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.chesnok.kz/api/offers",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.requests = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(dateString) {
      const months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];

      const date = new Date(dateString);
      const day = date.getUTCDate();
      const month = months[date.getUTCMonth()];
      const year = date.getUTCFullYear();

      return `${day} ${month} ${year} года`;
    },
    paycontact(id) {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.chesnok.kz/api/payforoffer",
          {
            id: id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          if (response.data.code == 1) {
            alert("Операция успешно проведена");
          } else {
            alert(
              "К сожалению на вашем счету недостаточно средств, для проведения данной операции"
            );
          }
          this.fetchRequests();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.galery {
  background-color: antiquewhite;
  padding: 3%;
}

.container {
  min-height: 90vh;
}

.itemGalery {
  width: 100%;
}

@import "~bootstrap/dist/css/bootstrap.css";
</style>