<template>
  <div class="desktop-view" id="showdesktopview">
    <ShowDesktopView />
  </div>
  <div class="mobile-view" id="showmobileview">
    <ShowMobileView />
  </div>
</template>

<script>
import axios from "axios";
import ShowDesktopView from "../views/ShowDesktopView.vue";
import ShowMobileView from "../views/ShowMobileView.vue";


export default {
  data() {
    return {

    };
  },
  components: {
    ShowDesktopView,
    ShowMobileView
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped>
/* Скрыть на десктопах */
.mobile-view {
  display: none;
}

/* Скрыть на мобильных устройствах */
.desktop-view {
  display: none;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 768px) {
  .mobile-view {
    display: block;
    /* Показать на мобильных устройствах */
  }

  .desktop-view {
    display: none;
    /* Скрыть на мобильных */
  }
}

/* Медиа-запрос для десктопов */
@media (min-width: 769px) {
  .desktop-view {
    display: block;
    /* Показать на десктопах */
  }

  .mobile-view {
    display: none;
    /* Скрыть на десктопах */
  }
}
</style>
