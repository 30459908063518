<template>
  <div
    class="container-fluid mb-5 cabartistbloc"
    style="background-color: aliceblue"
  >
    <div class="container">
      <div class="row">
        <Cabartistbar></Cabartistbar>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="pb-4">
      <div class="row">
        <div class="col-10">
          <input
            type="text"
            v-model="search"
            class="form-control"
            placeholder="Поиск..."
          />
        </div>
        <div class="col-2" style="text-align: right">
          <button
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <b>+</b>
          </button>
        </div>
      </div>
    </div>

    <div
      v-for="(profile, index) in filteredProfiles"
      :key="index"
      class="profile-item"
    >
      <div class="row">
        <div class="col-3 col-md-1">
          <img
            :src="
              'https://backend.chesnok.kz/storage/' + profile.avatar
            "
            style="width: 100%"
            alt=""
          />
        </div>
        <div class="col-8 col-md-9">
          <p>{{ profile.name }}</p>
          <p style="color: red" v-if="!profile.ischecked">На модерации!</p>
          <p style="color: rgb(70, 64, 64)" v-if="profile.rating">
            Рейтинг: {{ profile.rating }} из 100
          </p>
        </div>
        <div class="col-1 col-md-2 p-1">
          <div class="dropdown">
            <a
              class=""
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="m-2 bi bi-list"></i>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li v-if="profile.type != '1'">
                <a class="dropdown-item" :href="'/cabinet/edit/' + profile.id"
                  >Редактировать</a
                >
              </li>
              <li v-else>
                <a
                  class="dropdown-item"
                  :href="'/cabinet/editfood/' + profile.id"
                  >Редактировать</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  :href="'/cabinet/editcalendar/' + profile.id"
                  >Календарь</a
                >
              </li>
              <li>
                <button
                  class="dropdown-item"
                  @click="deleteProfile(profile.id)"
                >
                  Удалить
                </button>
              </li>
              <li>
                <button
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#adds"
                  @click="selectedProfile = profile"
                >
                  Рекламировать
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      v-for="(profile, index) in filteredProfiles"
      :key="index"
      class="mobile-profiles m-0 p-0"
      style="padding-right: 4% !important"
    >
      <div class="row">
        <div class="col-4 col-md-1">
          <img
            :src="
              'https://backend.chesnok.kz/storage/' + profile.avatar
            "
            style="width: 100%"
            alt=""
          />
          <div
            style="
              background-color: red;
              height: 3vh;
              text-align: center;
              padding-bottom: 2vh !important;
            "
            class=""
            v-if="!profile.ischecked"
          >
            <p style="font-size: 8pt; color: white">На модерации</p>
          </div>
        </div>
        <div class="col-8">
          <div class="row">
            <div
              class="col-10 p-1"
              style="
                background-color: #7fccf4;
                color: white;
                font-size: 8pt;
                padding-left: 3vw !important;
              "
            >
              Категория: {{ profile.category_name }}
            </div>
            <div class="col-2 p-0">
              <div
                class="p-1"
                style="
                  border-left: 3px solid white;
                  text-align: center;
                  background-color: #787878;
                "
              >
                <div class="dropdown" style="">
                  <a
                    class=""
                    type="button"
                    style=""
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="m-2 bi bi-list" style="color: white"></i>
                  </a>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li v-if="profile.type != '1'">
                      <a
                        class="dropdown-item"
                        :href="'/cabinet/edit/' + profile.id"
                        >Редактировать</a
                      >
                    </li>
                    <li v-else>
                      <a
                        class="dropdown-item"
                        :href="'/cabinet/editfood/' + profile.id"
                        >Редактировать</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        :href="'/cabinet/editcalendar/' + profile.id"
                        >Календарь</a
                      >
                    </li>
                    <li>
                      <button
                        class="dropdown-item"
                        @click="deleteProfile(profile.id)"
                      >
                        Удалить
                      </button>
                    </li>
                    <li>
                      <button
                        class="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#adds"
                        @click="selectedProfile = profile"
                      >
                        Рекламировать
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <p class="mt-1 mb-0">
            <b>{{ profile.name }}</b>
          </p>
          <p
            style="color: rgb(70, 64, 64); font-size: 8pt"
            v-if="profile.rating"
          >
            <b>Рейтинг:</b> {{ profile.rating }} из 100
          </p>
        </div>
        <div class="col-1 col-md-2 p-1"></div>
      </div>
    </div>

    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li
          class="page-item"
          :class="{ disabled: currentPage === 0 }"
          @click="currentPage--"
        >
          <a class="page-link" href="#" style="color: #223971 !important"
            >Предыдущая</a
          >
        </li>
        <li
          class="page-item"
          v-for="page in pageCount"
          :key="page"
          :class="{ active: page === currentPage + 1 }"
          @click="currentPage = page - 1"
        >
          <a
            class="page-link"
            href="#"
            style="color: rgb(129, 128, 128) !important"
            >{{ page }}</a
          >
        </li>
        <li
          class="page-item"
          :class="{ disabled: currentPage === pageCount - 1 }"
          @click="currentPage++"
        >
          <a class="page-link" href="#" style="color: #223971 !important"
            >Следующая</a
          >
        </li>
      </ul>
    </nav>
  </div>

  <div
    class="modal fade"
    id="adds"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style="width: 100% !important; position: fixed; left: 0px"
  >
    <div class="modal-dialog" style="width: 100% !important">
      <div class="modal-content" style="width: 100% !important">
        <div class="modal-header" style="width: 100% !important">
          <h5 class="modal-title" id="exampleModalLabel">
            Платные услуги Chesnok.kz
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="width: 100% !important">
          <form @submit.prevent="addProfile" style="width: 100% !important">
            <div class="mb-3" style="width: 100% !important">
              <div v-for="(parameter, index) in parameters" :key="index">
                <p
                  v-if="parameter.name == 'recommendation_amount'"
                  style="font-size: 10pt"
                >
                  Стоимость попадания в блок "Рекомендации", на главной -
                  {{ parameter.value }} тенге
                </p>
                <p
                  v-if="parameter.name == 'top_amount'"
                  style="font-size: 10pt"
                >
                  Стоимость поднятия в топ выдачи - {{ parameter.value }} тенге
                </p>
                <p
                  v-if="parameter.name == 'highlight_amount'"
                  style="font-size: 10pt"
                >
                  Стоимость "Выделения" анкеты в выдаче -
                  {{ parameter.value }} тенге
                </p>
              </div>
              <label for="profileType" class="form-label">Тип услуги</label>
              <select class="form-select" id="profileType" v-model="typepay">
                <option selected disabled>Выберите...</option>
                <option
                  value="recommendation_amount"
                  v-if="selectedProfile.rek == null"
                >
                  В рекомендации
                </option>
                <option value="top_amount" v-if="selectedProfile.top == null">
                  В топ выдачи
                </option>
                <option
                  value="highlight_amount"
                  v-if="selectedProfile.alloted == null"
                >
                  Выделить анкету
                </option>
              </select>
            </div>
            <p v-if="typepay">
              Стоимость:
              <span v-for="(par, index) in parameters" :key="index">
                <span v-if="par.name == typepay">{{ par.value }} тенге</span>
              </span>
            </p>
            <p v-if="typepay">Продолжительность: 7 дней</p>
            <a class="btn btn-info" @click="pay()" v-if="typepay">Применить</a>
          </form>
        </div>
        <div class="modal-body">
          <hr />
          <p style="color: orangered">
            Внимание, при продлении уже существующей услуги, оставшееся время
            обнуляется
          </p>
          <p v-if="selectedProfile.alloted != null">
            Выделен до {{ selectedProfile.alloted }}
          </p>
          <p v-else>Не выделено</p>

          <p v-if="selectedProfile.top != null">
            В топе до {{ selectedProfile.top }}
          </p>
          <p v-else>Не в топе</p>

          <p v-if="selectedProfile.rek != null">
            В рекомендациях до {{ selectedProfile.rek }}
          </p>
          <p v-else>Нет в рекомендациях</p>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Новый профиль</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="addProfile">
            <div class="mb-3">
              <label for="profileType" class="form-label">Тип профиля</label>
              <select class="form-select" id="profileType" v-model="type">
                <option selected disabled>Выберите...</option>
                <option value="1">Общепит</option>
                <option value="null">Шоубизнес</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="nameField" class="form-label">Имя / Название</label>
              <input
                type="text"
                class="form-control"
                id="nameField"
                v-model="name"
              />
            </div>
            <div class="mb-3">
              <label for="phoneField" class="form-label">Телефон</label>
              <input
                type="text"
                class="form-control"
                id="phoneField"
                v-mask="'+7(###)###-##-##'"
                v-model="mobile"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Закрыть
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Сохранить
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import Cabartistbar from "@/components/Cabartistbar.vue";
export default {
  setup() {

    

    if (!localStorage.getItem("isLogged")) {
      document.location.href = "/";
      exit;
    }

    const profiles = ref([]);
    const selectedProfile = ref([]);
    const parameters = ref([]);
    const search = ref("");
    const currentPage = ref(0);
    const perPage = ref(5);
    const route = useRoute();
    const name = ref(""); // variable for the name field
    const mobile = ref(""); // variable for the mobile field
    const type = ref(""); // variable for the profile type field
    const typepay = ref(""); // variable for the profile type field
    const showConfirmationTop = ref(false);
    const showConfirmationHighlight = ref(false);
    const showRek = ref(false);
    const selectedId = ref(null);
    const addToTop = async (id) => {
      showConfirmationTop.value = true;
      selectedId.value = id;
      console.log("Добавление в топ!", id);
    };
    const highlightItem = async (id) => {
      showConfirmationHighlight.value = true;
      selectedId.value = id;
      console.log("Выделение!", id);
    };
    const rekItem = async (id) => {
      showRek.value = true;
      selectedId.value = id;
      console.log("Добавление в реки!", id);
    };
    const fetchProfiles = async () => {
      let token = localStorage.getItem("token");
      try {
        const response = await axios.post(
          "https://backend.chesnok.kz/api/profiles",
          {
            id: route.params.id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        profiles.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };
    const pay = async () => {
      if (typepay.value == "recommendation_amount") {
        axios
          .post(
            "https://backend.chesnok.kz/api/updateRekDate",
            { id: selectedProfile.value.id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            if (response.status == 200) {
              alert("Успех");
              document.location.reload();
            } else if (response.status == 201) {
              alert("Недостаточно средств");
            }
          })
          .catch((error) => {});
      } else if (typepay.value == "top_amount") {
        axios
          .post(
            "https://backend.chesnok.kz/api/updateTopDate",
            { id: selectedProfile.value.id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            if (response.status == 200) {
              alert("Успех");
              document.location.reload();
            } else if (response.status == 201) {
              alert("Недостаточно средств");
            }
          })
          .catch((error) => {});
      } else if (typepay.value == "highlight_amount") {
        axios
          .post(
            "https://backend.chesnok.kz/api/updateAllotedDate",
            { id: selectedProfile.value.id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            if (response.status == 200) {
              alert("Успех");
              document.location.reload();
            } else if (response.status == 201) {
              alert("Недостаточно средств");
            }
          })
          .catch((error) => {});
      }
      let token = localStorage.getItem("token");
      try {
        const response = await axios.post(
          "https://backend.chesnok.kz/api/profiles",
          {
            id: route.params.id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        profiles.value = response.data;
      } catch (error) {
        console.log(error);
      }
    };
    const fetchAmaounts = async () => {
      let token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          "https://backend.chesnok.kz/api/parameters",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        parameters.value = response.data;
        console.log(response.data);
        console.log(parameters.value);
      } catch (error) {
        console.log(error);
      }
    };
    const addProfile = async () => {
      let token = localStorage.getItem("token");
      try {
        const response = await axios.post(
          "https://backend.chesnok.kz/api/addprofile",
          {
            name: name.value,
            mobile: mobile.value,
            type: type.value,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        // Assuming you're using bootstrap 5, this will close the modal.
        // const myModal = new bootstrap.Modal(document.getElementById('exampleModal'));
        // myModal.hide();
        alert("Профиль успешно создан");
        fetchProfiles();
      } catch (error) {
        // const myModal = new bootstrap.Modal(document.getElementById('exampleModal'));
        // myModal.hide();
        alert("Произошла ошибка");
        console.log(error);
        fetchProfiles();
      }
    };
    const deleteProfile = async (id) => {
      let token = localStorage.getItem("token");
      try {
        const response = await axios.post(
          "https://backend.chesnok.kz/api/delprofile",
          {
            id: id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        alert("Профиль успешно удален");
        fetchProfiles();
      } catch (error) {
        alert("Произошла ошибка");
        console.log(error);
        fetchProfiles();
      }
    };
    const filteredProfiles = computed(() => {
      const start = currentPage.value * perPage.value;
      const end = start + perPage.value;
      const searchTerm = search.value.toLowerCase(); // Convert search term to lowercase
      return profiles.value
        .filter((profile) => profile.name.toLowerCase().includes(searchTerm)) // Convert profile name to lowercase for case-insensitive search
        .slice(start, end);
    });
    const pageCount = computed(() => {
      const searchTerm = search.value.toLowerCase(); // Convert search term to lowercase
      return Math.ceil(
        profiles.value.filter((profile) =>
          profile.name.toLowerCase().includes(searchTerm)
        ).length / perPage.value
      );
    });
    fetchProfiles();
    fetchAmaounts();
    return {
      profiles,
      search,
      currentPage,
      perPage,
      filteredProfiles,
      pageCount,
      fetchProfiles,
      pay,
      fetchAmaounts,
      addProfile,
      deleteProfile,
      name,
      mobile,
      type,
      typepay,
      addToTop,
      highlightItem,
      rekItem,
      showConfirmationTop,
      showConfirmationHighlight,
      showRek,
      selectedId,
      parameters,
      selectedProfile,
    };
  },
  components: { Cabartistbar },
};
</script>
<style scoped>
.active .page-link {
  background-color: #223971 !important;
  /* This sets the background of the active page to red */
  border-color: #223971 !important;
  /* This sets the border color of the active page to red */
  color: white !important;
  /* This sets the text color of the active page to white for better contrast */
}

.profile-item {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
}

.mobile-profiles {
  display: none;
}

@media (max-width: 980px) {
  .mobile-profiles {
    display: block;
  }

  .profile-item {
    display: none;
  }

  .cabartistbloc {
    background: none !important;
  }
}
</style>