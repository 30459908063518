<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5">
        <form @submit.prevent="onSubmit">
          <div class="form-group position-relative p-2">
            <select id="city-select" v-model="selectedCities"
              class="form-control rounded-0 pl-5 thicker-border smaller-placeholder"
              style="height: 50px; border-radius: 12px !important;" required>
              <option value="" disabled selected>Города</option>
              <option v-for="city in cities" :value="city.id" :key="city.id">{{ city.city_name }}</option>
            </select>
          </div>

          <div class="form-group position-relative p-2">
            <select id="category-select" v-model="selectedCategories"
              class="form-control rounded-0 pl-5 thicker-border smaller-placeholder p-2"
              style="height: 50px; border-radius: 12px !important;" required>
              <option value="" disabled selected>Категории</option>
              <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
            </select>
          </div>

          <div class="form-group p-2">
            <textarea  id="search-input" v-model="search" type="text"
              class="form-control rounded-0 thicker-border smaller-placeholder" placeholder="Поиск"
              style="height: 50px; border-radius: 12px !important; height: 100px;" />
          </div>

          <div class="form-group p-2">
            <button type="submit" class="btn rdbtn mt-2"
              style="margin-bottom: 10px;  border: none !important">Найти</button>

          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      search: '',
      selectedCities: '',
      selectedCategories: '',
      cities: [],
      categories: [],
    }
  },
  async created() {
    try {
      const citiesResponse = await axios.get('https://backend.chesnok.kz/api/getCities')
      this.cities = citiesResponse.data

      const categoriesResponse = await axios.get('https://backend.chesnok.kz/api/categories')
      this.categories = categoriesResponse.data
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    
    onSubmit() {
      // Приведение текста поискового запроса в нижний регистр
      const searchQueryLowerCase = this.search.toLowerCase();

      this.$emit('search', {
        search: searchQueryLowerCase,
        selectedCities: this.selectedCities,
        selectedCategories: this.selectedCategories,
      })
    },
  },
}
</script>

<style scoped>
.smaller-placeholder {
  font-size: 0.8em;
  /* Размер шрифта для всего элемента */
}


/* Стили для серого placeholder в input */
input::placeholder {
    color: gray !important;
}


/* Стили для серого placeholder в input */
textarea::placeholder {
    color: gray !important;
}

/* Стили для серого placeholder в select */
select:invalid {
    color: gray !important;
}

/* Для того чтобы другие выбранные элементы в select не были серыми */
select:not(:invalid) {
    color: black !important;
}

.thicker-border {
  border-width: 2px;
}

.text-turquoise {
  color: #40e0d0;
}

.rdbtn {
  background-color: #cd0b0b !important;
  margin-top: 30px;
  width: 100%;
  color: #fff;
  white-space: nowrap;
  padding: 15px 60px;
  font: 500 18px/89% Montserrat, sans-serif;
}</style>
