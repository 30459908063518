<template>
  <div class="container p-0">
    <div class="div mb-4 m-0 p-0" >
      <div class="div-2 p-0 m-0">
        <!-- чеснок рекомендует -->
        <span>{{ $t("CHESNOK_RECOMMENDS") }}</span> 
      </div>
    </div>
    <div class="row" >
      <div
        v-for="item in paginatedItems"
        :key="item.id"
        class="col-6 col-md-3 p-2"
        style="cursor: pointer"
        @click="go(item)"
      >
        <!-- <div class=" details-button">
          <span v-for="(star, index) in 5" :key="index" class="star"
            :class="index < item.starrate ? 'gold' : 'grey'">★</span>
        </div> -->
        <div class="image-wrapper">
          <img
            :src="'https://backend.chesnok.kz/storage/' + item.avatar"
            style="border-radius: 10%"
            class="product-image"
            alt="Product image"
          />

          <div class="product-info pt-3">
            <h5
              style="font: 600 17px Montserrat, sans-serif"
              class="nameartist"
            >
              {{ item.name }}
            </h5>

            <p
              class="price-text fee"
              style="font-family: Montserrat, sans-serif"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/df7b540c24999b7db31bd581337f6357f667f49685117b4cb91e9684492ea096?"
                class="img img-i-for-white"
              />
              <img
                loading="lazy"
                src="../../src/assets/i.png"
                class="img img-i-for-black"
              />
              {{ getPriceText(item) }}
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      items: [],
      isLoading: true,
      city: "",
      currentPage: 1,
      itemsPerPage: 8,
    };
  },
  methods: {
    formatPrice(price) {
      return Number(price).toLocaleString("ru-RU");
    },

    getPriceText(item) {
      if (!item.hideprice) {
        if (item.from == null && item.before == null) {
          return this.$t("FEE_ON_REQUEST");
        } else if (item.from != null && item.before == null) {
          return `Гонорар от ${this.formatPrice(item.from)} ₸`;
        } else if (item.from == null && item.before != null) {
          return `Гонорар до ${this.formatPrice(item.before)} ₸`;
        } else if (item.from != null && item.before != null) {
          return (
            this.$t("FEE") +
            ` (${this.formatPrice(item.from)} ₸ - ${this.formatPrice(
              item.before
            )} ₸)`
          );
        }
      } else {
        return this.$t("FEE_ON_REQUEST");
      }
    },

    go(profile) {
      if (profile.type != "1") {
        this.$router.push("/show/" + profile.id + "#top");
      } else {
        this.$router.push("/show/food/" + profile.id);
      }
    },

    getCategoryInfo() {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.chesnok.kz/api/getInfoWithCity",
          {
            id: this.city.id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.isLoading = false;
          this.items = response.data;

          this.items.forEach((item) => {
            if (item.reviews && Array.isArray(item.reviews)) {
              let totalRate = 0;
              let totalReviews = item.reviews.length;
              item.reviews.forEach((review) => {
                totalRate += review.rate;
              });
              item.starrate =
                totalReviews > 0 ? Math.ceil(totalRate / totalReviews) : 0;
            } else {
              item.starrate = 0;
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },

  computed: {
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.items.slice(startIndex, endIndex);
    },

    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },

  created() {
    this.city = JSON.parse(localStorage.getItem("selectedCity"));
    this.getCategoryInfo();
  },
};
</script>

<style scoped>
body {
  font-family: Montserrat, sans-serif !important;
}
.img-i-for-black {
  display: none !important;
}

.img-i-for-white {
  display: inline;
}

body.dark-theme .img-i-for-white {
  display: none !important;
}

body.dark-theme .img-i-for-black {
  display: inline !important;
}
.details-button {
  position: absolute;
  bottom: 10px;
  right: 20px;
  background-color: rgba(128, 128, 128, 0.5);
  /* Полупрозрачный серый цвет */
  color: white;
  border: none;
  border-radius: 20px;
  padding: 2px 4px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
}

.title {
  height: 10vh;
  background-color: #223971;
  border-top: 10px solid #7fccf4;
  border-bottom: 10px solid #7fccf4;
}

.product {
  margin-bottom: 2em;
  padding: 1em;
  box-shadow: 0px 4px 4px 4px #00000040;
  overflow: hidden;
}

.star {
  font-size: 27.2px;
}

.gold {
  color: #7fccf4 !important;
}

.product:hover {
  box-shadow: 0 2px 5px rgba(32, 117, 214, 0.3);
}

.image-wrapper {
  position: relative;
}

.product-info {
  text-align: left;
}

.top-badge {
  position: absolute;
  top: 4%;
  right: 4%;
}

.product-image {
  width: 100%;
  /* Изменено на 100% */
  height: auto;
  /* Изменено с height: 100% на auto для сохранения пропорций */
  aspect-ratio: 1 / 1;
  /* Добавлено для обеспечения квадратного аспекта */
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 1px 1px 1px #00000040;
}

.div-2 span{
    font-size: 1.4rem !important;
    font-weight: 500;
  }

@media (max-width: 900px) {
  .star {
    font-size: 17.2px;
  }

  .product-image {
    width: 100%;
    /* Изменено на 100% */
    height: 200px;
    /* Изменено с height: 100% на auto для сохранения пропорций */
    aspect-ratio: 1 / 1;
    /* Добавлено для обеспечения квадратного аспекта */
    object-fit: cover;
    object-position: center;
    box-shadow: 0px 1px 1px 1px #00000040;
  } 

}
</style>
