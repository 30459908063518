<template>
    <div class="row  p-0 m-0" v-if="user.name">
        <!-- Использование d-flex и align-items-center для центрирования по вертикали -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 ">
            <div class="boxcabartistbar p-2 w-100">
                <p>{{ $t('HELLO') }}, {{ user.name }}</p>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="boxcabartistbar p-2 w-100">
                <div class="row">
                    <div class="col-7">
                        <p>{{ $t('YOUR_BALANCE') }}: {{ user.money }} Тенге</p>
                    </div>
                    <div class="col-5">
                        <button type="button" class="btn btn-info refill" data-bs-toggle="modal"
                            style="width: 100%; border-radius: 0px; font-size: 1.1vw; background-color: #7fccf4 !important; border: none !important"
                            data-bs-target="#myModal">{{ $t('REFILL_BALANCE') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" id="myModal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Введите сумму</h5>

                </div>
                <div class="modal-body">
                    <div class="row">
                        <!-- <input type="text" class="form-control m-1" id="nameField" placeholder="Имя владельца"
                            v-model="cardHolder">
                        <input type="text" class="form-control m-1" id="nameField" placeholder="Номер карты"
                            v-model="cardNumber">
                        <input type="text" class="form-control m-1" id="nameField" placeholder="Срок действия"
                            v-model="expiryDate"> -->
                        <input type="text" class="form-control m-1" id="nameField" placeholder="Сумма к оплате"
                            v-model="amount">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="getMoney">Подтвердить</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'App',
    data() {
        return {
            user: [], // Add notifications array
            loggedIn: false,
            amount: null,
            cardNumber: '',
            cardHolder: '',
            expiryDate: '',
            cvv: '',
        }
    },

    created() {
        this.loggedIn = localStorage.getItem('isLogged');

        this.getUserInfo();
    },
    methods: {
        getMoney() {
            let token = localStorage.getItem('token');

            // axios.post('https://backend.chesnok.kz/api/getMoney', {
            //     amount: this.amount,
            // }, { headers: { 'Authorization': `Bearer ${token}` } })
            //     .then(response => {
            //         // this.userInfo();
            //         this.cardHolder = '';
            //         this.cardNumber = '';
            //         this.cvv = '';
            //         this.expiryDate = '';
            //         // window.location.reload();
            //     }).catch(error => {
            //         console.log(error);
            //     })


            axios.post('https://backend.chesnok.kz/api/createTransaction', {
                amount: this.amount,
            }, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(response => {
                    window.location = response.data;
                }).catch(error => {
                    console.log(error);
                })


            this.showDialog = false;
        },
        getUserInfo() {
            console.log('ииииу')
            console.log(this.loggedIn)
            if (this.loggedIn == 'true') {
                console.log('Истина')
                let token = localStorage.getItem('token');

                axios.post('https://backend.chesnok.kz/api/me', {
                }, { headers: { 'Authorization': `Bearer ${token}` } })
                    .then(response => {
                        this.user = response.data;
                    }).catch(error => {
                    })
            } else {
                console.log('вышли')
            }

        },
    }
}
</script>

<style>
.boxcabartistbar {
    background-color: #f2f2f2;
}

.boxcabartistbar p {
    font-size: 1.2vw;
}

@media (max-width: 980px) {
    .boxcabartistbar p {
        font-size: 3.2vw;
        color: #223971;
        font-weight: bold;
    }
    .boxcabartistbar{
        margin: 1px ;
    }
    .refill{
        font-size: 2.4vw !important;
    }
}
</style>
