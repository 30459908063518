<template>
    <div>
        <h4 class="mb-4" v-if="city.id != 0">Лидеры рейтинга в категории "Ведущие" для города {{ city.city_name }}</h4>
        <h4 class="mb-4" v-else>Лидеры рейтинга в категории "Ведущие" в Казахстане</h4>

        <div v-for="item in paginatedItems" :key="item.id" class="product" style="cursor: pointer;" @click="go(item)">
            <div>
                <h5 style="margin: 0px 0px 5px 0px;">{{ item.name }}</h5>
                <div v-if="!item.hideprice">
                    <div v-if="item.from == null && item.before == null">
                        <p style="color: gray">Гонорар по запросу</p>
                    </div>
                    <div v-if="item.from != null && item.before == null">
                        <p style="color: gray">Гонорар от {{ item.from }}</p>
                    </div>
                    <div v-if="item.from == null && item.before != null">
                        <p style="color: gray">Гонорар до {{ item.before }}</p>
                    </div>
                    <div v-if="item.from != null && item.before != null">
                        <p style="color: gray">Гонорар ({{ item.from }} - {{ item.before }})</p>
                    </div>

                </div>
                <div class="star-rating">
                    <span v-for="(star, index) in 5" :key="index" class="star"
                        :class="index < item.starrate ? 'gold' : 'grey'">
                        ★
                    </span> <span v-if="item.reviews.length > 0">количество отзывов - {{ item.reviews.length }}</span>
                </div>
            </div>
            <div class="image-wrapper">
                <div v-for="n in 4" :key="n" class="image-container">
                    <img v-if="item.images[n - 1]"
                        :src="'https://backend.chesnok.kz/storage/' + item.images[n - 1].path"
                        class="product-image" alt="Product image" />
                    <img v-else src="../assets/logo.png" class="product-image" alt="Demo image" />
                </div>
            </div>
        </div>
        <!-- <nav v-if="totalPages > 1" aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <a class="page-link" href="#" aria-label="Previous" @click="changePage(currentPage - 1)">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
                    <a class="page-link" href="#" @click="changePage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <a class="page-link" href="#" aria-label="Next" @click="changePage(currentPage + 1)">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav> -->
    </div>
</template>
<script>
import axios from "axios";

export default {
    data() {
        return {
            items: [],
            isLoading: true,
            city: "",
            currentPage: 1,
            itemsPerPage: 2, // Change this value according to your needs
        };
    },
    methods: {
        go(profile) {
            if (profile.type != '1') {
                this.$router.push('/show/' + profile.id)
            } else {
                this.$router.push('/show/food/' + profile.id)

            }
        },
        getCategoryInfo() {
            let token = localStorage.getItem("token");
            axios
                .post(
                    // "https://backend.chesnok.kz/api/getCategoryInfo",
                    "https://backend.chesnok.kz/api/topToaster",
                    {
                        id: this.city.id,
                    },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                .then((response) => {
                    this.isLoading = false;
                    this.items = response.data;


                    this.items.forEach((item) => {
                        if (item.reviews && Array.isArray(item.reviews)) {
                            let totalRate = 0;
                            let totalReviews = item.reviews.length;
                            item.reviews.forEach((review) => {
                                totalRate += review.rate;
                            });
                            item.starrate = totalReviews > 0 ? Math.ceil(totalRate / totalReviews) : 0;
                        } else {
                            item.starrate = 0;
                        }
                    });




                })
                .catch((error) => {
                    console.error(error);
                });
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
    },
    computed: {
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.items.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.items.length / this.itemsPerPage);
        },
    },
    created() {
        this.city = JSON.parse(localStorage.getItem("selectedCity"));
        this.getCategoryInfo();


    },
};
</script>
<style scoped>
.star {
    font-size: 1.5em;
    /* измените размер звезд по вашему усмотрению */
    color: grey;
}

.star.gold {
    color: gold;
}
.product {
    margin-bottom: 2em;
    padding: 1em;
    border: 1px solid #ccc;
    /* рамка */
    border-radius: 10px;
    /* закругления */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* тени */
    overflow: hidden;
    /* чтобы закругления были видны */
}

.product:hover {
    box-shadow: 0 2px 5px rgba(32, 117, 214, 0.3);
}

.product .image-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* Добавлено свойство flex-wrap для переноса изображений на новую строку */
}

.image-container {
    width: 23%;
    /* Уменьшена ширина контейнера для изображений */
    height: 20vh;
    margin: 10px;
    /* Добавлен отступ между изображениями */
    position: relative;
    overflow: hidden;
}

@media (max-width: 768px) {
    .image-container {
        width: 41%;
        /* Половина ширины минус небольшой отступ */
    }
}

@media (max-width: 300px) {
    .image-container {
        width: 90%;
        /* Половина ширины минус небольшой отступ */
    }
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}</style>