<template>
  <div class="container mb-5">
    <div class="row">
      <div class="col-12 col-md-6 offset-md-3">
        <div class="header">
          <span @click="this.$router.push('/advancedsearch')" class="close">&larr;</span>
          <h2>Создать запрос</h2>
        </div>
        <form @submit.prevent="handleSubmit">
          <input
            id="title"
            placeholder="Заголовок"
            v-model="requestTitle"
            type="text"
            class="form-control mb-2 p-3 inputreq"
            required
          />

          <select
            id="category"
            class="form-control mb-2 p-3 inputreq"
            v-model="requestCategory"
            required
          >
            <!-- Вставьте здесь свои категории -->
            <option :value="category.id" v-for="category in categories">
              {{ category.name }}
            </option>
          </select>

          <input
            id="city"
            v-model="requestCity"
            placeholder="Категория"
            type="text"
            class="form-control mb-2 p-3 inputreq"
            required
          />

          <textarea
            id="description"
            class="form-control mb-2 p-3 inputreq"
            placeholder="Описание"
            v-model="requestDescription"
            required
          ></textarea>

          <input
            id="phone"
            placeholder="Телефон"
            v-model="requestPhone"
            type="text"
            class="form-control mb-2 p-3 inputreq"
            v-mask="'8(###)###-##-##'"
            required
          />

          <input
            placeholder="Дата события"
            id="estimated_date"
            v-model="estimatedDate"
            type="date"
            class="form-control mb-2 inputreq"
            required
          />
          <button
            @click="sendSearchRequest"
            class="mt-3 btn btn-primary w-100 p-3"
            style="background-color: #CD0B0B !important; font-size: 14pt; opacity: 1; border: none !important"
            :disabled="
              !requestTitle ||
              !requestCategory ||
              !requestCity ||
              !requestDescription ||
              !requestPhone
            "
          >
            Отправить запрос
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
  <script>
import axios from "axios";

export default {
  props: ["results", "isNicheNety"],
  data() {
    return {
      cities: [],
      categories: [],
      selectedCities: "",
      selectedCategories: "",
      estimatedDate: "",
      requestPhone: "",
      currentPage: 0,
      perPage: 5,
      showModal: false, // для управления модальным окном
      requestTitle: "", // для хранения вводимого заголовка
      requestCategory: "1", // для хранения выбранной категории
      requestCity: "", // для хранения выбранного города
      requestDescription: "", // для хранения вводимого описания
    };
  },
  computed: {
    paginatedData() {
      const start = this.currentPage * this.perPage;
      const end = start + this.perPage;
      return this.results.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.results.length / this.perPage);
    },
  },
  methods: {
    go(profile) {
      try {
        if (profile.type != "1") {
          this.$router.push("/show/" + profile.id);
        } else {
          this.$router.push("/show/food/" + profile.id);
        }
      } catch (error) {
        this.$router.push("/show/" + profile.id);
      }
    },
    changePage(page) {
      if (page >= 0 && page < this.pageCount) {
        this.currentPage = page;
      }
    },
    sendSearchRequest() {
      axios
        .post("https://backend.chesnok.kz/api/createRequestSearch", {
          title: this.requestTitle,
          category: this.requestCategory,
          city: this.requestCity,
          description: this.requestDescription,
          phone: this.requestPhone,
          estimated_date: this.estimatedDate,
        })
        .then((response) => {
          alert("Ваша заявка успешно зарегистрирована");
          this.showModal = false;
          this.requestTitle = "";
          this.requestCategory = "";
          this.requestCity = "";
          this.requestDescription = "";
        });
    },
    async getInfo() {
      try {
        const citiesResponse = await axios.get(
          "https://backend.chesnok.kz/api/getCities"
        );
        this.cities = citiesResponse.data;

        const categoriesResponse = await axios.get(
          "https://backend.chesnok.kz/api/categories"
        );
        this.categories = categoriesResponse.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    console.log("щас бущдет г ет инфо");
    this.getInfo();
  },
};
</script>

<style scoped>
#estimated_date::placeholder {
  color: #808080; /* Серый цвет текста плейсхолдера */
  opacity: 1; /* Для полного непрозрачного цвета */
}

/* Для Webkit-браузеров (Chrome, Safari, и т.д.) */
#estimated_date::-webkit-input-placeholder {
  color: #808080; /* Серый цвет текста плейсхолдера */
}

/* Для Firefox */
#estimated_date::-moz-placeholder {
  color: #808080; /* Серый цвет текста плейсхолдера */
}

/* Для Internet Explorer 10-11 */
#estimated_date:-ms-input-placeholder {
  color: #808080; /* Серый цвет текста плейсхолдера */
}

/* Для Microsoft Edge */
#estimated_date::-ms-input-placeholder {
  color: #808080; /* Серый цвет текста плейсхолдера */
}
#category {
  color: #808080; /* Серый цвет текста */
}

/* Стили для текста опций выпадающего списка */
#category option {
  color: #808080; /* Серый цвет текста */
}
.inputreq {
  border: 1px solid grey;
  margin-bottom: 10px !important;
}
.inputreq::placeholder {
  color: gray !important;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.icon {
  margin-right: 10px;
}

h2 {
  flex-grow: 1;
  text-align: center;
}
.star {
  font-size: 1.5em;
  /* измените размер звезд по вашему усмотрению */
  color: grey;
}

.star.gold {
  color: gold;
}

.container {
  min-height: 80vh;
}

.product {
  margin-bottom: 2em;
  padding: 1em;
  border: 1px solid #ccc;
  /* рамка */
  border-radius: 10px;
  /* закругления */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* тени */
  overflow: hidden;
  /* чтобы закругления были видны */
}

.product:hover {
  box-shadow: 0 2px 5px rgba(32, 117, 214, 0.3);
}

.product .image-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Добавлено свойство flex-wrap для переноса изображений на новую строку */
}

.image-containerrrsearch {
  width: 23%;
  /* Уменьшена ширина контейнера для изображений */
  height: 20vh;
  margin: 10px;
  /* Добавлен отступ между изображениями */
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .image-containerrrsearch {
    width: 41%;
    /* Половина ширины минус небольшой отступ */
  }
}

@media (max-width: 300px) {
  .image-containerrrsearch {
    width: 90%;
    /* Половина ширины минус небольшой отступ */
  }
}

.product-image {
  width: 100%;
  border-radius: 50px;
  /* Изменено на 100% */
  height: auto;
  /* Изменено с height: 100% на auto для сохранения пропорций */
  aspect-ratio: 1 / 1;
  /* Добавлено для обеспечения квадратного аспекта */
  object-fit: cover;
  object-position: center;
  box-shadow: 0px 1px 1px 1px #00000040;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>