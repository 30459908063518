<template>
  <div class="mobile-guid">
    <div class="mobile-menu-tabs-guide">
      <div class="tab-guide" @click="activateTab(1)" >Вы заказчик?</div>
      <div class="tab-guide" @click="activateTab(2)" >Вы профи?</div>
      <div class="tab-guide" @click="activateTab(3)" >О нас</div>
    </div>

    <div class="tab-content-guide" v-show="activeTab === 1">
      
    </div>




    <div class="tab-content-guide" v-show="activeTab === 2">
      
    </div>





    <div class="tab-content-guide" v-show="activeTab === 3">

      
    </div>





  </div>
</template>

<script>
export default {
  name: 'TabsPage',
  data() {
    return {
      activeTab: 1, // Изначально активен первый таб

    };
  },
  methods: {
    activateTab(tabNumber) {
      this.activeTab = tabNumber;
      switch (tabNumber) {
        case 1: 
          console.log('в первый таб юрл');
          document.location.href='/customer'
          break;
        case 2: 
          console.log('во второй таб юрл');
          document.location.href='/managers'

          break;
        case 3: 
          console.log('в третий таб юрл');
          document.location.href='/about'

          break;
      
        default:
          break;
      }
    },
  },
}
</script>

<style scoped>
.mobile-menu-tabs-guide {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.tab-guide {
  background-color: transparent;
  margin-right: 20px !important;

  /* Изменили цвет фона таба */
  color: white;
  cursor: pointer;
  font-size: 12pt;
  margin-right: 10px;
  font-weight: bold;
  /* Сделали текст таба жирным */
  border-bottom: 2px solid transparent;
  /* Добавили нижнее подчеркивание */
  /* transition: border-bottom-color 0.2s; */
  /* Добавили анимацию подчеркивания */
}

.tab-guide.active {
  background-color: transparent;
  color: #7ecae4;

}

.tab-content-guide {
  /* display: none; */
  /* margin-top: 1vh; */
  padding-right: 1.5vh;

  /* border-top: 3px solid rgb(177, 173, 173); */
  color: black;
}

.tab-content-guide.active {
  display: block;
}

.aboutp {
  font-size: 20.5pt;
  color: #484848;
  font-family: "Proxima Nova", sans-serif !important;

}

.card {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}</style>
