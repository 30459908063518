import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CustomerView from '../views/CustomerView.vue'
import ManagersView from '../views/Managers.vue'
import LoginView from '../views/LoginView.vue'
import RestoreView from '../views/RestoreView.vue'
import Adminloginjwt from '../views/AdminloginjwtView.vue'
import mLoginView from '../views/mLoginView.vue'
import mRegisterView from '../views/mRegisterView.vue'
import RegisterView from '../views/RegisterView.vue'
import NewsView from '../views/NewsView.vue'
import AboutView from '../views/AboutView.vue'
import SaleView from '../views/SaleView.vue'
import PageView from '../views/PageView.vue'
import CategorieslistingView from '../views/CategorieslistingView.vue'
import AdvancedsearchView from '../views/AdvancedsearchView.vue'
import CrateRequest from '../components/CrateRequest.vue'
import ShowView from '../views/ShowView.vue'
import ShowViewFood from '../views/ShowViewFood.vue'
import ProfileListview from '../views/cabinetartist/ProfilelistView.vue'
import EditcalendarView from '../views/cabinetartist/EditcalendarView.vue'
import EditprofileView from '../views/cabinetartist/EditprofileView.vue'
import OffersView from '../views/cabinetartist/OffersView.vue'
import EditprofileFoodView from '../views/cabinetartist/EditprofileFoodView.vue'
import SuccessView from '../views/SuccessView.vue'
import ErrorView from '../views/ErrorView.vue'

import ContactsView from '../views/ContactsView.vue'

import ShowNewsView from '../views/ShowNewsView.vue'


import ProfileviewsView from '../views/cabinetartist/ProfileviewsView.vue'
import EditaccountinfoView from '../views/cabinetartist/EditaccountinfoView.vue'
import MarketingView from '../views/cabinetartist/MarketingView.vue'

import RequestsProfileView from '../views/cabinetuser/RequestsView.vue'
import EdituserView from '../views/cabinetuser/EdituserView.vue'

import FavouritesView from '../views/cabinetuser/FavouritesView.vue'


import RequestsView from '../views/cabinetartist/RequestsView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/restore',
    name: 'restore',
    component: RestoreView
  },

  {
    path: '/adminloginjwt/:id/:token/:name/:avatar',
    name: 'adminloginjwt',
    component: Adminloginjwt
  },
  {
    path: '/mlogin',
    name: 'mlogin',
    component: mLoginView
  },
  {
    path: '/mregister',
    name: 'mregister',
    component: mRegisterView
  },
  {
    path: '/customer',
    name: 'customer',
    component: CustomerView
  },
  {
    path: '/managers',
    name: 'managers',
    component: ManagersView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },

  {
    path: '/news',
    name: 'news',
    component: NewsView
  },

  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/sale',
    name: 'sale',
    component: SaleView
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView
  },
  {
    path: '/categories/:id',
    name: 'categories',
    component: CategorieslistingView
  },
  {
    path: '/show/:id',
    name: 'show',
    component: ShowView
  },

  {
    path: '/news/:id',
    name: 'shownews',
    component: ShowNewsView
  },
  {
    path: '/show/food/:id',
    name: 'showfood',
    component: ShowViewFood
  },
  {
    path: '/page/:id',
    name: 'page',
    component: PageView
  },
  {
    path: '/advancedsearch/',
    name: 'advancedsearch',
    component: AdvancedsearchView
  },

  {
    path: '/cabinet/profileslist',
    name: 'profileslist',
    component: ProfileListview
  },
  {
    path: '/cabinet/editcalendar/:id',
    name: 'editcalendar',
    component: EditcalendarView
  },
  {
    path: '/cabinet/edit/:id',
    name: 'edit',
    component: EditprofileView
  },

  {
    path: '/cabinet/offers',
    name: 'Offers',
    component: OffersView
  },


  {
    path: '/success',
    name: 'success',
    component: SuccessView
  },
  {
    path: '/createRequest',
    name: 'createRequest',
    component: CrateRequest
  },

  {
    path: '/error',
    name: 'error',
    component: ErrorView
  },


  {
    path: '/cabinet/views/',
    name: 'views',
    component: ProfileviewsView
  },
  {
    path: '/cabinet/requests/',
    name: 'requests',
    component: RequestsView
  },
  {
    path: '/cabinet/editaccountprofile/',
    name: 'editaccountprofile',
    component: EditaccountinfoView
  },

  {
    path: '/cabinet/marketing/',
    name: 'marketing',
    component: MarketingView
  },


  {
    path: '/cabinet/myrequest/',
    name: 'myrequest',
    component: RequestsProfileView
  },



  {
    path: '/cabinet/bookmarks/',
    name: 'FavouritesView',
    component: FavouritesView
  },
  {
    path: '/cabinet/editaccountuser/',
    name: 'editaccountuser',
    component: EdituserView
  },
  {
    path: '/cabinet/editfood/:id',
    name: 'editfood',
    component: EditprofileFoodView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
