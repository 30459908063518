import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueTheMask from 'vue-the-mask';
import VueScrollTo from 'vue-scrollto';
import { createI18n } from 'vue-i18n';

// Импортируйте файлы перевода
import en from './assets/locales/en.json';
import kz from './assets/locales/kk.json';
import ru from './assets/locales/ru.json';

// Инициализация i18n
const i18n = createI18n({
  locale: 'ru', // установите начальный язык
  fallbackLocale: 'kk', // язык, на который переключиться, если не найден текущий
  messages: {
    en,
    kz,
    ru
  }
});

// Импорт стилей
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-next-select/dist/index.css';
import 'vue-select/dist/vue-select.css';
import 'dropzone-vue/dist/dropzone-vue.common.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// Инициализация jQuery
import jQuery from 'jquery';
global.jQuery = jQuery;
global.$ = jQuery;

// Создание и монтирование Vue приложения
createApp(App)
  .use(router)
  .use(VueTheMask)
  .use(VueScrollTo)
  .use(i18n) // Добавление i18n
  .mount('#app');
