<template>
  <div style="background-color: #f2f2f2" class="p-4" v-if="loggedIn">
    <div class="mb-3">
      <div class="title_review">
        <label for="comment" class="form-label">{{ $t('review') }}</label>
      </div>

      <div class="mb-3 p-0">
        <!-- <label class="form-label">{{ $t('grade') }}</label> -->
        <div class="p-0" style="text-align: center;">
          <button v-for="star in 5" :key="star" class="btn btn-star" style="border: none !important; font-size: 4rem;"
            :class="star <= rating ? 'btn-warning' : 'btn-outline-secondary'" @click="setRating(star)">
            ★
          </button>
        </div>
      </div>
      <div>
        <textarea style="" class="form-control textareacomment" id="comment" v-model="comment" rows="3"></textarea>
      </div>
    </div>

    <div style="text-align: center;">
      <button v-if="isCheck" class="btn btn-primary p-3" @click="submitReview" style="
       background-color: rgb(127,204,244) !important; border: none !important; border-radius: 2px;
       font-size: 1.4rem; width: 30%; font-weight: bold;">
        {{ $t('post_a_review') }}
      </button>
      <p v-else style="font-size: 0.7rem; color: gray" class="mt-1">Оставить отзыв вы сможете только после диалога с
        артистом</p>

    </div>


  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      comment: "",
      rating: 0,
      isCheck: false,
      comments: [],
    };
  },
  methods: {
    setRating(star) {
      this.rating = star;
    },
    submitReview() {
      let token = localStorage.getItem("token");

      axios
        .post(
          "https://backend.chesnok.kz/api/storereview",
          {
            profile_id: this.$route.params.id,
            rating: this.rating,
            comment: this.comment,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          alert("Отзыв успешно добавлен");
        })
        .catch((err) => {

          alert("Отзыв содержит запрещенные слова, мы не можем его разместить");

        });

      // TODO: Отправить обзор на сервер

      // Очистить форму после отправки
      this.comment = "";
      this.rating = 0;
    },
  },
  created() {
    // код, который нужно выполнить при создании компонента
    let token = localStorage.getItem("token");

    this.loggedIn = localStorage.getItem("isLogged");

    axios
      .post(
        "https://backend.chesnok.kz/api/getCommentProfile",
        {
          profile_id: this.$route.params.id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        this.comments = response.data;
      });

    axios
      .post(
        "https://backend.chesnok.kz/api/getCheckReview",
        {
          id: this.$route.params.id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        this.isCheck = response.data.status;
      });
  },
  mounted() {
    // код, который нужно выполнить после монтирования компонента в DOM
  },
};
</script>

<style scoped>
.textareacomment {
  border: 2px solid gray;
  width: 50%;
  margin-left: 25%;
  height: 210px;
  border-radius: 0px;
}

@media (max-width: 980px) {
  .textareacomment {
    border: 1px solid gray;
    width: 96%;
    margin-left: 2%;
    height: 130px;
    border-radius: 0px;
  }
  .btn-star{
    padding: 0px !important;
  }
}

.btn {
  margin-right: 5px;
}

.title_review {
  padding: 0px !important;
  margin: 0px !important;
  text-align: center;
  font-size: 18pt;
  font-weight: 800;
  color: gray;
}

.text-warning {
  color: black;
  /* 'черный' цвет для заполненных звезд */
}

.text-secondary {
  color: lightgray;
  /* 'светлый' цвет для незаполненных звезд */
}</style>
