<template>
  <div class="container p-0">
    <div class="div p-0">
      <div class="div-2">
        <!-- Специальное предложение -->
        <span>Скоро самые яркие события</span>
      </div>
      <img loading="lazy" 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/80231082d21146716fbffcf1dc7cc9f3b8c185e0011604b8696a52f72a7fb7c2?"
        class="img imgspecial" />
    </div>
    <div class="col-12 p-0 m-0 shadow-transition" v-if="carouselItems">
      <div class="mt-3 " style="">
        <div class="row p-1">
          <div v-for="item in carouselItems" :key="item.id" class="col-6 col-md-3 p-2" style="cursor: pointer;"
            @click="go(item.profile_id, item.id, item.profile_link)">
            <div class="image-wrapper"
              style="position: relative; width: 100%; padding-top: 100%; overflow: hidden;">
              <img :src="'https://backend.chesnok.kz/storage/' + item.image" class="product-image" alt="Product image"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; border-radius: 10%;" />
              <button class="details-button">Подробнее</button>
            </div>
            <div class="info-overlay">
              <div style="min-height: 45px;">
                <h5 style=" font: 600 17px Montserrat, sans-serif;" class="nameartist">{{ item.description }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      carouselItems: [],
      city: "",
    };
  },
  methods: {
    go(id, slide_id, url) {
      // Проверяем, существует ли slide_id в localStorage
      const existingSlideId = localStorage.getItem(slide_id);

      if (!existingSlideId) {
        localStorage.setItem(slide_id, slide_id);
        axios.post("https://backend.chesnok.kz/api/clickSlide", {
          slide_id: slide_id,
          city_id: this.city.id,
        });
        console.log("мы отправили бабки в профиль");
      } else {
        console.log("Клик по рекламному блоку уже засчитан");
      }
      if (id != null) {
        this.$router.push("/show/" + id);
      } else {
        document.location = url;
      }
    },
    async fetchCarouselItems() {
      try {
        const response = await axios.get(
          "https://backend.chesnok.kz/api/slide/" + this.city.id
        );
        this.carouselItems = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.city = JSON.parse(localStorage.getItem("selectedCity"));
    this.fetchCarouselItems();
  },
};
</script>

<style scoped>
.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-line {
  flex: 1;
  border: 5px solid #7fccf4;
}

.header-text {
  margin: 0 15px;
}

.image-containerr {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 43vh;
}

.image-item {
  flex: 1;
  max-width: 33.33%;
}

.second-item {
  margin-left: 0.1%;
  margin-right: 0.1%;
}

.text-block {
  display: none;
}

.image-item img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .image-containerr {
    flex-direction: column;
    align-items: center;
  }

  .image-item {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    /* Add some space between items */
    display: flex;
    /* Enable flex layout */
    align-items: center;
    /* Align items vertically */
  }

  .image-item img {
    width: 30%;
    /* Set image width */
  }

  .text-block {
    display: block;
    flex-grow: 1;
    /* Take up remaining space */
    padding-left: 10px;
    /* Add some padding */
    /* Additional styling for the text block if needed */
  }
}

.div {
  display: flex;
  width: 100%;
  gap: 20px;
  font-size: 30px;
  color: #000;
  font-weight: 600;
  padding: 3px 20px;
}

@media (max-width: 991px) {
  .div {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.div-2 {
  font-family: Montserrat, sans-serif;
  flex-grow: 1;
  flex-basis: auto;
  font-weight: 500;
}

@media (max-width: 991px) {
  .div-2 {
    max-width: 100%;
  }
}

.img {
  aspect-ratio: 2.27;
  object-fit: auto;
  object-position: center;
  width: 87px;
}</style>
