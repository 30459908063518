<template>
    <div class="container" v-if="artist">
        <!-- Фото галерея -->
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item" :class="{ active: index === 0 }" v-for="(image, index) in artist.images"
                    :key="image.id">
                    <img :src="'https://backend.chesnok.kz/storage/' + image.path"
                        class="d-block w-100 carousel-image" alt="Фото" data-bs-toggle="modal"
                        data-bs-target="#carouselModal">
                </div>
            </div>
            <!-- Thumbnails -->
            <div class="carousel-indicators">
                <button type="button"
                    :style="{ backgroundImage: 'url(https://backend.chesnok.kz/storage/' + image.path + ')' }"
                    data-bs-target="#carouselExampleIndicators" :data-bs-slide-to="index" :class="{ active: index === 0 }"
                    v-for="(image, index) in artist.images" :key="'thumb-' + image.id"></button>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-9">
                <div>
                    <h5 style="margin: 0px 0px 5px 0px;">{{ artist.name }} </h5>

                </div>
                <div>
                    <hr>
                </div>
                <div>
                    <a :href="'/categories/' + cat.id" class="btn btn-secondary m-1" v-for="cat in artist.categories">{{
                        cat.name
                    }}</a>
                </div>
                <div>
                    <hr>
                </div>
                <div>
                    <a  class="btn btn-secondary m-1" v-for="city in artist.cities">{{
                        city.city_name
                    }}</a>
                </div>
                <div>
                    <div>
                        <div v-if="artist.average_check != null">
                            <div>
                                <p style="color: gray">Средний чек - {{ artist.average_check }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {{ artist.description }}
                </div>
                <div class="mt-3 videobloc">
                    <iframe v-for="video in artist.videos" :src="'https://www.youtube.com/embed/' + video.code"
                        frameborder="0"></iframe>
                </div>
            </div>
            <div class="col-3">
                <div class="profilecard  p-3" style="text-align: center;">
                    <img class="rounded-circle" :src="'https://backend.chesnok.kz/storage/' + artist.avatar"
                        alt="">
                    <h5 class="mt-3">{{ artist.name }}</h5>
                    <p>
                        <i style="color:rgb(61, 210, 204);" class="m-2 bi bi-bookmark" v-if="loggedIn"
                            :class="bookmarkedProfiles.includes(artist.id) ? 'bookmark' : 'bookmark_border'"
                            @click.stop="bookmarkProfile(artist.id)"></i>
                        В закладки
                    </p>
                    <a href="#" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">Написать</a>
                </div>
                <div class="profilecard p-3 mt-3">
                    <Calendar @date-selected="onDateSelected" v-if="artist" :events="artist.calendar" />
                    <div class="mt-3">
                        <div class="row">
                            <div class="col-1">
                                <div style="height: 2vh; width: 2vh; background-color: #3f51b5;"></div>
                            </div>
                            <div class="col-11">
                                - дата занята
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Модальное окно -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" style="background-color: white;">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col">
                            <Calendar @date-selected="onDateSelected" v-if="artist" :events="artist.calendar" />
                            <div class="mt-3">
                                <div class="row">
                                    <div class="col-1">
                                        <div style="height: 2vh; width: 2vh; background-color: #3f51b5;"></div>
                                    </div>
                                    <div class="col-11">
                                        - дата занята
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <textarea id="description" v-model="textmessage" class="form-control" style="min-height: 40vh;"
                                required placeholder="Текс обращения"></textarea>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                    <button type="button" class="btn btn-primary" @click="sendMessageToArtist()">Отправить</button>
                </div>
            </div>
        </div>
    </div>



    <div class="modal fade modal-fullscreen" id="carouselModal" tabindex="-1" aria-labelledby="carouselModalLabel"
        aria-hidden="true">
        <div class="modal-dialog ">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row">
                        <div id="carouselModalIndicators" class="carousel slide" data-bs-ride="carousel" v-if="artist">
                            <div class="carousel-inner">
                                <div class="carousel-item" :class="{ active: index === 0 }"
                                    v-for="(image, index) in artist.images" :key="image.id">
                                    <img :src="'https://backend.chesnok.kz/storage/' + image.path"
                                        class="d-block w-100 carousel-image" alt="Фото">
                                </div>
                            </div>
                            <!-- Управляющие элементы -->
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselModalIndicators"
                                data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Предыдущее</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselModalIndicators"
                                data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Следующее</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from 'axios';
import Calendar from '../components/Calendar.vue';

export default {
    data() {
        return {
            artist: null,
            datetoquestions: '',
            isBookmarked: false,
            bookmarkedProfiles: [],
            selectedImageIndex: 0,

        };
    },
    components: {
        Calendar
    },
    mounted() {
        this.fetchArtist();
        this.fetchBookmarkedProfile();
        this.loggedIn = localStorage.getItem('isLogged');

    },
    methods: {
        selectImage(index) {
            this.selectedImageIndex = index;
        },
        async bookmarkProfile() {
            if (this.loggedIn) {
                try {
                    const token = localStorage.getItem('token');
                    let id = this.$route.params.id;
                    const response = await axios.post(
                        `https://backend.chesnok.kz/api/profiles/${id}/bookmark`,
                        {},
                        { headers: { 'Authorization': `Bearer ${token}` } }
                    );
                    console.log(response.data.message);
                    document.location.reload();
                    // Здесь вы можете обновить состояние иконки закладки на основе ответа сервера.
                } catch (error) {
                    console.error(error);
                }
            } else {
                this.$router.push('/login')
            }
        },
        fetchGenres() {
            axios.post('https://backend.chesnok.kz/api/genres').then(response => {
                this.genres = response.data;
            })
        },
        async fetchBookmarkedProfile() {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    'https://backend.chesnok.kz/api/bookmarked-profile',
                    { headers: { 'Authorization': `Bearer ${token}` } }
                );
                this.bookmarkedProfiles = response.data.map(profile => profile.id);
                this.bookmarkedProfiles.forEach(element => {

                    console.log(' element id - ' + element.id);
                    console.log(' this.id id - ' + this.id);
                    if (element == this.id) {


                        this.isBookmarked = true;
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
        fetchArtist() {
            const id = this.$route.params.id;
            axios.post('https://backend.chesnok.kz/api/getProfileInfo', { id })
                .then(response => {
                    this.artist = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        onDateSelected(date) {
            console.log("Выбранная дата:", date);
            const datee = new Date(date);
            datee.setDate(datee.getDate() + 1);
            const newDateString = datee.toISOString().slice(0, 10);

            this.datetoquestions = newDateString;
            this.isedit = false;
            this.artist.calendar.forEach(element => {
                if (date == element.date) {
                    this.isedit = true;
                    this.selectedComponent = element.id;
                }
            });
            this.datetoevent = date;
        },
        getLanguages() {
            axios.get('https://backend.chesnok.kz/api/getLanguages', {
            })
                .then(response => {
                    this.languages = response.data
                }).catch(error => {
                })
        },
        sendMessageToArtist() {
            let token = localStorage.getItem('token');
            let id = this.id;
            axios.post('https://backend.chesnok.kz/api/sendRequestArtist', {
                receiverId: this.$route.params.id,
                textMessage: this.textmessage,
                datetoquestions: this.datetoquestions,
            }, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(response => {
                    this.sendmessage = false;
                    alert('Сообщение успешно отправлено, вы будете перенаправлены на страницу сообщений');
                    document.location.href = "#/cabinetuser/"
                }).catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.logout();
                    } else {
                        alert('Вы уже отправляли запрос на эту дату!')
                    }
                })
        },
    }
};
</script>

<style scoped>
i {
    cursor: pointer;
}

.bookmark {
    color: black !important;
}

.carousel-indicators button {
    width: 60px;
    height: 40px;
    background-size: cover;
    border: 0;
}

.carousel-item {
    height: 0;
    padding-bottom: 56.25%;
    /* Соотношение сторон 16:9 */
    overflow: hidden;
}

.carousel-item img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.profilecard {
    background-color: rgb(226, 233, 240);
    min-height: 30vh;
    border-radius: 4%;
}

.profilecard img {
    width: 50%;
    margin-top: 6%;
    border: 1px solid rgb(96, 96, 221);


}

.videobloc iframe {
    padding: 1%;
}

.modal-content {
    width: 90%;
    position: fixed;
    left: 5%;
    height: auto;
    background-color: rgba(172, 172, 172, 0);
}

.modal-body {
    width: 100%;
    background-color: rgba(172, 172, 172, 0);
    height: auto;

}
</style>
