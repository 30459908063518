<template>
  <div class="position-relative m-1 search-component flscrn" ref="searchComponent" >
    <div class="input-group search-box">
      <div class="input-group-prepend">
        <span class="input-group-text advanced-search navtextsize" style="background-color:#00b4b4; color: white; border-radius: 0px" @click="$router.push('/advancedsearch')">Расширенный поиск</span>
      </div>
      <input class="form-control" type="text" v-model="searchQuery" @input="search" placeholder="Введите ваш запрос" />
    </div>
    <div class="results-list position-absolute w-100" v-if="showResults">
      <ul class="list-group">
        <li class="list-group-item" v-for="(result, index) in searchResults" :key="index" @click="$router.push('/show/'+result.id)">
          <div class="row">
            <div class="col-3">
              <img :src="'https://backend.chesnok.kz/storage/'+result.avatar" class="avatar" alt="...">
            </div>
            <div class="col-9">{{ result.name }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="position-relative m-1 search-component mblscrn" ref="searchComponent" >
    <div class="input-group search-box">
      <div class="input-group-prepend">
        <span class="input-group-text advanced-search" @click="$router.push('/advancedsearch')">Расширенный поиск</span>
      </div>
      <input class="form-control" type="text" v-model="searchQuery" @input="search" placeholder="Введите ваш запрос" />
    </div>
    <div class="results-list position-absolute w-100" v-if="showResults">
      <ul class="list-group">
        <li class="list-group-item" v-for="(result, index) in searchResults" :key="index" @click="$router.push('/show/'+result.id)">
          <div class="row">
            <div class="col-3">
              <img :src="'https://backend.chesnok.kz/storage/'+result.avatar" class="avatar" alt="...">
            </div>
            <div class="col-9">{{ result.name }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      searchQuery: '',
      searchResults: [],
      showResults: false
    }
  },
  mounted() {
    document.addEventListener('click', this.outsideClickListener);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.outsideClickListener);
  },
  methods: {
    search() {
      if (this.searchQuery.length > 0) {
        axios.get('https://backend.chesnok.kz/api/profiles/search', {
          params: {
            query: this.searchQuery
          }
        })
          .then(response => {
            this.searchResults = response.data;
            this.showResults = true;
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        this.showResults = false;
      }
    },
    outsideClickListener(e) {
      if (!this.$refs.searchComponent.contains(e.target)) {
        this.showResults = false;
      }
    }
  }
}
</script>
<style>
body {
  font-family: Montserrat, sans-serif !important;
}

.search-component {
  /* стили для корневого элемента */
}

.search-box {
  box-shadow: 1px 4px 6px 3px #00000040;
  border-radius: 0px !important;
}

.advanced-search {
  border-radius: 0%;
  background-color: #569193;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
}

@media (min-width: 901px) {
  .mblscrn {
    display: none;
  }
}
@media (max-width: 900px) {
  .flscrn {
    display: none;
  }
}
.form-control {
  border-radius: 0%;
}

.results-list {
  z-index: 1000;
}

.list-group {
  position: absolute;
  top: 100%;
}

.avatar {
  width: 100%;
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 767px) {
  .advanced-search {
    font-size: 12px; /* Уменьшите размер шрифта для кнопки */
  }
}
</style>