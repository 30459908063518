<template>
  <div class="container-fluid mb-5" style="">
    <div class="container">
      <div class="row">
        <Cabartistbar></Cabartistbar>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">

      <!-- МОДАЛЬНОЕ ОКНО -->
      <div class="modal-overlay" @click="closeModal()" :class="{ show: showModal }">
        <div class="modal" tabindex="-1" :class="{ 'show d-block': showModal }" @click.stop>
          <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Добавить кампанию</h5>
                <button type="button" class="btn-close" @click="showModal = false"></button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="mb-3">
                    <label class="form-label">Название</label>
                    <input type="text" class="form-control" v-model="name" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label"><i>Цена за клик формируется из выбранного региона для показа,
                        <a @click="showModalTwo = true">ПОКАЗАТЬ СТОИМОСТЬ</a></i></label>
                    <!-- <input type="number" class="form-control" v-model="cost"> -->
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Вариант размещения</label>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" id="placementType1" value="3" v-model="type" />
                      <label class="form-check-label" for="placementType1">Верхний баннер</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" id="placementType1" value="1" v-model="type" />
                      <label class="form-check-label" for="placementType1">Анонсы</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" id="placementType2" value="2" v-model="type" />
                      <label class="form-check-label" for="placementType2">Баннер</label>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div>
                      <p>Города для кампании</p>
                      <v-select v-model="selectedCities" :options="cities" multiple label="city_name"
                        :reduce="(label) => label.id"></v-select>
                    </div>
                  </div>
                  <div class="mb-3" v-if="type == '1'">
                    <label class="form-label">Описание</label>
                    <textarea class="form-control" v-model="description"></textarea>
                  </div>
                  <div class="mb-3">
                    <label for="formFile" class="form-label">Загрузить изображение</label>
                    <input class="form-control" type="file" id="formFile" ref="files" @change="handleFileUploads" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Начало показа</label>
                    <input type="date" class="form-control" v-model="fromDate" />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Окончание показа</label>
                    <input type="date" class="form-control" v-model="beforeDate" />
                  </div>
                  <div class="mb-3">
                    <div>
                      <input type="radio" id="selectProfile" value="profile" v-model="inputType" />
                      <label for="selectProfile">
                        Баннер будет ссылаться на профиль</label>
                      <hr />
                      <input type="radio" id="enterLink" value="link" v-model="inputType" />
                      <label for="enterLink" class="pl-3">
                        Баннер будет открывать ссылку</label>
                    </div>
                    
                    <div v-if="inputType === 'profile'">
                      <select class="form-select" v-model="selectedArtist">
                        <option v-for="profile in profiles" :key="profile.id" :value="profile.id">
                          {{ profile.name }}
                        </option>
                      </select>
                    </div>
                    <div v-else-if="inputType === 'link'">
                      <input type="text" class="form-control" v-model="profileLink" placeholder="Введите ссылку" />
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="showModal = false">
                  Отмена
                </button>
                <button type="button" class="btn btn-primary" @click="addCampaign" :disabled="!isFormValid">
                  Добавить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 desktop-marketing">
        <div style="width: 80%; margin-left: 10%; position: relative">
          <div class="row mb-5" v-for="campaign in campanies" :key="campaign.name">
            <!-- Левый блок -->
            <div class="col-11" style="
                min-height: 10vh;
                background-color: rgb(241, 241, 241);
                border: 3px solid white;
              ">
              <span style="font-size: 6pt; color: gray">Название</span> <br />
              <span><b>{{ campaign.name }}</b></span>
              <hr class="m-0" />
              <span style="font-size: 6pt; color: gray">Статус</span> <br />
              <span><b>{{ campaign.isactive == true ? $t("ACTIVE") : $t("PAUSE") }}</b></span>
            </div>

            <!-- Правый блок -->
            <div class="col-1" style="
                position: relative;
                min-height: 10vh;
                background-color: rgb(241, 241, 241);
                border: 3px solid white;
              ">
              <span style="font-size: 6pt; color: gray">Клики</span> <br />
              <span><b>{{ campaign.slide_clicks_count }}</b></span>

              <!-- Кнопки редактирования и удаления -->
              <div style="position: absolute; top: -20%; right: 0">
                <a class="p-1 m-1" style="background-color: gray" @click="editCampaign(campaign)">
                  <i style="color: white; font-size: 0.9vw" class="bi bi-pencil-fill"></i>
                </a>
                <a class="p-1 m-1" style="background-color: gray" @click="deleteCampaign(campaign)">
                  <i style="color: white; font-size: 0.9vw" class="bi bi-trash-fill"></i>
                </a>
              </div>
            </div>
            <div style="text-align: center">
              <a class="btn btn-info mt-1" style="
                  background-color: #7fccf4 !important;
                  border: none !important;
                  font-weight: bold;
                  border-radius: 0px;
                  width: 30vw;
                " @click="toggleStatusCampany(campaign.id)">{{ campaign.isactive ? $t("PUT_ON_PAUSE") : $t("ACTIVATE")
                }} </a>

            </div>

          </div>
        </div>
      </div>
    </div>

    <div style="text-align: center;" class="mt-1 desktop-marketing">
      <button class="btn btn-primary" style="
                  background-color: #7fccf4 !important;
                  border: none !important;
                  font-weight: bold;
                  border-radius: 0px;
                  width: 30vw;
                " @click="
                  showModal = true;
                resetForm();
                selectAllCities();
                ">
        Добавить кампанию
      </button>
    </div>


    <div class="row mobile-marketing">
      <div class="col-12 p-4">
        <h3 style="color: gray; font-weight: bold;">Продвижение</h3>
      </div>

      <div class="row p-4" v-for="campaign in campanies">
        <div class="col-9" style="background: rgb(245, 245, 245); border: 2px solid white">
          <p style="font-weight: 100; font-size: 8pt;" class="mt-1 mb-1">Название</p>
          <p style="font-weight: bolder;" class="mt-1 mb-1">{{ campaign.name }}</p>

          <p style="font-weight: 100; font-size: 8pt;" class="mt-1 mb-1">Статус</p>
          <p style="font-weight: bolder;" class="mt-1 mb-1">{{ campaign.isactive == true ? $t("ACTIVE") : $t("PAUSE") }}</p>
        </div>
        <div class="col-3" style="background: rgb(245, 245, 245); border: 2px solid white; position: relative;">
          <p style="font-weight: 100; font-size: 8pt;" class="mt-1 mb-1">Клики</p>
          <p style="font-weight: bolder;" class="mt-1 mb-1">258</p>
          <div style="position: absolute; top: -20%; right: 0">
            <a class="p-1 m-1" style="background-color: gray" @click="editCampaign(campaign)">
              <i style="color: white; font-size: 2.9vw" class="bi bi-pencil-fill"></i>
            </a>
            <a class="p-1 m-1" style="background-color: gray" @click="deleteCampaign(campaign)">
              <i style="color: white; font-size: 2.9vw" class="bi bi-trash-fill"></i>
            </a>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script>
import axios from "axios";
import Cabartistbar from "@/components/Cabartistbar.vue";
import vSelect from "vue-select";

export default {
  data() {
    return {
      currentCampaignId: null,
      inputType: "profile", // по умолчанию выбрано 'profile'
      profileLink: "", // переменная для хранения введенной ссылки
      columns: [
        {
          name: "name",
          required: true,
          label: "Название",
          align: "left",
          field: "name",
        },
        {
          name: "click_amount",
          required: true,
          label: "Стоимость клика",
          align: "left",
          field: "click_amount",
        },
        {
          name: "slide_clicks_count",
          required: true,
          label: "Кол-во кликов",
          align: "left",
          field: "slide_clicks_count",
        },
        {
          name: "spent_amount",
          required: true,
          label: "Затраты",
          align: "left",
          field: "spent_amount",
        },
        {
          name: "delete",
          required: false,
          label: "Удалить",
          align: "center",
          field: "delete",
        },
      ],
      cities: [],
      campanies: [],
      showModal: false,
      showModalTwo: false,
      selectedCities: [],
      type: "1",
      name: "",
      cost: "",
      description: "",
      fromDate: "",
      profiles: [],
      beforeDate: "",
      selectedArtist: "",
      files: null,
      isFormValid: false, // Добавленное свойство,
      prices: [],
    };
  },
  components: {
    Cabartistbar,
    vSelect,
  },
  computed: {
    // Обновленное определение isFormValid
    isFormValid() {
      return (
        this.name !== "" &&
        // this.selectedArtist !== "" &&
        this.fromDate !== "" &&
        this.beforeDate !== "" &&
        // this.cost !== '' &&
        this.files !== null &&
        this.files.length > 0 &&
        (this.type !== "1" || this.description !== "")
      );
    },
  },

  created() {
    if (!localStorage.getItem("isLogged")) {
      document.location.href = "/";
      exit;
    }

    this.fetchPrices();
    this.fetchCampany();
    this.fetchProfiles();
    this.getCities();
    this.checklocalstorage();
  },

  methods: {
    closeModal() {
      console.log('hihihihi');
      this.showModal = false;
    },

    closeModalTwo() {
      this.showModalTwo = false;
    },
    toggleStatusCampany(id) {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.chesnok.kz/api/toggleStatusCampany",
          { id: id },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          if (response.status == 200) {
            alert("Статус рекламной кампании изменен!");
          }
          this.fetchCampany();
        })
        .catch((error) => {
          alert("Возникла непредвиденная ошибка!");
          console.log(error);
        });
    },

    checklocalstorage() {
      let idforcreatedcampany = localStorage.getItem("idforcreatedcampany");
      if (idforcreatedcampany) {
        this.selectedArtist = idforcreatedcampany;
        this.showModal = true;
        localStorage.removeItem("idforcreatedcampany");
      }
    },
    selectAllCities() {
      this.selectedCities = this.cities.map((city) => city.id);
    },
    resetForm() {
      this.currentCampaignId = null;
      this.name = "";
      this.cost = "";
      this.description = "";
      this.fromDate = "";
      this.beforeDate = "";
      this.selectedArtist = "";
      this.files = null;
      this.selectedCities = [];
    },
    editCampaign(campaign) {
      this.name = campaign.name;
      // this.cost = campaign.click_amount;
      this.type = campaign.type;
      let citiesArray = campaign.cities.map((city) => city.id);

      this.description = campaign.description;
      this.fromDate = campaign.from_date;
      this.beforeDate = campaign.before_date;
      this.selectedArtist = campaign.profile_id;

      this.selectedCities = citiesArray;
      this.currentCampaignId = campaign.id;

      // Следует добавить остальные поля, которые нужно редактировать
      this.showModal = true;
    },
    fetchProfiles() {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.chesnok.kz/api/profiles",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.profiles = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchPrices() {
      let token = localStorage.getItem("token");
      axios
        .get(
          "https://backend.chesnok.kz/api/prices",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.prices = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCities() {
      axios
        .get("https://backend.chesnok.kz/api/getCities", {})
        .then((response) => {
          this.cities = response.data;
        })
        .catch((error) => { });
    },
    fetchCampany() {
      let token = localStorage.getItem("token");
      axios
        .post(
          "https://backend.chesnok.kz/api/fetchcampany",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          this.campanies = response.data;
        })
        .catch((error) => { });
    },

    handleFileUploads() {
      this.files = this.$refs.files.files;
      console.log("file " + this.files);
    },

    addCampaign() {
      this.showModal = false;
      let token = localStorage.getItem("token");
      let formData = new FormData();
      formData.append("image", this.files[0]);
      formData.append("name", this.name);
      // formData.append('cost', this.cost);
      formData.append("type", this.type);
      formData.append("from", this.fromDate);
      formData.append("before", this.beforeDate);

      // formData.append("profile_id", this.selectedArtist);
      if (this.inputType === "link") {
        // Обработка введенной ссылки
        formData.append("profile_link", this.profileLink);
      } else {
        // Обработка выбранного профиля
        formData.append("profile_id", this.selectedArtist);
      }

      formData.append("description", this.description);
      formData.append("cities", this.selectedCities);

      formData.append("token", token);

      if (this.currentCampaignId) {
        formData.append("id", this.currentCampaignId);
      }

      axios
        .post("https://backend.chesnok.kz/api/slide", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },


        })
        .then((response) => {
          if (response.status == 200) {
            alert("Ваш рекламный материал активируется после модерирования");
            this.resetForm(); // сбрасываем форму;
            this.currentCampaignId = null;
            // document.location.reload();
          } else {
            // window.location.reload();
          }
        })
        .catch((response) => {
          console.log(response.data);
        });
    },

    deleteCampaign(campaign) {
      let token = localStorage.getItem("token");
      axios
        .post(
          `https://backend.chesnok.kz/api/deletecampany`,
          {
            id: campaign.id,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(() => {
          this.fetchCampany();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style scoped>
.mobile-marketing {
  display: none;
}

.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.modal-overlay.show {
  display: block;
}

@media (max-width: 980px) {
  .desktop-marketing {
    display: none;
  }

  .mobile-marketing {
    display: block;
  }
}
</style>
