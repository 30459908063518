<template>
  <div
    class="container py-4 containereditusermobile"
    style="width: 30%; margin-left: 35%"
  >
    <div class="q-pa-lg">
      <div class="row">
        <div class="col-12 col-md-12 py-2" style="text-align: center">
          <img
            :src="imageUrl"
            class="profile-image"
            style="width: 30%; border-radius: 0px; border-radius: 50%"
          />
          <br />
          <div class="file-input" style="width: 50%">
            <div style="padding-top: 2%">
              <p
                class="mt-4"
                style="width: 100%; text-decoration: underline"
                data-bs-toggle="modal"
                data-bs-target="#cropImageModal"
              >
                Загрузить фото
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12" style="padding: 2%">
          <div class="">
            <div class="row mb-4">
              <div class="col">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control formcontroledituser"
                    id="username"
                    placeholder="Имя пользователя"
                    v-model="username"
                    style=""
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <input
                    type="email"
                    placeholder="Email пользователя"
                    class="form-control formcontroledituser"
                    id="email"
                    v-model="email"
                    style="
                      border-radius: 10px;
                      border: 1px solid rgb(107, 107, 107);
                      color: gray;
                    "
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <select
                class="form-control formcontroledituser p-2"
                multiple
                id="genres"
                v-model="selectedGenres"
                style="
                  border-radius: 10px;
                  border: 1px solid rgb(107, 107, 107);
                "
              >
                <option
                  class="p-1 mb-1"
                  style="
                    color: grey;
                    border-bottom: 1px solid gray;
                    padding-bottom: 10px !important;
                    font-size: 8pt;
                  "
                  v-for="genre in genres"
                  :key="genre.id"
                  :value="genre.id"
                >
                  {{ genre.name }}
                </option>
              </select>
            </div>
            <img ref="image" style="display: none" />

            <button
              class="btn btn-primary mt-3"
              style="
                width: 100%;
                background-color: #cd0b0b !important;
                border: none !important;
                border-radius: 10px;
              "
              @click="submitForm"
            >
              Отправить
            </button>
            <button
              class="mt-3"
              style="
                width: 100%;
                border: none !important;
                border-radius: 0px;
                background: none !important;
                color: #cd0b0b;
              "
              @click="confirmUpgrade"
            >
              Перевести аккаунт в "Профессиональный"
            </button>
          </div>
          <div class="mt-3" style="text-align: right">
            <div class="form-group">
              <!-- <a class="btn btn-light" @click="confirmUpgrade">Перевести аккаунт в "Профессиональный"</a> -->
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="cropImageModal"
        tabindex="-1"
        aria-labelledby="cropImageModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="cropImageModalLabel">
                Обрезать изображение
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <vue-avatar
                :width="400"
                :height="400"
                :rotation="rotationAvatar"
                :scale="scaleAvatar"
                ref="vueavatarAvatar"
                @vue-avatar-editor:image-ready="onImageReadyAvatar"
              >
              </vue-avatar>
              <br />
              <div>
                <div class="row">
                  <div class="col-2">Увеличение</div>
                  <div class="col-10">
                    <input
                      type="range"
                      min="1"
                      max="3"
                      step="0.02"
                      v-model="scaleAvatar"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div class="row">
                  <div class="col-2">Вращение</div>
                  <div class="col-10">
                    <input
                      type="range"
                      min="0"
                      max="360"
                      step="1"
                      v-model="rotationAvatar"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Закрыть
              </button>
              <button
                @click="saveClicked"
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Обрезать и отправить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { VueAvatar } from "vue-avatar-editor-improved";

export default {
  components: {
    VueAvatar,
  },
  data() {
    return {
      imageUrl: "https://via.placeholder.com/150",
      selectedFile: null,
      username: "",
      selectedGenres: [],
      genres: [],
      rotationAvatar: 0,
      scaleAvatar: 1,
      userId: null,
    };
  },
  methods: {
    onImageReadyAvatar() {
      this.scaleAvatar = 1;
      this.rotationAvatar = 0;
    },
    onImageReadyGallery() {
      this.scaleGallery = 1;
      this.rotationGallery = 0;
    },
    confirmUpgrade() {
      let token = localStorage.getItem("token");

      if (
        window.confirm(
          'Уверены ли вы в том, что хотите перевести аккаунт в "профессиональный"?'
        )
      ) {
        axios
          .post(
            "https://backend.chesnok.kz/api/goingtoproffessional",
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            this.logout();
          });
      } else {
        console.log("net");
      }
    },
    logout() {
      localStorage.removeItem("isLogged");
      this.loggedIn = null;
      window.location.href = "/";
    },
    getUserInfo() {
      let token = localStorage.getItem("token");

      axios
        .post(
          "https://backend.chesnok.kz/api/me",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("береза");
          this.username = response.data.name;
          this.userId = response.data.id;

          this.email = response.data.email;

          localStorage.setItem("username", this.username);

          console.log("береза 2");

          this.imageUrl =
            "https://backend.chesnok.kz/storage/" + response.data.avatar;
          console.log(this.imageUrl);

          axios
            .post(
              "https://backend.chesnok.kz/api/getgenres",
              {},
              { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
              this.selectedGenres = response.data;
            });
        })
        .catch((error) => {});
    },

    fetchGenres() {
      axios.post("https://backend.chesnok.kz/api/genres").then((response) => {
        this.genres = response.data;
      });
    },

    handleFileUploads() {
      console.log("выполняется");
      this.files = this.$refs.files.files;
      console.log("file " + this.files);
    },
    saveClicked() {
      const token = localStorage.getItem("token");

      var canvas = this.$refs.vueavatarAvatar.getImageScaled();
      var img = canvas.toDataURL();
      this.$refs.image.src = img;

      // Convert dataURL to Blob
      fetch(img)
        .then((res) => res.blob())
        .then((blob) => {
          const formData = new FormData();
          formData.append("image", blob, "filename.png");
          formData.append("username", this.username);
          formData.append("email", this.email);
          formData.append("genres", this.selectedGenres);

          axios
            .post("https://backend.chesnok.kz/api/edituser", formData, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              localStorage.setItem("username", this.username);
              this.getUserInfo();
              alert("Аватар успешно обновлен");
              document.location.reload();
            });
        });
    },
    async submitForm() {
      let token = localStorage.getItem("token");

      if (!this.files || !this.files[0]) {
        console.log("Изображение не выбрано");
        const formData = new FormData();
        formData.append("username", this.username);
        formData.append("email", this.email);
        formData.append("token", token);
        formData.append("genres", this.selectedGenres);

        try {
          const response = await axios.post(
            "https://backend.chesnok.kz/api/edituser",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          localStorage.setItem("username", this.username);

          alert("Информация успешно обновлена");
          this.getUserInfo();
          document.location.reload();
        } catch (error) {
          alert("Произошла ошибка");
        }
      } else {
        const formData = new FormData();
        formData.append("image", this.files[0]);
        formData.append("username", this.username);
        formData.append("email", this.email);
        formData.append("token", token);
        formData.append("genres", this.selectedGenres);

        try {
          const response = await axios.post(
            "https://backend.chesnok.kz/api/edituser",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          localStorage.setItem("username", this.username);
          this.getUserInfo();
          document.location.reload();
        } catch (error) {}
      }
    },
  },
  async mounted() {},
  created() {
    if (!localStorage.getItem("isLogged")) {
      document.location.href = "/";
      exit;
    }
    this.getUserInfo();
    this.fetchGenres();
  },
};
</script>
<style scoped>
.formcontroledituser {
  border-radius: 10px;
  border: 1px solid rgb(107, 107, 107);
  color: gray;
}
body.dark-theme .formcontroledituser{
  background: #3E3E3E !important;
  color: rgba(255, 255, 255, 0.499) !important;
  border: 0.5px rgba(255, 255, 255, 0.355) solid;
}
body.dark-theme .formcontroledituser input{
  background: #3E3E3E !important;
  color: rgba(255, 255, 255, 0.499) !important;
  border: 0.5px rgba(255, 255, 255, 0.355) solid;
}
.card-body {
  background: red !important;
}

.profile-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.file-input {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.file-input input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

@media (max-width: 980px) {
  .containereditusermobile {
    width: 92% !important;
    margin-left: 4% !important;
  }
  .updavataredituser {
    font-size: 4vw;
  }
  .titleformedituser {
    font-size: 3vw;
  }
  .form-control {
    border: 2px solid rgb(182, 181, 181) !important;
  }
}
</style>