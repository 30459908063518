<template>
    <!-- Кнопка для скроллинга влево -->
    <div class="row" v-if="!isModalOpen">
      <div class="col-1">
            <h2>Фото</h2>
        </div>
        <div class="col-11 " style="text-align: right;">
            <button @click="scrollLeft" style="background: none !important;" class="scroll-btn left-btn">
                <img src="../../assets/left.png" alt="">
            </button>
            <button @click="scrollRight" style="background: none !important;" class="scroll-btn right-btn">
                <img src="../../assets/right.png" alt="">
            </button>
        </div>
      <div class="col-12">
        <div class="d-flex image-slider" ref="imageContainer">
          <img v-for="(image, index) in images" :key="image.id" class="img-thumbnail m-1 galery-item"
            :src="'https://backend.chesnok.kz/storage/' + image.path" @click="openModal(index)" alt="Thumbnail"
            style="flex: 0 0 auto; width: 30vh !important; height: 30vh !important; border: none !important; border-radius: 10%;">
        </div>
      </div>
    </div>

    <div class="row" v-if="isModalOpen" style="position: relative;">
      <div class="col-1 d-flex justify-content-between">
        <button @click="prevImage" class="btn btn-light" style="background: none; border: none">
          <img src="../../assets/left.png" alt="">
        </button>
      </div>
      <div class="col-10 d-flex justify-content-between">
        <img v-if="currentImage" :src="'https://backend.chesnok.kz/storage/' + currentImage.path" class="img-fluid"
          style="height: 75vh; object-fit: contain; max-width: 90%; margin: 0px auto">
        <button class="close-btn" @click="closeModal">
          <span>&#10005;</span>
        </button>
      </div>
      <div class="col-1 d-flex justify-content-between">
        <button @click="nextImage" class="btn btn-light" style="background: none; border: none">
          <img src="../../assets/right.png" alt="">
        </button>
      </div>
      <div class="col-12 d-flex custom-scrollbar" style="overflow-x: scroll;">
        <img v-for="(image, idx) in images" :key="`thumb-${image.id}`"
          :class="{ 'selected-image': currentItemIndex === idx }" :src="'https://backend.chesnok.kz/storage/' + image.path"
          class="img-thumbnail m-1 galery-item" @click="setCurrentImage(idx)"
          style="flex: 0 0 auto; width: 30vh !important; height: 30vh !important;" alt="Thumbnail">
      </div>
    </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  images: Array,
});
const emit = defineEmits(['openImage']);

const currentItemIndex = ref(0);
const isModalOpen = ref(false);
const currentImage = computed(() => props.images[currentItemIndex.value]);
const imageContainer = ref(null);

const setCurrentImage = (index) => {
  currentItemIndex.value = index;
};

const openModal = (index) => {
  setCurrentImage(index);
  isModalOpen.value = true;
  emit('openImage', true);
};

const closeModal = () => {
  isModalOpen.value = false;
  emit('openImage', false);
};

const scrollLeft = () => {
  if (imageContainer.value) {
    imageContainer.value.scrollBy({ left: -200, behavior: 'smooth' });
  }
};

const scrollRight = () => {
  if (imageContainer.value) {
    imageContainer.value.scrollBy({ left: 200, behavior: 'smooth' });
  }
};

const prevImage = () => {
  if (currentItemIndex.value > 0) {
    setCurrentImage(currentItemIndex.value - 1);
  }
};

const nextImage = () => {
  if (currentItemIndex.value < props.images.length - 1) {
    setCurrentImage(currentItemIndex.value + 1);
  }
};
</script>

<style scoped>
.selected-image {
  border: 2px solid #7fccf4;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  height: 35px; width: 35px;
  font-size: 12px;
}

.control-btn {
  background-color: red;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.modal-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.navbat {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-right-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  flex-direction: column;
}

.image-slider-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.image-slider {
  overflow-x: auto;
  flex-grow: 1;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.image-slider::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scroll-btn {
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.left-btn {
  margin-bottom: 10px;
}

.galery-item {
  width: 150px !important;
  height: 150px !important;
  object-fit: cover;
  object-position: center;
}
</style>
